import classNames from "classnames";

interface TabsProps {
  activeTab: string;
  tabs: string[];
  setActiveTab: any;
}

const Tabs = (props: TabsProps) => {
  const { activeTab, tabs, setActiveTab } = props;

  const handleTabClick = (tab: string) => {
    setActiveTab(tab);
  };

  return (
    <div className='flex border-b-2 border-gray-300 text-gray-400 md:mt-5'>
      {tabs.map((tab, index) => (
        <div
          key={index}
          className='font-semibold px-5 md:px-0 md:pr-14 xs:w-full'>
          <button
            className={classNames(
              "flex-1 text-[14px] md:text-[16px] py-2",
              {
                "text-[#0979a1] font-bold border-b-2 border-[#0979a1]":
                  activeTab === tab,
              }
            )}
            onClick={() => handleTabClick(tab)}>
            {tab.charAt(0).toUpperCase() + tab.slice(1)}
          </button>
        </div>
      ))}
    </div>
  );
};

export default Tabs;
