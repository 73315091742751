import Back from "../../components/back";
import { CircleLoader } from "react-spinners";
import Calculator from "./calculator";

const rateRanges = [
  {
    transactionValue: "0 - 2500",
    transactionFee: "50",
  },
  {
    transactionValue: "2,500 - 750,000",
    transactionFee: "2% of Transaction Value",
  },
  {
    transactionValue: "750,000 - 5,000,000",
    transactionFee: "15,000",
  },
  {
    transactionValue: "Above 5 million",
    transactionFee: "vendpal.ng",
  },
];

const Merchants = () => {
  return (
    <div>
      <Back />

      <div className="max-w-screen-xl mx-auto flex flex-col gap-5 w-[100%] lg:w-[70%]  p-4 mt-10">
        <p className="mb-8 font-bold">Pricing</p>
        <p className="mb-8 font-bold">Our Pricing fees</p>
        <div className="overflow-x-auto">
          <table className="min-w-full border border-[#000000]">
            <thead className="text-[#FFFFFF] bg-[#0979A1] text-[18px] text-left">
              <tr>
                <th className="border px-4 py-2">Transaction Value</th>
                <th className="border px-4 py-2">Transaction Fee</th>
              </tr>
            </thead>
            <tbody>
              {rateRanges && rateRanges.length > 0 ? (
                rateRanges.map((item: any, index: number) => (
                  <tr key={index}>
                    <td className="border border-[#000] px-4 py-2">
                      {item.transactionValue}
                    </td>
                    <td className="border border-[#000] px-4 py-2 hover:text-[#0979A1]">
                      {index === rateRanges.length - 1 ? (
                        <a
                          href={`https://${item.transactionFee}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                        <span className="text-[#000]"> Visit </span>{item.transactionFee}
                        </a>
                      ) : (
                        <span className="text-[#000]"> {item.transactionFee}</span>
                      )}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={2} className="text-center h-[250px]">
                    <div className="flex items-center justify-center  mt-10">
                      <CircleLoader color="#0979A1" size={40} />
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>

      <Calculator />
    </div>
  );
};

export default Merchants;
