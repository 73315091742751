import React from "react";

const Divider = () => {
  return (
    <div className="w-screen md:w-full  ">
      <div>
        <div className="h-14 lg:h-[150px] transform  flex-1  bg-[#00131C]  absolute -left-[19%] w-[120%]   flex items-center justify-center overflow-hidden">
          <div className="text-[#BEE9FF] text-center text-lg md:text-2xl font-normal w-full flex lg:justify-around items-center scroll-containers primary-text ">
            <span className="block mx-4">Special Promo Alert</span>
            <span className="block mx-4">
              <svg
                width="41"
                height="6"
                viewBox="0 0 41 6"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M40.2998 5.04715L-0.000194729 5.04715L-0.00019455 0.952147L40.2998 0.952148L40.2998 5.04715Z"
                  fill="#BEE9FF"
                />
              </svg>
            </span>
            <span className="block mx-4">
              Unlock Secure Shopping & Get 100% Off Transaction Fee (Limited
              Offer)
            </span>
            <span className="block mx-4 w-5 h-5 bg-[#BEE9FF] rounded-full">
              
            </span>
            <span className="block mx-4">Special Promo Alert</span>
            <span className="block mx-4">
              <svg
                width="41"
                height="6"
                viewBox="0 0 41 6"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M40.2998 5.04715L-0.000194729 5.04715L-0.00019455 0.952147L40.2998 0.952148L40.2998 5.04715Z"
                  fill="#BEE9FF"
                />
              </svg>
            </span>
            <span className="block mx-4">
              Unlock Secure Shopping & Get 50% Off Transaction Fee (Limited
              Offer)
            </span>
            <span className="block mx-4 w-5 h-5 bg-[#BEE9FF] rounded-full">
              
              </span>
              <span className="block mx-4">Special Promo Alert</span>
            <span className="block mx-4">
              <svg
                width="41"
                height="6"
                viewBox="0 0 41 6"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M40.2998 5.04715L-0.000194729 5.04715L-0.00019455 0.952147L40.2998 0.952148L40.2998 5.04715Z"
                  fill="#BEE9FF"
                />
              </svg>
            </span>
            <span className="block mx-4">
              Unlock Secure Shopping & Get 50% Off Transaction Fee (Limited
              Offer)
            </span>
            <span className="block mx-4 w-5 h-5 bg-[#BEE9FF] rounded-full">
              
            </span>
            <span className="block mx-4">Special Promo Alert</span>
            <span className="block mx-4">
              <svg
                width="41"
                height="6"
                viewBox="0 0 41 6"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M40.2998 5.04715L-0.000194729 5.04715L-0.00019455 0.952147L40.2998 0.952148L40.2998 5.04715Z"
                  fill="#BEE9FF"
                />
              </svg>
            </span>
            <span className="block mx-4">
              Unlock Secure Shopping & Get 50% Off Transaction Fee (Limited
              Offer)
            </span>
            <span className="block mx-4 w-5 h-5 bg-[#BEE9FF] rounded-full">
              
              </span>
          </div>
         
        </div>
      </div>
      <style>{`
        .scroll-containers {
          
          white-space: nowrap;
          position: absolute;
          inset-inline-start: 0%;
          inset-block-start: 0%;
          block-size: inherit;
          inline-size: inherit;
          align-items: center;
        }

        .primary-text {
          animation: primary-text 25s linear infinite;
        }
        .secondary-text {
          animation: secondary-text 20s linear infinite;
        }

        @keyframes primary-text {
          0% {
            transform: translateX(0);
          }
          100% {
            transform: translateX(-100%);
          }
        }
        @keyframes secondary-text {
          0% {
            transform: translateX(100%);
          }
          100% {
            transform: translateX(0);
          }
        }

        /*@keyframes marquee {
          0% {
            transform: translateX(0);
          }
          100% {
            transform: translateX(100%);
           
          }
          
        }

        .animate-marquee {
          animation: marquee 10s linear infinite;
        }

        .scroll-item {
          inline-size: 100%; 
          float: inline-start; 
        }*/
      `}</style>
    </div>
  );
};

export default Divider;
