import { useEffect, useState } from "react";
import { BiChevronDown } from "react-icons/bi";
import { AiOutlineSearch } from "react-icons/ai";
import { IoIosClose } from "react-icons/io";

interface SelectProps {
  label: string;
  name: string;
  options: { value: string; label: string }[];
  className?: string;
  onChange: (selectedValue: string) => void;
  value?: string;
  backendValue?: string;
  error?: string;
}

export default function MerchantSelect({
  label,
  options: initialOptions,
  className,
  backendValue,
  onChange,
  value,
  error,
}: SelectProps) {
  const [inputValue, setInputValue] = useState("");
  const [selected, setSelected] = useState("");
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState(initialOptions);

  useEffect(() => {
    setSelected(value || "");
  }, [value]);

  useEffect(() => {
    const selectedOption = initialOptions.find(
      (option) => option.value === value
    );
    setSelected(selectedOption ? selectedOption.label : "");
    setOptions(initialOptions);
  }, [value, initialOptions]);

  const handleOptionClick = (selectedValue: string, selectedLabel: string) => {
    setOpen(false);
    setInputValue("");
    onChange(selectedValue);
  };

  const handleInputChange = (input: string) => {
    setInputValue(input);
    const filteredOptions = initialOptions.filter((option) =>
      option.label.toLowerCase().includes(input.toLowerCase())
    );
    setOptions(filteredOptions);
  };

  return (
    <div className={`${className}`}>
      <div className="lg:text-[14px] text-[12px] font-normal text-[#040821] ">
        <label>{label}</label>
      </div>
      <div>
        <div className="font-medium relative">
          <div
            onClick={() => setOpen(!open)}
            className={`w-full h-[45px] pl-5 flex items-center font-normal justify-between border-2 border-solid rounded-md ${
              !selected && "text-[#040821]"
            }`}
          >
            {selected
              ? selected?.length > 25
                ? selected?.substring(0, 25) + "..."
                : selected
              : "Select Name"}

            {open ? (
              <IoIosClose
                size={18}
                className="text-gray-700"
                onClick={() => setInputValue("")}
              />
            ) : (
              <BiChevronDown size={20} className={`${open && "rotate-180"}`} />
            )}
          </div>
          <ul
            className={`bg-[#F8F8F8] mt-2 overflow-y-auto h-[485px] md:h-[400px] w-full absolute  rounded-md ${
              open
                ? "max-h-[500px] drop-shadow-lg border-2 border-solid"
                : "max-h-0 border-0"
            } `}
          >
            <div className="flex items-center px-2 sticky top-0 bg-[#F8F8F8] border-b">
              <AiOutlineSearch size={18} className="text-gray-700" />
              <input
                type="text"
                value={inputValue}
                autoFocus={true}
                onChange={(e) => handleInputChange(e.target.value)}
                placeholder={`Search ${label}`}
                className="flex-1 placeholder:text-gray-400 bg-[#F8F8F8] p-2 outline-none"
              />
              <IoIosClose
                size={18}
                className="text-gray-700"
                onClick={() => {
                  if (inputValue === "") {
                    setOpen(!open);
                  } else {
                    setInputValue("");
                  }
                }}
              />
            </div>
            {options?.map((option) => (
              <li
                key={option?.value}
                className={`p-2 text-sm hover:bg-sky-600 hover:text-white ${
                  option?.value === backendValue ? "bg-sky-600 text-white" : ""
                }`}
                onClick={() => handleOptionClick(option.value, option.label)}
              >
                {option?.label}
              </li>
            ))}
          </ul>
        </div>
      </div>

      {error && (
        <span className="error-text text-[#FF0101] text-[14px]">{error}</span>
      )}
    </div>
  );
}
