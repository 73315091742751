import React from "react";
import Button from "../button";
import { Verification } from "../../assets/images/icons";

const VerificationSuccess: React.FC = () => {
  return (
    <div className='flex flex-col  items-center gap-4 text-[#040821]'>
      <Verification className='w-[30%] h-[30%] md:h-full md:max-h-[200px] md:w-full' />
      <p className='text-center font-bold text-lg md:text-2xl'>
        Verification complete
      </p>
      <p className='text-center text-sm md:text-xl md:max-w-[60%] px-2 md:px-0'>
        Congratulations your account is now verified and it has been secured to
        your email for security purposes.
      </p>
      <Button size='full-half' variant='primary' href='/dashboard'>
        Continue
      </Button>
    </div>
  );
};

export default VerificationSuccess;
