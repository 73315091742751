import { useForm, ValidationError } from "@formspree/react";
import NewIcon from "../assets/images/new-icons";
import pattern from "../assets/images/new-desktop-pattern.png";
import phonePattern from "../assets/images/new-phone-pattern.png";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { CircleLoader } from "react-spinners";

const NewsSection = () => {
  const data = [
    { text: "FAQ", iconName: "faq-icon", navigateTo: "/faq" },
    {
      text: "BLOG",
      iconName: "blog-icon",
      navigateTo: "https://vendstash.wordpress.com/",
    },
    { text: "CONTACT", iconName: "support-icon", navigateTo: "/contact" },
  ];

  const [state, handleSubmit] = useForm("xanynaqy");
  if (state.succeeded) {
    Swal.fire({
      title: "Awesome!",
      text: "Thank you for subscribing",
      icon: "success",
    });
  }

  

 

  return (
    <>
      <div
        className="relative h-[329px] max-h-[330px] lg:h-[428px] lg:max-h-[429px]"
        id="download"
      >
        <img
          src={phonePattern}
          alt="Hero"
          className="absolute inset-0 object-cover w-full h-full lg:hidden"
        />

        <img
          src={pattern}
          alt="Hero"
          className="hidden lg:block absolute inset-0 object-cover w-full h-full"
        />
        <div className="absolute inset-0 flex flex-col items-center justify-center text-white px-3 max-w-[87%] lg:max-w-[888px] mx-auto ">
          <p className="font-medium text-[24px] leading-[32px] mb-6 lg:text-[45px] lg:leading-[52px] text-center">
            Download our mobile application
          </p>
          <p className="font-normal text-sm lg:font-medium lg:text-[22px] lg:leading-[32px] text-center">
          Download VendStash on iOS or Android and join over 1 million users transforming the way they shop and sell online.
          </p>

          <div className="flex flex-row gap-3.5 lg:gap-6 mt-10 lg:mt-12">
            <div className="w-[135px] h-[40px] lg:w-[216px] lg:h-[64px] cursor-pointer" onClick={()=>{
               Swal.fire({
                title: "Coming Soon",
                text: "Vendstash will be available on Google Play store soon",
                icon: "info",
              });
            }}>
              <NewIcon
                name="google-play-store"
                height={"full"}
                width={"full"}
              />
            </div>
            <div className="w-[119px] h-[40px] lg:w-[216px] lg:h-[64px] cursor-pointer"  onClick={()=>{
               Swal.fire({
                title: "Coming Soon",
                text: "Vendstash will be available on App store soon",
                icon: "info",
              });
            }}>
              <NewIcon name="apple-play-store" height={"full"} width={"full"} />
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="flex flex-col lg:flex-row">
          <div className="bg-[#FF5449] text-[#540003] py-12 px-6  lg:py-20 lg:px-16 text-center lg:text-left lg:basis-2/5">
            <p className="text-[24px] leading-[32px] lg:text-[32px] lg:leading-[40px] font-semibold mb-5 lg:mb-[56px]">
            Stay Updated with VendStash
            </p>
            <p className="text-sm  lg:text-[28px] lg:leading-[40px]">
            Subscribe to our newsletter for the latest updates on VendStash products, features, and more...
            </p>
          </div>
          <div className="bg-[#FFB4AB] py-12 px-6 lg:px-16 lg:py-[97.5px]  lg:basis-3/5 ">
            <form onSubmit={handleSubmit}>
              <div className="lg:mb-8 lg:pl-6 lg:py-4 pl-3 py-1.5 bg-[#FFF8F7] rounded-lg lg:rounded-2xl lg:h-[78px]  flex justify-between items-center pr-2 lg:pr-3">
                <input
                  id="email"
                  type="email"
                  name="email"
                  placeholder="Enter your email address"
                  className="outline-none border-0 bg-inherit w-8/12 placeholder:text-[#5C5F61] lg:placeholder:text-2xl placeholder:text-[12px] text-xl font-normal h-full"
                />
                <button
                  type="submit"
                  disabled={state.submitting}
                  className="bg-[#FF5449] hover:bg-[#DE3730] flex items-center text-xs justify-center text-white py-3 px-4  xl:max-w-[116px] xl:max-h-[54px] lg:py-5  lg:text-[18px] rounded-lg lg:rounded-2xl"
                >
                  Sign Up
                </button>
              </div>
              <ValidationError
                prefix="Email"
                field="email"
                errors={state.errors}
              />
            </form>
            <p className="text-[#191C1E] text-xs lg:text-base">
            By subscribing, you agree to our Terms of Service and acknowledge our Privacy Policy. *
            </p>
          </div>
        </div>
        <div className="grid grid-cols-3 h-[72px] lg:h-[128px]">
          {data.map((e: any, index: any) => {
            return (
              <div
                className={`col-span-1 flex items-center justify-center hover:bg-[#BEE9FF] ${
                  index == 0
                    ? ""
                    : "border-l-[0.5px] border-l-[#70787D] lg:border-l"
                } `}
                key={index}
              >
                <Link
                  to={e.navigateTo}
                  className="no-underline p-2"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className="flex flex-col lg:flex-row items-center justify-center">
                    <div className="h-5 w-5 lg:h-10 lg:w-10">
                      {" "}
                      <NewIcon name={e.iconName} />
                    </div>
                    <span>{e.text}</span>
                  </div>
                </Link>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default NewsSection;
