import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useRequest from "../../../components/hooks/use-request";
import Back from "../../../components/back";
import WithdrawalDetails from "./withdrawl-details";

interface UserDetails {
  bankName: string;
  bankAccountNumber: string;
}

interface DetailsProps {
  id: string;
  amount: number;
  status: string;
  created_at: string;
  user: UserDetails;
}

const Details = () => {
  const { id } = useParams<{ id: string }>();
  const [withdraw, setWithdraw] = useState<DetailsProps>({
    id: "",
    amount: 0,
    status: "",
    created_at: "",
    user: {
      bankName: "",
      bankAccountNumber: "",
    },
  });
  const { makeRequest: getWithdrawals } = useRequest(
    `/user/withdraw/${id}`,
    "GET"
  );

  useEffect(() => {
    const fetchData = async () => {
      const [response] = await getWithdrawals();
      setWithdraw(response?.data || []);
    };
    fetchData();
  }, []);
  return (
    <>
      <div className="flex justify-center">
        <h2 className="flex justify-between w-[35%] text-[24px] mt-3 font-bold">
          <Back /> Withdrawal Details
        </h2>
      </div>
      <WithdrawalDetails withdraw={withdraw} />
    </>
  );
};

export default Details;
