export function capitalizeFirstLetter(str: string) {
  return str
    ?.split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
}

export function formatCurrency(
  value: number | undefined | null,
  currency: string,
  display: "code" | "symbol"
) {
  try {
    if (value != null) {
      return value.toLocaleString("en-NG", {
        style: "currency",
        currency,
        minimumFractionDigits: 2,
        currencyDisplay: display,
      });
    } else {
      return "amount must be 200 above and less than 5,000,000";
    }
  } catch (e) {
    return "N/A";
  }
}

export function formatSimpleCurrency(value: string | number | null | undefined): string {
  try {
    if (value != null) {
      const numberValue = typeof value === "string" ? parseFloat(value) : value;
      if (!isNaN(numberValue)) {
        return numberValue.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
      } else {
        return "Invalid number";
      }
    }
    return "N/A";
  } catch (e) {
    return "N/A";
  }
}


export function formatDate(dateString: string) {
  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  return new Date(dateString).toLocaleDateString(undefined, options);
}

export function signOut() {
  localStorage.removeItem("token");
  localStorage.removeItem("profilePhoto");
  localStorage.removeItem("user");
  localStorage.removeItem("userEmail");
};

export function truncateEmail(email: string, maxLength = 15) {
  if (!email) return '';
  if (email.length <= maxLength) return email;
  const truncated = email.substring(0, maxLength) + '...';
  return truncated;
};

export function isLoggedIn() {
  const token = localStorage.getItem("token");
  return token != null;
}

export function maskEmail(email: string | null): string {
  if (!email) return "";
  const [name, domain] = email.split("@");
  if (name.length > 4) {
    return `${name.slice(0, 3)}${"*".repeat(name.length - 4)}${name[name.length - 1]}@${domain}`;
  }
  return email;
}



