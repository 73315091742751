import { Link } from "react-router-dom";
import Card from "../../onboarding-card";
import Input from "../../input";
import Button from "../../button";
import { CircleLoader } from "react-spinners";
import { Controller } from "react-hook-form";
import { useState } from "react";
import Visible from "../../../assets/images/Eye.svg";
import Invisible from "../../../assets/images/eye-regular.svg";
import { decryptData } from "../../../utils/encryption";

const Login = ({
  handleLogin,
  control,
  loading,
}: {
  handleLogin: (e: any) => void;
  control: any;
  loading: boolean;
}) => {
  const [viewPassword, setViewPassword] = useState(false);
  const rememberMeJSON = localStorage.getItem("rememberMe");
  const rememberMe = rememberMeJSON ? JSON.parse(rememberMeJSON) : null;
  const [rememberMeClicked, setRememberMeClicked] = useState(
    rememberMe ? true : false
  );

  const handleShowPassword = () => {
    setViewPassword((previousValue): any => !previousValue);
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  return (
   <Card title='Welcome back, superhuman!'>
    <form className="mt-6 md:mt-8" onSubmit={handleLogin}>
        <div className="flex flex-col gap-4 md:gap-6 mb-5">
          <div>
            <Controller
              name="email"
              control={control}
              defaultValue={rememberMe?.email || ""}
              rules={{
                required: "Email is required",
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: "Enter a valid email. E.g, example@gmail.com",
                },
              }}
              render={({ field, fieldState }) => (
                <Input
                  value={field.value}
                  placeholder="Email"
                  className="w-full "
                  label="Email address"
                  error={fieldState?.error?.message}
                  onChange={field.onChange}
                />
              )}
            />
          </div>

          <div className="w-full">
            <Controller
              name="password"
              control={control}
              defaultValue={
                rememberMe
                  ? decryptData(rememberMe.password, rememberMe.email)
                  : ""
              }
              rules={{
                required: "Password is required",
              }}
              render={({ field, fieldState }) => (
                <div className="w-full relative">
                  <Input
                    label="Password"
                    type={viewPassword ? "text" : "password"}
                    value={field.value}
                    placeholder="Enter your password"
                    className="w-full"
                    error={fieldState?.error?.message}
                    onChange={field.onChange}
                  />
                  <button
                    onClick={handleShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    className="w-5 h-5 absolute top-1/2 right-2 transform -translate-y-1/2 mt-3 md:mt-4"
                    type="button"
                  >
                    {viewPassword ? (
                      <img src={Invisible} alt="password" />
                    ) : (
                      <img src={Visible} alt="password" />
                    )}
                  </button>
                </div>
              )}
            />
          </div>
        </div>

        <div className="flex justify-between items-center">
          {/**
           * <div className="flex flex-row gap-1 md:gap-3 items-center">
            <Controller
              name="rememberMe"
              control={control}
              defaultValue={rememberMe ? true : false}
              render={({ field }) => (
                <input
                  type="checkbox"
                  id="rememberMe"
                  className="w-5 h-5 md:h-8 md:w-8 text-green-500 border-2 border-green-500 rounded focus:outline-none focus:border-green-700 cursor-pointer"
                  checked={rememberMeClicked}
                  onClick={() => setRememberMeClicked(!rememberMeClicked)}
                  {...field}
                />
              )}
            />
            <label className="text-sm md:text-base">Remember me</label>
          </div>
           */}

          <button className="text-[#0867AC] hover:text-[#35D081] font-normal text-sm ml-auto ">
            <Link to="/forgot-password">Forgot password?</Link>
          </button>
        </div>

        <div className="flex justify-center items-center mt-5">
          <Button size="lg" variant="primary" type="submit">
            {loading ? (
              <CircleLoader color="#ffffff" loading={loading} size={20} />
            ) : (
              "  Log in"
            )}
          </Button>
        </div>

        <div className="flex items-center justify-center mx-7 lg:mx-0 mt-5">
              <label className="ml-2 text-xs md:text-sm text-[#475467] cursor-pointer select-none">
                Don't have an account?
                <Link to="/signup">
                  <span className="text-[#0867AC] hover:text-[#35D081]"> Sign Up</span>
                </Link>
              </label>
            </div>
      </form>
   </Card>
  );
};

export default Login;
