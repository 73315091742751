import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import useRequest from "../../components/hooks/use-request";
import Toast, { showToast } from "../../components/toast";
import Button from "../../components/button";
import { CircleLoader } from "react-spinners";
import UserSignUp from "../../components/auth/signup";
import Card from "../../components/onboarding-card";
import UserCreatePassword from "../../components/auth/create-password";
interface SignupData {
  fullname: string;
  email: string;
  referralCode?: string;
  userType: string;
}
const Signup = () => {
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const [signupData, setSignupData] = useState<SignupData>({} as SignupData);
  const { loading, makeRequest } = useRequest("/user/signup", "POST");

  const { handleSubmit, control, watch } = useForm();

  const url = new URL(window.location.href);
  const referralCode = url.searchParams.get("r");

  const handleFirstStep = handleSubmit(async (formData) => {
    setSignupData({
      fullname: formData.fullname,
      email: formData.email,
      referralCode: formData.referralCode || referralCode,
      userType: formData.userType,
    });
    setStep(2);
  });


  const handleFinalSubmit = handleSubmit(async (formData) => {
    const userData = {
      ...signupData,
      password: formData.password,
    };
    const [response] = await makeRequest(userData, {
      r: userData.referralCode,
    });

    if (response.status) {
      localStorage.setItem("user", JSON.stringify(response?.data?.user));
      localStorage.setItem("userEmail",(response?.data?.user?.email));
      localStorage.setItem("token", response?.data?.token);
      localStorage.setItem("verifyToken", response?.data?.token);
      showToast(response.message, true, {
        position: "top-center",
      });
      navigate("/signup/verify");
    } else {
      showToast(response.message, response.status, {
        position: "top-center",
      });
    }
  });


  return (
    <>
    {step === 1 ? (
      <>
        <Card title="Create a VendStash account" classNames="mt-8">
          <div className="mt-3 flex flex-col items-center gap-y-5 md:gap-y-6 max-w-screen-xl mx-auto">
            <div className="w-full flex flex-col items-center">
              <div className="flex flex-col items-center w-full">
                <UserSignUp
                  handleCreateAccount={handleFirstStep}
                  control={control}
                  loading={loading}
                  referralCode={referralCode}
                />
              </div>
            </div>

            <div className="flex justify-center items-center w-full px-7 lg:px-0">
              <Button
                size="lg"
                variant="primary"
                type="submit"
                onClick={handleFirstStep}
              >
                {loading ? (
                  <CircleLoader color="#ffffff" loading={loading} size={20} />
                ) : (
                  "Continue"
                )}
              </Button>
            </div>

            <div className="flex flex-co items-center justify-center mx-7 lg:mx-0">
              <label className="ml-2 text-xs md:text-sm text-[#475467] cursor-pointer select-none">
                Already have an account?
                <Link to="/signin">
                  <span className="text-[#0867AC] hover:text-[#35D081]"> Log In</span>
                </Link>
              </label>
            </div>
          </div>
          <Toast />
        </Card>

        <div className="flex text-center flex-co items-center justify-center mx-7 lg:mx-0 py-5">
          <label className="ml-2 text-xs md:text-sm text-[#475467] cursor-pointer select-none">
            By signing up, you agree to our{" "}
            <Link to="/terms">
              <span className="text-[#0867AC]">Terms of Service</span>
            </Link>{" "}
            <br />
            and{" "}
            <Link to="/privacy-policy">
              <span className="text-[#0867AC]">Privacy Policy</span>
            </Link>{" "}
          </label>
        </div>
      </>
    ) : (
      <Card
        title="You will need a password"
        subtitle="Choose a password that's easy for you to remember."
        classNames="mt-8"
      >
        <div className="mt-3 flex flex-col items-center gap-y-5 md:gap-y-6 max-w-screen-xl mx-auto">
          <div className="w-full flex flex-col items-center">
            <div className="flex flex-col items-center w-full">
              <UserCreatePassword
                handleCreateAccount={handleFinalSubmit}
                control={control}
                loading={loading}
                watch={watch}
              />
            </div>
          </div>

          <div className="flex justify-center items-center w-full px-7 lg:px-0">
            <Button
              size="lg"
              variant="primary"
              type="submit"
              onClick={handleFinalSubmit}
            >
              {loading ? (
                <CircleLoader color="#ffffff" loading={loading} size={20} />
              ) : (
                "Continue"
              )}
            </Button>
          </div>
        </div>
        <Toast />
      </Card>
    )}
  </>
  );
};

export default Signup;
