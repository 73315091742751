import React, { ChangeEvent, useState } from "react";
import { Controller } from "react-hook-form";
import "react-toastify/dist/ReactToastify.css";
import Input from "../../input";
import Visible from "../../../assets/images/Eye.svg";
import Invisible from "../../../assets/images/eye-regular.svg";
import RadioButton from "../../radio-button";

const UserCreatePassword = ({
  handleCreateAccount,
  control,
  watch,
}: {
  handleCreateAccount: (e: any) => void;
  control: any;
  loading: boolean;
  watch: any;
}) => {
  const [viewPassword, setViewPassword] = useState(false);
  const [viewConfirmPassword, setViewConfirmPassword] = useState(false);
  const passwordValue = watch("password") || "";
  const hasAlphabet = /[A-Za-z]/.test(passwordValue);
  const hasNumber = /\d/.test(passwordValue);
  const hasSpecialCharacter = /[!@#$%^&*(),.?":{}|<>]/.test(passwordValue);
  const meetsLengthRequirement = passwordValue?.length >= 8;

  function handleShowPassword() {
    setViewPassword((prev) => !prev);
  }
  function handleShowConfirmPassword() {
    setViewConfirmPassword((prev) => !prev);
  }

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  return (
    <form onSubmit={handleCreateAccount} className="w-full">
      <div className="flex flex-col justify-center gap-4 md:gap-6">
        <Controller
          name="password"
          control={control}
          defaultValue=""
          rules={{
            required: "Password is required",
            minLength: {
              value: 8,
              message: "Password must be at least 8 characters",
            },
            maxLength: {
              value: 70,
              message: "Password must not be more than 70 characters",
            },
            pattern: {
              value:
                /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&_\W])[A-Za-z\d@$!%*#?&_\W]{6,70}$/,
              message:
                "Password must contain at least one alphabet, one number, and one special character",
            },
          }}
          render={({ field, fieldState }) => (
            <div className="w-full relative">
              <Input
                label="Enter Password"
                type={viewPassword ? "text" : "password"}
                value={field.value}
                className="w-full"
                error={fieldState?.error?.message}
                onChange={field.onChange}
              />
              <button
                onClick={handleShowPassword}
                onMouseDown={handleMouseDownPassword}
                className="w-5 h-5 absolute top-1/2 right-2 transform -translate-y-1/2 mt-4"
                type="button"
              >
                {viewPassword ? (
                  <img src={Invisible} alt="password" />
                ) : (
                  <img src={Visible} alt="password" />
                )}
              </button>
            </div>
          )}
        />
      </div>

      <div className="mt-4 grid grid-cols-2 gap-y-4 text-[12px]">
        <RadioButton
          label="alphabet"
          name="radioGroup"
          checked={hasAlphabet}
          variant={hasAlphabet ? "primary" : "secondary"}
        />

        <RadioButton
          label="number"
          name="radioGroup"
          checked={hasNumber}
          variant={hasNumber ? "primary" : "secondary"}
        />

        <RadioButton
          label="8 characters"
          name="radioGroup"
          checked={meetsLengthRequirement}
          variant={meetsLengthRequirement ? "primary" : "secondary"}
        />

        <RadioButton
          label="special character"
          name="radioGroup"
          checked={hasSpecialCharacter}
          variant={hasSpecialCharacter ? "primary" : "secondary"}
        />
      </div>

      <div className="flex flex-col justify-center gap-4 md:gap-6">
        <Controller
          name="confirmPassword"
          control={control}
          defaultValue=""
          rules={{
            required: "Confirm your password",
            validate: (value) =>
              value === passwordValue || "Password do not match",
          }}
          render={({ field, fieldState }) => (
            <div className="w-full relative">
              <Input
                label="Confirm Password"
                type={viewConfirmPassword ? "text" : "password"}
                placeholder="Confirm Password"
                value={field.value}
                className="w-full mt-3"
                error={fieldState?.error?.message}
                onChange={field.onChange}
              />
              <button
                onClick={handleShowConfirmPassword}
                onMouseDown={handleMouseDownPassword}
                className="w-5 h-5 absolute top-1/2 right-2 transform -translate-y-1/2 mt-4"
                type="button"
              >
                {viewConfirmPassword ? (
                  <img src={Invisible} alt="password" />
                ) : (
                  <img src={Visible} alt="password" />
                )}
              </button>
            </div>
          )}
        />
      </div>
    </form>
  );
};

export default UserCreatePassword;
