// import { FaXTwitter } from "react-icons/fa6";
// import { AiFillInstagram } from "react-icons/ai";
// import { FaLinkedin } from "react-icons/fa";
// import { MdFacebook } from "react-icons/md";
// import avatar from "../../assets/images/avatar.png";

const Team = () => {
  return (
    <div className="my-4 mt- lg:mt-0 px-[4%]">
      {/* <div className="sm:max-w-screen-xl sm:mx-auto py-10">
        <p className="text-center text-xl sm:text-[30px] font-medium text-[#0979A1]">
          <span className="border border-t-0 border-l-0 border-r-0 border-b-[#0979A1]">
            About
          </span>{" "}
          Us
        </p>
        <p className="text-center mb-3 mt-3">Meet Our Team Members</p>
        <div className="flex flex-wrap justify-evenly items-center">
          <div className="flex flex-col gap-3 justify-center items-center w-full sm:w-1/2 md:w-1/3 lg:w-1/4">
            <img src={avatar} alt="avatar" />
            <p className="text-[#0979A1]">Morifeoluwa Jebutu</p>
            <p>Founder, VendStash</p>
            <div className="flex justify-between items-center gap-2">
              <MdFacebook style={{ color: "#0979A1" }} />
              <FaXTwitter style={{ color: "#0979A1" }} />
              <AiFillInstagram style={{ color: "#0979A1" }} />
              <FaLinkedin style={{ color: "#0979A1" }} />
            </div>
          </div>

          <div className="flex flex-col gap-3 justify-center items-center w-full sm:w-1/2 md:w-1/3 lg:w-1/4">
            <img src={avatar} alt="avatar" />
            <p className="text-[#0979A1]">Morifeoluwa Jebutu</p>
            <p>Founder, VendStash</p>
            <div className="flex justify-between items-center gap-2">
              <MdFacebook style={{ color: "#0979A1" }} />
              <FaXTwitter style={{ color: "#0979A1" }} />
              <AiFillInstagram style={{ color: "#0979A1" }} />
              <FaLinkedin style={{ color: "#0979A1" }} />
            </div>
          </div>

          <div className="flex flex-col gap-3 justify-center items-center w-full sm:w-1/2 md:w-1/3 lg:w-1/4">
            <img src={avatar} alt="avatar" />
            <p className="text-[#0979A1]">Morifeoluwa Jebutu</p>
            <p>Founder, VendStash</p>
            <div className="flex justify-between items-center gap-2">
              <MdFacebook style={{ color: "#0979A1" }} />
              <FaXTwitter style={{ color: "#0979A1" }} />
              <AiFillInstagram style={{ color: "#0979A1" }} />
              <FaLinkedin style={{ color: "#0979A1" }} />
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default Team;
