import React, { useState, useEffect } from "react";
import useRequest from "../../../components/hooks/use-request";
import Form from "./form";
import Back from "../../../components/back";
import Referral from "./referral";

interface EditProps {}

interface User {
  fullname: string;
  phone: string;
  socialMediaHandle: string;
  socialMediaPlatform: string;
  bankAccountNumber: string;
  bankName: string;
  bankCode: string;
  profilePhoto: string;
}

const EditProfile: React.FC<EditProps> = () => {
  const [files, setFiles] = useState<string[]>([]);
  const [user, setUser] = useState<User>();
  const [referrals, setRerrals] = useState<User>();
  const headers = {
    "Content-Type": "multipart/form-data",
  };
  const { response, makeRequest } = useRequest("/user/referrals", "GET", headers);

  useEffect(() => {
    const fetchData = async () => {
      const [response] = await makeRequest();
      setUser(response.data?.data?.user);
      setRerrals(response.data?.data);
    };
    fetchData();
  }, []);

  return (
    <>
      <div className="w-full mx-auto ">
        <div className="flex jstify-start">
          <div className="flex lg:w-[30%] w-[70%]">
            <Back />
            <p className="lg:text-lg text-[12px] font-bold w-[100%] pr-7 mt-4">
              Edit Profile
            </p>
          </div>
        </div>
      </div>

      <div className="md:flex gap-5 mt-5 px-5">
        <Form
          user={user}
          response={response}
          onChange={""}
          files={files}
          setFiles={setFiles}
        />

        <Referral user={user} referrals={referrals}  />
      </div>
    </>
  );
};

export default EditProfile;
