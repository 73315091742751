import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { showToast } from "../../components/toast";

interface AuthGuardProps {
  children: React.ReactNode;
}

const AuthGuard: React.FC<AuthGuardProps> = ({ children }) => {
  const navigate = useNavigate();
  const isLoggedIn = localStorage.getItem("token");
  const [hasShownToast, setHasShownToast] = useState(false);

  useEffect(() => {
  if (isLoggedIn === null && !hasShownToast) {
      navigate("/", { replace: true });
      // showToast("You are not authorized", false, {
      //   position: "top-center",
      // });
      setHasShownToast(true);
    }
  }, [isLoggedIn, navigate, hasShownToast]);

  return <>{children}</>;
};

export default AuthGuard;