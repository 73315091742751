import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import Input from "../new-input";
import { CircleLoader } from "react-spinners";
import useRequest from "../hooks/use-request";
import Textarea1 from "../contact-textarea";
import { showToast } from "../toast";
import { Link } from "react-router-dom";
import NewIcon from "../../assets/images/new-icons";
import Footer from "../footer";

const ContactUs = () => {
  const attachment = localStorage.getItem("uploadedFiles") || "[]";
  const [errorMessage, setErrorMessage] = useState("");
  const [success, setSuccess] = useState("");
  const { makeRequest, loading } = useRequest(
    `/customer-support/create-ticket`,
    "POST"
  );
  const { handleSubmit, control, reset, formState } = useForm();
  const { errors } = formState;

  const createHelp = handleSubmit(async (formData) => {
    const help = {
      fullName: formData.fullName,
      email: formData.email,
      phone: formData.phone,
      subject: formData.subject,
      message: formData.message,
      attachment,
    };
    const [response] = await makeRequest(help);
    if (response.status) {
      showToast("Support Request Sent", true, {
        position: "top-center",
      });
      setSuccess(response.message);
      reset();

      setTimeout(() => {
        window.location.reload();
      }, 5000);
    } else {
      showToast(response.message, false, {
        position: "top-center",
      });
      setErrorMessage(response.message);

      setTimeout(() => {
        setErrorMessage("");
      }, 7000);
    }
  });

  const data = [
    { name: "contact-facebook", navigateTo: "https://web.facebook.com/61554099410029/" },
    { name: "contact-linkedin", navigateTo: "https://www.linkedin.com/company/vendstash/" },
    { name: "contact-twitter", navigateTo: "https://twitter.com/vendstash" },
    { name: "contact-instagram", navigateTo: "https://www.instagram.com/vendstash_" },
  ];

  useEffect(() => {
    if (Object.keys(errors).length === 0) {
      setErrorMessage("");
    }
  }, [errors]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="pt-16 pb-8 pl-6 lg:p-[88px]  bg-[#F0F4F8] flex xl:items-center flex-col xl:flex-row">
        <div className="flex flex-col-reverse lg:flex-row lg:gap-[88px]">
          <p className="text-[#191C1E] lg:text-[#000000] text-[24px] leading-[28px] lg:text-[46px] lg:leading-[69px] font-semibold ">
            Get in touch
          </p>
          <div className="flex items-center gap-4 text-[12px] lg:text-[24px] leading-[24px] font-normal">
            <Link to="/" className="no-underline">
              <span className="text-[#605D64]">Home</span>
            </Link>
             <span className="w-4 h-4 lg:w-6 lg:h-6">
            <NewIcon name="header-arrow-forward" width={'full'} height={'full'}/>
          </span>
            <span>Contact</span>
          </div>
        </div>
        <div className="flex gap-4 lg:gap-6 items-center xl:ml-auto">
        <Link to="https://wa.me/2348065748268">
          <button className="bg-white border-[0.5px] lg:border border-[#C0C8CD] rounded-lg lg:rounded-2xl h-10 w-[148px] flex items-center justify-center gap-3 text-[#505355] lg:text-[20px] lg:leading-[28px] font-normal text-xs lg:font-medium lg:h-[67.2px] lg:w-[251px]">
         
           <span className=" w-4 h-4 lg:w-6 lg:h-6">
              <NewIcon name="contact-whatsapp" height={"full"} width={"full"} />
            </span>{" "}
            <span>Send message</span>
          </button></Link>

          <Link to='mailto:hello.vendstash@gmail.com'>
          <button className="bg-white border-[0.5px] lg:border border-[#C0C8CD] rounded-lg lg:rounded-2xl h-10 w-[148px] flex items-center justify-center gap-3 text-[#505355] lg:text-[20px] lg:leading-[28px] font-normal text-xs lg:font-medium lg:h-[67.2px] lg:w-[251px]">
            <span className=" w-4 h-4 lg:w-6 lg:h-6">
              <NewIcon name="contact-email" height={"full"} width={"full"} />
            </span>{" "}
            <span>Send us an email</span>
          </button></Link>

        
        </div>
      </div>
      <div className="bg-white py-16 lg:py-32">
        <div className="w-full px-6 md:w-[70%] xl:w-[50%] bg-white mx-auto items-center justify-center  ">
          <form
            className="w-full flex flex-col gap-6 lg:gap-12"
            onSubmit={createHelp}
          >
            <div className="">
              <Controller
                name="fullName"
                control={control}
                defaultValue=""
                rules={{
                  required: "Name is required",
                  minLength: {
                    value: 3,
                    message: "Name must be at least 3 characters",
                  },
                }}
                render={({ field, fieldState }) => (
                  <Input
                    label="FULL NAME"
                    value={field.value}
                    className="w-full"
                    error={fieldState?.error?.message}
                    onChange={field.onChange}
                  />
                )}
              />
            </div>
            <div className="">
            <Controller
              name="phone"
              control={control}
              defaultValue=""
              rules={{
                required: "Phone Number is required",
                pattern: {
                  value: /^\d{11}$/,
                  message: "Enter a valid eleven-digit phone number",
                },
              }}
              render={({ field, fieldState }) => (
                <Input
                  value={field.value}
                  label="PHONE NUMBER"
                  className="w-full"
                  error={fieldState?.error?.message}
                  onChange={field.onChange}
                />
              )}
            />
          </div> 

            <div>
              <Controller
                name="email"
                control={control}
                defaultValue=""
                rules={{
                  required: "Email is required",
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "Enter a valid email. E.g, example@gmail.com",
                  },
                }}
                render={({ field, fieldState }) => (
                  <Input
                    label="ENTER EMAIL ADDRESS"
                    value={field.value}
                    className="w-full"
                    error={fieldState?.error?.message}
                    onChange={field.onChange}
                  />
                )}
              />
            </div>

            <div>
            <Controller
              name="subject"
              control={control}
              defaultValue=""
              rules={{
                required: "Request title is required",
                minLength: {
                  value: 3,
                  message: "Request must be at least 3 characters",
                },
              }}
              render={({ field, fieldState }) => (
                <Input
                  label="MESSAGE SUBJECT"
                  value={field.value}
                  className="w-full"
                  error={fieldState?.error?.message}
                  onChange={field.onChange}
                />
              )}
            />
          </div>


            <div className="">
              <Controller
                name="message"
                control={control}
                defaultValue=""
                rules={{
                  required: "Description is required",
                  minLength: {
                    value: 10,
                    message: "Description must be at least 10 characters",
                  },
                }}
                render={({ field, fieldState }) => (
                  <Textarea1
                    label="ENTER MESSAGE HERE"
                    value={field.value}
                    error={fieldState?.error?.message}
                    onChange={field.onChange}
                  />
                )}
              />
            </div>
            <p className="text-red-500 text-center mt-4">{errorMessage}</p>
            {errorMessage && (
              <p className="text-[#0979A1] text-center mt-4">
                No account? <Link to="/signup">Sign up for free</Link>
              </p>
            )}
            <div className="flex justify-center">
              <button
                className={`rounded-lg lg:rounded-2xl  w-[250px] text-xs lg:text-xl font-medium lg:text-[20px]  flex items-center justify-center  h-[62px] bg-[#004D65] text-white`}
                type="submit"
            
              >
                {loading ? (
                  <CircleLoader color="#ffffff" loading={loading} size={20} />
                ) : (
                  "Send Message"
                )}
              </button>
            </div>
          </form>

          {errorMessage && (
            <p className="text-center text-[14px] mt-4">
              You can chat with our Customer Support Agent on Whatsapp or send
              us an email
            </p>
          )}

          <div className="bg-[#F0F4F8] py-[45px] px-[18px] lg:py-[96px] lg:px-3 rounded-[24px] flex flex-col items-center gap-12 lg:gap-[57px] mt-12 lg:mt-24 ">
            <p className="font-medium text-base lg:text-[28px] leading-[42px] text-[#505355] text-center">
              Follow us and don’t miss out on our content
            </p>
            <div className="flex items-center justify-center lg:gap-[72px]">
              {data.map((e: any, index:any) => {
                return (
                  <Link to={e.navigateTo} key={index} target="_blank" rel="noopener noreferrer">
                    <div className="w-[48px] h-[48px] lg:w-[88px] lg:h-[88px] ">
                      <NewIcon name={e.name} width={"full"} height={"full"} />
                    </div>
                  </Link>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <Footer/>
    </>
  );
};

export default ContactUs;
