import React from "react";
import "react-toastify/dist/ReactToastify.css";
import Input from "../../../components/input";
import useRequest from "../../../components/hooks/use-request";
import Toast, { showToast } from "../../../components/toast";
import { Controller, useForm } from "react-hook-form";
import { CircleLoader } from "react-spinners";
import { Link, useNavigate } from "react-router-dom";

const Delete = (props: any) => {
  const navigate = useNavigate();
  const { onNo } = props;
  const userToken = localStorage.getItem("token");
  const { makeRequest, loading } = useRequest(
    "/user/delete-account",
    "DELETE",
    {
      Authorization: `Bearer ${userToken}`,
    }
  );

  const { handleSubmit, control, reset } = useForm();

  const handleDelete = handleSubmit(async (formData) => {
    const userPassword = {
      password: formData.password,
    };
    const [response] = await makeRequest(userPassword);
    if (response.status) {
      showToast(response.message, true, {
        position: "top-center",
      });
      reset();
      navigate("/dashboard");
      onNo();
    } else {
      showToast(response.message, false, {
        position: "top-center",
      });
    }
  });

  return (
    <>
      <div className="mt-1 p-2 sm:p-4 flex justify-center items-center  mx-auto flex-col gap-5">
        <h1 className="text-[18px] md:text-[28px] text-[#040821] font-bold">
          Delete Account
        </h1>
        <p className="text-[16px] md:text-[26px] text-center">
          Are you sure you want to delete your account?
        </p>
        <p className="text-[14px] md:text-[24px] text-center">
          This action{" "}
          <span className="text-[#0979A1] uppercase">cannot be undone</span>. If
          you still wish to proceed, enter your password below.
        </p>

        <form onSubmit={handleDelete}>
          <Controller
            name="password"
            control={control}
            rules={{ required: "Password is required" }}
            render={({ field, fieldState }) => (
              <Input
                label="Password"
                type="password"
                value={field.value}
                onChange={field.onChange}
                placeholder="Enter your password"
                className="w-full"
                error={fieldState.error?.message}
              />
            )}
          />

          <p className="text-[12px] md:text-[16px] text-center mt-4">
            Please review our{" "}
            <Link to="terms">
              <button  onClick={onNo} className="text-[#0979A1]">Terms and Conditions</button>{" "}
            </Link>{" "}
            before proceeding
          </p>

          <div className="flex flex-col justify-center gap-4 md:flex-row mt-4">
            <button
              onClick={onNo}
              className="py-2 px-12 justify-center items-center gap-10 rounded-[10px] border border-[#0979A1] bg-[#0979A1] text-white text-center font-orbiter-fbs font-bold text-22"
            >
              Cancel
            </button>

            <button
              type="submit"
              className="flex py-2 px-12 justify-center items-center gap-10 rounded-[10px] border border-red-500 bg-red-500 text-white text-center font-orbiter-fbs font-bold text-22"
            >
              {loading ? (
                <CircleLoader color="#ffffff" loading={loading} size={20} />
              ) : (
                "Delete"
              )}
            </button>
          </div>
        </form>

        <Toast />
      </div>
    </>
  );
};

export default Delete;
