import { useEffect, useState } from "react";
import Back from "../../components/back";
import { Controller, useForm } from "react-hook-form";
import Input from "../../components/input";
import Button from "../../components/button";
import Select from "../../components/select";
import UploadEvidence from "./upload";
import useRequest from "../../components/hooks/use-request";
import { showToast } from "../../components/toast";
import { Modal } from "antd";
import { Link } from "react-router-dom";
import useSelectedOrder from "../../components/hooks/use-selected-order";
import { CircleLoader } from "react-spinners";

interface OrderData {
  id: string;
  orderId: string;
  buyerEmail: string;
  items: Item[];
}

interface Item {
  name: string;
}

const BuyerRefund = () => {
  const [isSubmissionSuccessful, setIsSubmissionSuccessful] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const userToken = localStorage.getItem("token");
  const [files, setFiles] = useState<string[]>([]);
  const [data, setData] = useState<OrderData[]>([]);
  const [buyerEmail, setBuyerEmail] = useState<string>("");
  const { selectedOrderId, selectOrder } = useSelectedOrder();

  const { makeRequest: getOrders } = useRequest("/orders", "GET");

  const { loading, makeRequest } = useRequest(
    selectedOrderId ? `/orders/${selectedOrderId}/request-refund` : "",
    "POST",
    {
      Authorization: `Bearer ${userToken}`,
    }
  );

  const Refund: Record<string, string> = {
    bad: "Bad goods",
    incomplete: "Incomplete goods",
    delay: "Delayed delivery",
    delivery: "No delivery",
  };

  useEffect(() => {
    const fetchData = async () => {
      if (buyerEmail) {
        const [response] = await getOrders(undefined, { email: buyerEmail });
        const ordersData = response?.data?.orders || [];
        setData(ordersData);
      }
    };
    fetchData();
  }, [buyerEmail, setData]);

  const handleChangeEmail = (e: any) => {
    setBuyerEmail(e.target.value);
    const selectedOrder = data.find(
      (order) => order.buyerEmail === e.target.value
    );
    if (selectedOrder) {
      selectOrder(selectedOrder.orderId);
    }
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const { handleSubmit, control, reset } = useForm();

  const handleFormSubmit = handleSubmit(async (formData) => {
    const proof = files.flat();
    const userData = {
      email: buyerEmail,
      reason: formData.reason,
      proof: proof.flat(),
    };
    const [response] = await makeRequest(userData);
    if (response.status) {
      setIsSubmissionSuccessful(true);
      showToast(response.message, true, {
        position: "top-center",
      });
      reset();
    } else {
      showToast(response.message, false, {
        position: "top-center",
      });
    }
  });

  return (
    <>
      <Back />

      <div className="p-2 md:p-4 mt-8 max-w-screen-xl mx-auto flex flex-col lg:w-[50%]">
        <p className="text-center font-bold text-[26px]">
          Refund payment for an order
        </p>

        <form onSubmit={handleFormSubmit}>
          <Input
            label="Buyer's Email Address"
            className=""
            value={buyerEmail}
            onChange={handleChangeEmail}
          />

          <div className="mt-4">
            <Controller
              name="selectedOrder"
              control={control}
              defaultValue=""
              rules={{ required: "Select an Order" }}
              render={({ field, fieldState }) => (
                <Select
                  label="Order"
                  name="selectedOrder"
                  options={(data as OrderData[]).map((order) => ({
                    value: order.orderId,
                    // label: order.items.map((item) => item.name).join(", "),
                    label: order.orderId
                  }))}
                  className=""
                  onChange={(selectedValue) => {
                    field.onChange(selectedValue);
                    selectOrder(selectedValue);
                  }}
                  value={field.value}
                  error={fieldState?.error?.message}
                />
              )}
            />
          </div>

          <div className="h-[130px] mt-3">
            <Controller
              name="reason"
              control={control}
              defaultValue=""
              rules={{ required: "Select a Platform" }}
              render={({ field, fieldState }) => (
                <Select
                  label="Reason for refund"
                  name="reason"
                  options={Object.entries(Refund).map((platform) => {
                    const [value, label] = platform;
                    return {
                      value,
                      label,
                    };
                  })}
                  className=""
                  onChange={(selectedValue) => {
                    field.onChange(selectedValue);
                  }}
                  value={field.value}
                  error={fieldState?.error?.message}
                />
              )}
            />
          </div>
          <p className="h-10">Upload Proof</p>
          <UploadEvidence files={files} setFiles={setFiles} />
          <div className="flex gap-20 justify-center mt-14">
            <Button size="sm" variant="primary" onClick={showModal}>
              {loading ? (
                <CircleLoader color="#ffffff" loading={loading} size={20} />
              ) : (
                "Continue"
              )}
            </Button>
          </div>
        </form>

        <Modal
          visible={isModalVisible && isSubmissionSuccessful}
          onOk={handleOk}
          onCancel={handleCancel}
          width={690}
          closable={true}
          footer={null}
          className="j flex justify-center justify-items-center px-auto mt-24"
        >
          <h1 className="text-center font-bold text-[#040821] text-[30px]">
            Your request is being processed.
          </h1>
          <p className="text-center text-[24px] font-normal mt-3">
            We will send you a notification when it is completed.
          </p>
          <div className="flex justify-center mx-auto w-full mt-10">
            <Button variant="primary" size="sm">
              <Link to="/dashboard">Back to Dashboard</Link>
            </Button>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default BuyerRefund;
