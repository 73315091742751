import React from "react";
import Icon from "../../../assets/images/icons";

interface Statistics {
  balance: number;
  inflow: number;
  outflow: number;
  incomingEscrow: number;
  outgoingEscrow: number;
}

interface AccountCardsProps {
  statistics: Statistics;
}

export const WalletCards = ({ statistics }: AccountCardsProps) => {
  const balance = statistics?.balance;
  const formattedBalance = balance?.toLocaleString("en-US");

  const inflow = statistics?.inflow;
  const formattedInflow = inflow?.toLocaleString("en-US");

  const outflow = statistics?.outflow;
  const formattedOutflow = outflow?.toLocaleString("en-US");

  const escrow = statistics?.incomingEscrow;
  const formattedEscrow = escrow?.toLocaleString("en-US");

  const outEscrow = statistics?.outgoingEscrow;
  const formattedOutEscrow = outEscrow?.toLocaleString("en-US");
  return (
    <div className="grid md:grid-cols-5 grid-cols-2 md:gap-8 gap-5 mt-7 w-full ">
      <section className="bg-[#0979A1] rounded-md border  border-[#fff]">
        <div className="flex justify-between px-3 py-2 mx-2 mt-6 bg-white rounded-full w-[40px] h-[40px]">
          <Icon name="wallet" />
        </div>
        <p className="font-medium md:text-[16px] text-[12px] text-white pl-3 mt-4">
          Wallet Balance
        </p>
        <p className="md:text-[16px] text-[12px] font-bold pl-3  md:h-14 h-10 text-white">
          {" "}
          NGN {formattedBalance}.00
        </p>
      </section>

      <section className="bg-[#14A256] rounded-md border  border-[#fff]">
        <div className="flex justify-between px-3 py-2 mx-2 mt-6 bg-white rounded-full w-[40px] h-[40px]">
          <Icon name="wallet" />
        </div>
        <p className="font-medium md:text-[16px] text-[12px] text-white pl-3 mt-4">
          Inflow
        </p>
        <p className="md:text-[16px] text-[12px] font-bold pl-3  md:h-14 h-10 text-white">
          {" "}
          NGN {formattedInflow}.00
        </p>
      </section>

      <section className="bg-[#9864B1] rounded-md border  border-[#fff]">
        <div className="flex justify-between px-3 py-2 mx-2 mt-6 bg-white rounded-full w-[40px] h-[40px]">
          <Icon name="wallet" />
        </div>
        <p className="font-medium md:text-[16px] text-[12px] text-white pl-3 mt-4">
          Outflow
        </p>
        <p className="md:text-[16px] text-[12px] font-bold pl-3  md:h-14 h-10 text-white">
          {" "}
          NGN {formattedOutflow}.00
        </p>
      </section>

      <section className="bg-[#A53860] rounded-md border  border-[#fff]">
        <div className="flex justify-between px-3 py-2 mx-2 mt-6 bg-white rounded-full w-[40px] h-[40px]">
          <Icon name="wallet" />
        </div>
        <p className="font-medium md:text-[16px] text-[12px] text-white pl-3 mt-4">
           In Escrow
        </p>
        <p className="md:text-[16px] text-[12px] font-bold pl-3  md:h-14 h-10 text-white">
          {" "}
          NGN {formattedEscrow}.00
        </p>
      </section>

      <section className="bg-[#A53860] rounded-md border  border-[#fff]">
        <div className="flex justify-between px-3 py-2 mx-2 mt-6 bg-white rounded-full w-[40px] h-[40px]">
          <Icon name="wallet" />
        </div>
        <p className="font-medium md:text-[16px] text-[12px] text-white pl-3 mt-4">
           Out Escrow
        </p>
        <p className="md:text-[16px] text-[12px] font-bold pl-3  md:h-14 h-10 text-white">
          {" "}
          NGN {formattedOutEscrow}.00
        </p>
      </section>
    </div>
  );
};
