import { Controller } from "react-hook-form";
import Button from "../../button";
import Input from "../../input";
import { CircleLoader } from "react-spinners";
import Card from "../../onboarding-card";
import { Link } from "react-router-dom";
import NewIcon from "../../../assets/images/new-icons";
const Password = ({
  handleForgotPassword,
  control,
  loading,
}: {
  handleForgotPassword: (e: any) => void;
  control: any;
  loading: boolean;
}) => {
  return (
    <Card
      title="Forgot Password"
      subtitle="No worries! Enter your account’s email, and we’ll send you a 4-digit code to reset your password."
       classNames="my-10"
    >
      <form onSubmit={handleForgotPassword}>
        <Controller
          name="email"
          control={control}
          defaultValue=""
          rules={{
            required: "Email is required",
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: "Enter a valid email. E.g, example@gmail.com",
            },
          }}
          render={({ field, fieldState }) => (
            <Input
              value={field.value}
              placeholder="Email"
              className="w-full "
              label="Email address"
              error={fieldState?.error?.message}
              onChange={field.onChange}
            />
          )}
        />
        <div className="flex justify-center items-center mt-5">
          <Button size="lg" variant="primary" type="submit">
            {loading ? (
              <CircleLoader color="#ffffff" loading={loading} size={20} />
            ) : (
              " Continue"
            )}
          </Button>
        </div>

        <div className="flex items-center justify-center mx-7 lg:mx-0 mt-5">
          <Link to="/signin" className="flex text-[#667085] font-normal text-sm">
            <NewIcon name="backicon" /> Back to Login
         
          </Link>
        </div>
      </form>
    </Card>
  );
};

export default Password;
