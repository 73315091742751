import React, { useState } from "react";
import { Controller } from "react-hook-form";
import "react-toastify/dist/ReactToastify.css";
import Input from "../../input";
import Select from "../../select";

const UserSignUp = ({
  handleCreateAccount,
  control,
  referralCode,
}: {
  handleCreateAccount: (e: any) => void;
  control: any;
  loading: boolean;
  referralCode: string | null;
}) => {
  const [viewPassword, setViewPassword] = useState("");

  const userTypeOptions = [
    { value: "buyer", label: "Buying" }, 
    { value: "merchant", label: "Selling" }, 
  ];


  return (
    <form onSubmit={handleCreateAccount} className="w-full">
      <div className="flex flex-col justify-center gap-4 md:gap-6">
        <Controller
          name="userType"
          control={control}
          defaultValue=""
          rules={{ required: "Select a user type" }}
          render={({ field, fieldState }) => (
            <Select
              label="How do you plan to use VendStash?"
              name="userType"
              options={userTypeOptions}
              className="w-full"
              onChange={(selectedValue) => {
                field.onChange(selectedValue);
              }}
              value={field.value}
              error={fieldState?.error?.message}
              message="For sign-up purposes only – you can still buy or sell regardless of your choice." 
            />
          )}
        />
        <Controller
          name="fullname"
          control={control}
          defaultValue=""
          rules={{
            required: "Business name is required",
            minLength: {
              value: 3,
              message: "Name must be at least 3 characters",
            },
          }}
          render={({ field, fieldState }) => (
            <Input
              label="Name"
              value={field.value}
              placeholder="e.g. John Doe"
              className="w-full"
              error={fieldState?.error?.message}
              onChange={field.onChange}
            />
          )}
        />{" "}
        <Controller
          name="email"
          control={control}
          defaultValue=""
          rules={{
            required: "Email is required",
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: "Enter a valid email. E.g, example@gmail.com",
            },
          }}
          render={({ field, fieldState }) => (
            <Input
              label="Email Address"
              value={field.value}
              placeholder="e.g. johndoe@email.com"
              className="w-full "
              error={fieldState?.error?.message}
              onChange={field.onChange}
            />
          )}
        />{" "}
        <Controller
          name="referralCode"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <Input
              label="Referral Code (optional)"
              value={referralCode ? referralCode : field.value}
              placeholder="Enter your referral code"
              className="w-full "
              onChange={field.onChange}
            />
          )}
        />{" "}

      </div>
    </form>
  );
};

export default UserSignUp;
