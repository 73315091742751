import { BiPhoneCall } from "react-icons/bi";
import { IoMdMail } from "react-icons/io";
import contact from "../../assets/images/contact.png";

const Contact = () => {
  return (
    <div className="my-4 mt- lg:mt-0 px-[4%]">
      <div className="sm:max-w-screen-xl sm:mx-auto py-10">
        <p className="text-center text-xl sm:text-[30px] font-medium text-[#0979A1]">
          <span className="border border-t-0 border-l-0 border-r-0 border-b-[#0979A1]">
            Contact
          </span>{" "}
          Us
        </p>
        <p className="text-center mt-5">
          Have any questions or concerns? Reach out to our team.
        </p>
        <div className="flex justify-center items-center mt-5 mb-5">
          <img src={contact} alt="Contact Us" />
        </div>
        <div className="flex justify-around items-center">
          <div className="flex gap-3 items-center">
            <BiPhoneCall style={{ color: "#0979A1" }} />{" "}
            <span>+2347010680569</span>
          </div>
          <div className="flex gap-3 items-center">
            <IoMdMail style={{ color: "#0979A1" }} />{" "}
            <span>vendstash@gmail.com</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
