import { ChangeEvent, FC } from "react";
import Icon from "../../assets/images/icons";

interface SearchProps {
  setSearch: (value: string) => void;
}

const Search: FC<SearchProps> = ({ setSearch }) => {
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  return (
    <div className="relative flex mb-8 border border-gray-300 rounded-lg h-[50px] pl-4 md:pl-14">
      <Icon name="search" className=" w-5 text-gray-500" />

      <input
        placeholder="Search for merchant or vendor"
        className="w-full  px-8 bg-transparent rounded h-[50px] focus:outline-none focus:border-blue-500"
        onChange={handleChange}
      />
    </div>
  );
};

export default Search;
