import React from "react";
import PlaceholderImage from "../assets/images/Ellipse 1.svg";
import Icon from "../assets/images/icons";
import { capitalizeFirstLetter, formatDate } from "../utils/functions";

interface TableColumn {
  header: string;
  accessor: string;
}

interface TableRow {
  [key: string]: any;
}

interface TableProps {
  columns: TableColumn[];
  data: TableRow[];
  selectedUserId: string | null;
  currentPage?: number | null;
  onPageChange?: number | null;
  onUserClick: (id: string) => void;
}

const Table: React.FC<TableProps> = ({
  columns,
  data,
  onUserClick,
  selectedUserId,
}) => {
  const getStatusColor = (status: string) => {
    switch (status) {
      case "paid":
        return "#D1FFC9";
      case "completed":
        return "#D1FFC9";
      case "pending":
        return "#CFF0FC";
      case "failed":
        return "#FFE2E2";
      case "confirmed":
        return "#D1FFC9";
      case "delivered":
        return "#CFF0FC";
      case "inactive":
        return "#D9D9D9";
      case "cancelled":
        return "#FFE2E2";
      default:
        return "transparent";
    }
  };

  return (
    <table className="table mt-6 w-full">
      <thead className="bg-[#CFF0FC] h-10">
        <tr className="text-[16px] text-left">
          {columns.map((column, index) => (
            <th className="font-medium px-4" key={index}>
              {column.header}
            </th>
          ))}
        </tr>
      </thead>
      <tbody className="text-[12px]">
        {data.map((row, rowIndex) => (
          <tr key={rowIndex}>
            {columns.map((column, colIndex) => (
              <td key={colIndex} className="pt-5 px-4">
                {" "}
                {column.accessor === "created_at" ||
                column.accessor === "createdAt" ? (
                  formatDate(row[column.accessor])
                ) : column.accessor === "profilePhoto" ? (
                  <img
                    src={
                      row[column.accessor]
                        ? row[column.accessor]
                        : PlaceholderImage
                    }
                    width={40}
                    alt="profile"
                  />
                ) : column.accessor === "id" ? (
                  <button onClick={() => onUserClick(row["id"])}>
                    <Icon name="dotIcon" />
                  </button>
                ) : column.accessor === "orderId" ? (
                  <button onClick={() => onUserClick(row["orderId"])}>
                    <Icon name="dotIcon" />
                  </button>
                ) : column.accessor === "phone" && !row[column.accessor] ? (
                  "N/A"
                ) : column.accessor === "status" ? (
                  <p
                    className="-mt-1 text-[12px] text-center font-normal w-20 p-1 rounded-lg"
                    style={{
                      backgroundColor: getStatusColor(row[column.accessor]),
                    }}
                  >
                    {capitalizeFirstLetter(row[column.accessor])}
                  </p>
                ) : (
                  row[column.accessor]
                )}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default Table;
