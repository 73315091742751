import { useState } from "react";
import Back from "../../components/back";
import VerificationSuccess from "../../components/auth/verification-success";
import CodeInput from "../../components/auth/code-input";

const Verify = () => {
  const [code, setCode] = useState("");
  const [isCodeInputSuccessful, setIsCodeInputSuccessful] = useState(false);

  const handleVerificationSuccess = () => {
    setIsCodeInputSuccessful(true);
  };

  return (
    <>
      <Back title="Back to Sign Up"/>

      {isCodeInputSuccessful ? (
        <VerificationSuccess />
      ) : (
        <CodeInput
          code={code}
          setCode={setCode}
          onSuccess={handleVerificationSuccess}
        />
      )}
    </>
  );
};

export default Verify;
