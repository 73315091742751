import { useRoutes } from "react-router-dom";
import Landing from "./landing";
import MainRoutes from "./main-routes";
import LandingPage from "../pages";
import Signup from "../pages/auth/signup";
import Verify from "../pages/auth/verify";
import Signin from "../pages/auth/signin";
import ForgotPassword from "../pages/auth/forgot-password";
import ResetPassword from "../pages/auth/reset-password";
import Blacklist from "../pages/blacklist";
import BlacklistMerchants from "../pages/blacklist/merchants";
import Report from "../pages/blacklist/report";
import Reviews from "../pages/blacklist/reviews";
import Pricing from "../pages/pricing";
import AuthorizePayment from "../pages/authorize-payment";
import ConfirmOrder from "../pages/authorize-payment/confirm-order";
import ReleaseFund from "../pages/release-fund";
import ReportOrder from "../pages/authorize-payment/report-order";
import BuyerRefund from "../pages/refund/buyer-refund";
import GeneratePayment from "../pages/dashboard/payment-dashboard/verify-payment";
import ConfirmBuyerPayment from "../pages/generate-payment/confirm-payment";
import PaymentLink from "../pages/generate-payment";
import About from "../pages/about";
import Works from "../pages/how-it-works";
import Privacy from "../pages/privacy";
import FAQ from "../pages/faq";
import RequestPayment from "../pages/request-payment";
import Error404 from "../pages/404";
import Terms from "../pages/terms-and-conditions";
import ContactUs from "../components/contact/contact-us";
import CreateOrder from "../pages/create-order";
import OrderDetails from "../pages/orders/details";
import Blog from "../pages/blog";

// ================|| ROUTING RENDER ||================ //

export default function ThemeRoutes() {
  return useRoutes([
    Landing,
    MainRoutes,
    {
      path: "/",
      element: <LandingPage />,
    },
    { path: "/signup", element: <Signup /> },
    { path: "/signup/verify", element: <Verify /> },
    { path: "/signin", element: <Signin /> },
    { path: "/pricing", element: <Pricing /> },
    { path: "/forgot-password", element: <ForgotPassword /> },
    { path: "/reset-password", element: <ResetPassword /> },
    { path: "/create-order", element: <CreateOrder /> },
    { path: "/orders/:orderId", element: <OrderDetails /> },
    { path: "/authorize-payment/:id", element: <AuthorizePayment /> },
    { path: "/confirm-payment/:id", element: <ConfirmBuyerPayment /> },
    { path: "/generate-payment/:id", element: <GeneratePayment /> },
    { path: "/release-fund/:orderId", element: <ReleaseFund /> },
    { path: "/confirm-order/:id", element: <ConfirmOrder /> },
    { path: "/upload-proof/:orderId", element: <RequestPayment /> },
    { path: "/blacklist", element: <Blacklist /> },
    { path: "/blacklist/merchants", element: <BlacklistMerchants /> },
    { path: "/blacklist/merchant/report", element: <Report /> },
    { path: "/blacklist/merchant/reviews/:id", element: <Reviews /> },
    { path: "/user/report-order", element: <ReportOrder /> },
    { path: "/buyer-refund", element: <BuyerRefund /> },
    { path: "/payment-link/:id", element: <PaymentLink /> },
    { path: "/about", element: <About /> },
    { path: "/how-it-works", element: <Works /> },
    { path: "/privacy-policy", element: <Privacy /> },
    { path: "/terms", element: <Terms /> },
    { path: "/faq", element: <FAQ /> },
    { path: "/contact", element: <ContactUs /> },
    { path: "/blog", element: <Blog/>},
    { path: "/*", element: <Error404 /> },
  ]);
}
