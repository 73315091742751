import Back from "../../components/back";
import Card from "../../components/card";
import Button from "../../components/button";
import { Controller } from "react-hook-form";
import "react-toastify/dist/ReactToastify.css";
import Input from "../../components/input";
import Select from "../../components/select";
import FileUpload from "../../components/blacklist/file-upload";
import { useForm } from "react-hook-form";
import { CircleLoader } from "react-spinners";
import useRequest from "../../components/hooks/use-request";
import { showToast } from "../../components/toast";
import Textarea from "../../components/textarea";
import { useCallback, useEffect, useState } from "react";
import SelectMerchant from "../../components/select-merchant";

interface Merchant {
  id: string;
  fullname: string;
}

interface User {
  email?: string;
}

const Report = () => {
  const [merchants, setMerchant] = useState<Merchant[]>([]);
  const [user, setUser] = useState<User>();

  const { loading, makeRequest } = useRequest("/reports", "POST");
  const [searchQuery, setSearchQuery] = useState("");
  const { makeRequest: getMerchant } = useRequest(`/user/patronizedmerchants/${user?.email}`, "GET");
  const { makeRequest: getUser } = useRequest("/user/profile", "GET");

    
  useEffect(() => {
    const fetchData = async () => {
      const [response] = await getUser();
      setUser(response?.data);
    };
    fetchData();
  }, []);

  const fetchData = useCallback(async () => {
    if(user?.email){
    const [response] = await getMerchant(undefined);
    const filteredMerchants =
      response?.data?.sort((a: any, b: any) =>
        a.id.localeCompare(b.id)
      ) || [];
    
    setMerchant(filteredMerchants);
    }
  }, [getMerchant, user?.email]);

  useEffect(() => {
    fetchData();
  }, [user?.email]);
  
  const socialMediaPlatforms: Record<string, string> = {
    instagram: "Instagram",
    twitter: "Twitter (X)",
    facebook: "Facebook",
    snapchat: "Snapchat",
    other: "Other",
  };
  const { handleSubmit, control, reset } = useForm();

  
  const handleFormSubmit = handleSubmit(async (formData) => {
    const image = localStorage.getItem("uploadedFiles" || "[]" );
    const userData = {
      note: formData.note,
      socialPlatform: formData.socialPlatform,
      phone: formData.phone,
      socialHandle: formData.socialHandle,
      merchantId: formData.merchantId,
      image, 
    };
    const [response] = await makeRequest(userData);
    if (response.status) {
      showToast(response.message, true, {
        position: "top-center",
      });
      setTimeout(() => {
        window.location.reload();
      }, 5000);
      reset();
    } else {
      showToast(response.message, false, {
        position: "top-center",
      });
    }
  });

  return (
    <div>
      <div>
        <div className="flex jstify-start">
          <div className="inline-block">
            <Back />
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-5 w-[100%] lg:w-[70%] mx-auto p-2 -mt-6">
        <div className="mt-8 p-2 hidden md:block">
          <p className="text-lg font-bold">Report a merchant</p>
        </div>
        <div className="mt-5 ml-6 md:ml-12 md:mt-6 md:hidden">
          <p className="text-lg font-bold">Report a merchant</p>
        </div>
        <p className="font-semibold text-sm md:text-lg">
        To report a merchant, please fill in the following details.
        </p>

        <Card>
          <form onSubmit={handleFormSubmit} className="">
            <div className="flex flex-col md:flex-row gap-6 flex-wrap justify-center">
              <div className="flex-[40%]">
                <Controller
                  name="merchantId"
                  control={control}
                  defaultValue=""
                  rules={{ required: "Select a Merchant" }}
                  render={({ field, fieldState }) => (
                    <SelectMerchant
                      label="Merchant Name"
                      name="merchantName"
                      options={merchants.map((merchant) => ({
                        value: merchant?.id,
                        label: merchant?.fullname,
                      }))}
                      className="w-full"
                      onChange={(selectedValue) =>
                        field.onChange(selectedValue)
                      }
                      value={field.value}
                      error={fieldState?.error?.message}
                    />
                  )}
                />

                <Controller
                  name="phone"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: "Phone Number is required",
                    pattern: {
                      value: /^\d{11}$/,
                      message: "Enter a valid eleven-digit phone number",
                    },
                  }}
                  render={({ field, fieldState }) => (
                    <>
                      <Input
                        value={field.value}
                        label="Phone Number"
                        className="w-full"
                        error={fieldState?.error?.message}
                        onChange={field.onChange}
                      />
                    </>
                  )}
                />
              </div>

              <div className="flex-[40%]">
                <Controller
                  name="socialPlatform"
                  control={control}
                  defaultValue=""
                  rules={{ required: "Select a Platform" }}
                  render={({ field, fieldState }) => (
                    <Select
                      label="Social Media Platform"
                      name="merchantSocialMediaPlatform"
                      options={Object.entries(socialMediaPlatforms).map(
                        (platform) => {
                          const [value, label] = platform;
                          return {
                            value,
                            label,
                          };
                        }
                      )}
                      className="w-full"
                      onChange={(selectedValue) => {
                        field.onChange(selectedValue);
                      }}
                      value={field.value}
                      error={fieldState?.error?.message}
                    />
                  )}
                />
              </div>
              <div className="flex-[40%]">
                <Controller
                  name="socialHandle"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: "Media handle is required",
                    minLength: {
                      value: 3,
                      message: "Name must be at least 3 characters",
                    },
                  }}
                  render={({ field, fieldState }) => (
                    <>
                      <Input
                        value={field.value}
                        label="Social Media Handle"
                        className="w-full"
                        error={fieldState?.error?.message}
                        onChange={field.onChange}
                      />
                    </>
                  )}
                />
              </div>
            </div>

            <p className="mt-5 text-base md:text-[18px] text-center">
              Add screenshots or pictures{" "}
              <span className="text-[#0979A1]">
                *(make sure no personal information is included)*
              </span>
            </p>

            <div className="flex justify-center">
              <FileUpload />
            </div>

            <Controller
              name="note"
              control={control}
              defaultValue=""
              rules={{
                required: "Note is required",
                minLength: {
                  value: 3,
                  message: "Note must be at least 3 characters",
                },
              }}
              render={({ field, fieldState }) => (
                <>
                  <Textarea
                    value={field.value}
                    label="Additional Note"
                    className="w-full"
                    error={fieldState?.error?.message}
                    onChange={field.onChange}
                  />
                </>
              )}
            />

            <div className="flex justify-center items-center my-8">
              <Button size="full-half" variant="primary" type="submit">
                {loading ? (
                  <CircleLoader color="#ffffff" loading={loading} size={20} />
                ) : (
                  "Submit"
                )}
              </Button>
            </div>
          </form>
        </Card>
      </div>
    </div>
  );
};

export default Report;
