import { CircleLoader } from "react-spinners";
import Button from "../../button";
import Input from "../../input";
import { Controller } from "react-hook-form";
import Visible from "../../../assets/images/Eye.svg";
import Invisible from "../../../assets/images/eye-regular.svg";
import { useState } from "react";
import Card from "../../onboarding-card";
import RadioButton from "../../radio-button";
import { Link } from "react-router-dom";
import NewIcon from "../../../assets/images/new-icons";

const ResetPassword = ({
  handleResetPassword,
  control,
  loading,
  watch,
}: {
  handleResetPassword: (e: any) => void;
  control: any;
  loading: boolean;
  watch: any;
}) => {
  const [viewPassword, setViewPassword] = useState("");
  const [confirmViewPassword, setConfirmViewPassword] = useState("");
  const passwordValue = watch("password") || "";
  const hasAlphabet = /[A-Za-z]/.test(passwordValue);
  const hasNumber = /\d/.test(passwordValue);
  const hasSpecialCharacter = /[!@#$%^&*(),.?":{}|<>]/.test(passwordValue);
  const meetsLengthRequirement = passwordValue?.length >= 8;

  const handleShowPassword = () => {
    setViewPassword((previousValue): any => !previousValue);
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const handleShowConfirmPassword = () => {
    setConfirmViewPassword((previousValue): any => !previousValue);
  };

  const handleMouseDownConfirmPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  return (
    <Card
      title="Reset your password"
      subtitle="Choose a password that's easy for you to remember."
      classNames="my-10"
    >
      <form className="flex flex-col gap-y-3" onSubmit={handleResetPassword}>
        <Controller
          name="resetPasswordToken"
          control={control}
          defaultValue=""
          rules={{
            required: "Code is required",
            minLength: {
              value: 4,
              message: "Code must be 4 digits",
            },
          }}
          render={({ field, fieldState }) => (
            <Input
              value={field.value}
              placeholder="Code"
              className="w-full "
              type="number"
              label="Confirmation Code"
              error={fieldState?.error?.message}
              onChange={field.onChange}
            />
          )}
        />

        <Controller
          name="password"
          control={control}
          defaultValue=""
          rules={{
            required: "Password is required",
            minLength: {
              value: 6,
              message: "Password must be at least 6 characters",
            },
            maxLength: {
              value: 70,
              message: "Password must not be more than 70 characters",
            },
            pattern: {
              value:
                /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&_\W])[A-Za-z\d@$!%*#?&_\W]{6,70}$/,
              message:
                "Password must contain at least one alphabet, one number, and one special character",
            },
          }}
          render={({ field, fieldState }) => (
            <div className="w-full relative">
              <Input
                label="New Password"
                type={viewPassword ? "text" : "password"}
                value={field.value}
                placeholder="Password"
                className="w-full"
                error={fieldState?.error?.message}
                onChange={field.onChange}
              />
              <button
                onClick={handleShowPassword}
                onMouseDown={handleMouseDownPassword}
                className="w-5 h-5 absolute top-1/2 right-2 transform -translate-y-1/2 mt-4"
                type="button"
              >
                {viewPassword ? (
                  <img src={Invisible} alt="password" />
                ) : (
                  <img src={Visible} alt="password" />
                )}
              </button>
            </div>
          )}
        />

        <div className="mt-4 grid grid-cols-2 gap-y-4 text-[12px]">
          <RadioButton
            label="alphabet"
            name="radioGroup"
            checked={hasAlphabet}
            variant={hasAlphabet ? "primary" : "secondary"}
          />

          <RadioButton
            label="number"
            name="radioGroup"
            checked={hasNumber}
            variant={hasNumber ? "primary" : "secondary"}
          />

          <RadioButton
            label="8 characters"
            name="radioGroup"
            checked={meetsLengthRequirement}
            variant={meetsLengthRequirement ? "primary" : "secondary"}
          />

          <RadioButton
            label="special character"
            name="radioGroup"
            checked={hasSpecialCharacter}
            variant={hasSpecialCharacter ? "primary" : "secondary"}
          />
        </div>

        <Controller
          name="confirmPassword"
          control={control}
          defaultValue=""
          rules={{
            required: "Password is required",
            minLength: {
              value: 6,
              message: "Password must be at least 6 characters",
            },
            maxLength: {
              value: 70,
              message: "Password must not be more than 70 characters",
            },
            pattern: {
              value:
                /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&_\W])[A-Za-z\d@$!%*#?&_\W]{6,70}$/,
              message:
                "Password must contain at least one alphabet, one number, and one special character",
            },
          }}
          render={({ field, fieldState }) => (
            <div className="w-full relative">
              <Input
                label="Confirm Password"
                type={confirmViewPassword ? "text" : "password"}
                value={field.value}
                placeholder="Confirm Password"
                className="w-full"
                error={fieldState?.error?.message}
                onChange={field.onChange}
              />
              <button
                onClick={handleShowConfirmPassword}
                onMouseDown={handleMouseDownConfirmPassword}
                className="w-5 h-5 absolute top-1/2 right-2 transform -translate-y-1/2 mt-4"
                type="button"
              >
                {confirmViewPassword ? (
                  <img src={Invisible} alt="password" />
                ) : (
                  <img src={Visible} alt="password" />
                )}
              </button>
            </div>
          )}
        />

        <Button size="lg" variant="primary" type="submit">
          {loading ? (
            <CircleLoader color="#ffffff" loading={loading} size={20} />
          ) : (
            "Reset my password"
          )}
        </Button>

        <div className="flex items-center justify-center mx-7 lg:mx-0 mt-5">
          <Link
            to="/signin"
            className="flex text-[#667085] font-normal text-sm"
          >
            <NewIcon name="backicon" /> Back to Login
          </Link>
        </div>
      </form>
    </Card>
  );
};

export default ResetPassword;
