import React, { useRef, useState, useEffect } from "react";
import ArrowUp from "../assets/images/arrow-up-icon.svg";
import ArrowMarked from "../assets/images/arrow-marked-icon.svg";
import useFileUpload from "./hooks/use-upload";
import Toast from "./toast";
import classNames from "classnames";
import Icon from "../assets/images/icons";

const Upload = ({ onChange, files, setFiles }: any) => {
  const {
    uploadFiles,
    loading: uploadLoading,
    progress: uploadProgress,
  } = useFileUpload("/upload");
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [isDragging, setIsDragging] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [showProgress, setShowProgress] = useState(false);
  const [remainingTime, setRemainingTime] = useState<number | null>(null);
  const [uploads, setUploads] = useState<any[]>([]);
  const [uploadedFileNames, setUploadedFileNames] = useState<string[]>([]);

  useEffect(() => {
    setShowProgress(uploadLoading);
  }, [uploadLoading]);

  useEffect(() => {
    if (uploadLoading) {
      const estimatedTimeRemaining = Math.round((100 - uploadProgress) * 100);
      setRemainingTime(estimatedTimeRemaining);
    } else {
      setRemainingTime(uploadProgress === 100 ? 100 : null);
    }
  }, [uploadLoading, uploadProgress]);

  useEffect(() => {
    if (uploadLoading && uploadProgress === 100) {
      setUploadSuccess(true);
    }
  }, [uploadLoading, uploadProgress]);

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = event.target.files;
    if (files) {
      const fileList = Array.from(files);
      const response = await uploadFiles(fileList);
      if (response && response.data) {
        setFiles((prevFiles: string[]) => [
          ...prevFiles,
          ...(response.data || []),
        ]);
        setUploadedFileNames((prevNames: string[]) => [
          ...prevNames,
          ...fileList.map((file) => file.name),
        ]);
      }
      if (onChange) {
        onChange(fileList);
      }
      setUploads((prevUploads) => [...prevUploads, { fileList, response }]);
    } else {
      setFiles([]);
    }
  };

  const handleRemoveFile = (index: number) => {
    setFiles((prevFiles: string[]) => prevFiles.filter((_, i) => i !== index));
    setUploadedFileNames((prevNames: string[]) =>
      prevNames.filter((_, i) => i !== index)
    );
    setUploads((prevUploads) => prevUploads.filter((_, i) => i !== index));
    if (uploads.length === 1) {
      setUploadSuccess(false);
    }
  };

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleDragEnter = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragging(false);

    const files = event.dataTransfer.files;
    if (files) {
      const fileList = Array.from(files);
      setUploadedFiles(fileList);
      uploadFiles(fileList);

      if (onChange) {
        onChange(fileList);
      }
    }
  };

  const handleAddAnotherFile = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <div>
      <div
        className={classNames(
          "rounded-lg p-4 flex justify-center  items-center flex-col w-full",
          {
            "bg-[#6A6A6A]": uploadSuccess,
            "bg-[#EAEAEA] border-dashed border-2 border-gray-400":
              !uploadSuccess,
          }
        )}
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
        onDragOver={(event) => event.preventDefault()}
        onDrop={handleDrop}
      >
        <img
          src={uploadSuccess ? ArrowMarked : ArrowUp}
          alt="Upload success status"
        />
        <input
          type="file"
          ref={fileInputRef}
          style={{ display: "none" }}
          onChange={handleFileChange}
          multiple
        />
        <button
          type="button"
          onClick={handleButtonClick}
          className={classNames("file-upload-button", "font-bold", {
            "bg-gray-300": isDragging,
          })}
        >
          <span className="text underline">Click to upload</span> or drag and
          drop
        </button>
        <p className="text-[#040821]">Maximum file size 100mb</p>

        {uploadedFileNames.map((fileName, index) => (
          <p key={index} className="flex gap-2 items-center">
            {fileName}
            <button type="submit" onClick={() => handleRemoveFile(index)}>
              <Icon name="deleteicon" />
            </button>
          </p>
        ))}

        <Toast />
      </div>

      <button
        type="button"
        onClick={handleAddAnotherFile}
        className="w-full text-center h-10 border border-[#0979A1] mt-5 rounded-md text-[#0979A1]"
      >
        Add Another File <span className="text-[26px]">+</span>
      </button>

      {uploads.map(({ fileList, response }, index) => (
        <div
          key={index}
          className="border-solid border-2 border-gray-400 rounded-md p-2 pl-10 h-18 items-center w-full mt-5"
        >
          <p className="font-bold text-[14px]">
            {uploadLoading ? "Uploading..." : "Completed"}
          </p>
          {uploadLoading && remainingTime !== null ? (
            <p className="text-[14px]">
              {remainingTime > 0
                ? `${remainingTime} secs remaining`
                : "Finishing up..."}
            </p>
          ) : (
            <p className="text-[14px]">{"100%"}: completed</p>
          )}

          <div className="progress-bar h-2 border">
            <div
              className="h-[2px] bg-blue-500"
              style={{ width: `background: blue ${uploadProgress}%` }}
            ></div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Upload;
