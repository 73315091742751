import Button from "../../components/button";
import err404 from "../../assets/images/404.svg";

const Error404 = () => {
  return (
    <>
      <div className="flex flex-col justify-center items-center gap-6 mt-6">
        <img src={err404} alt="pay" className="px-4 md:w-4/12" />
        <p className="text-lg md:text-3xl font-bold text-[#0979A1]">
          Oops! Something went wrong
        </p>
        <p className="text-center text-sm md:text-lg">
          We couldn’t find the page you were looking for
        </p>
        <Button href="/dashboard" size="sm" variant="primary">
          Back to Home
        </Button>
      </div>
    </>
  );
};

export default Error404;
