import React from "react";
import { capitalizeFirstLetter, formatDate } from "../../../utils/functions";

interface UserDetails {
  bankName: string;
  bankAccountNumber: string;
}

interface DetailsProps {
  withdraw: {
    id: string;
    amount: number;
    status: string;
    created_at: string;
    user: UserDetails;
  };
}

const Details = ({ title, value }: any) => {
  return (
    <div className="flex justify-between px-4 mt-3 md:w-[30%] mx-auto">
      <h3 className="font-normal text-[14px]">{title}</h3>
      <h3 className="font-medium text-[14px] pt-1">{value}</h3>
    </div>
  );
};

const WithdrawalDetails: React.FC<DetailsProps> = ({ withdraw }) => {
  const getStatusColor = (status: string) => {
    switch (status) {
      case "active":
        return "#completed";
      case "completed":
        return "#D1FFC9";
      case "failed":
        return "#FCCFCF";
      case "pending":
        return "#FBFCCF";
      default:
        return "transparent";
    }
  };
  const createdDate = withdraw?.created_at
    ? new Date(withdraw.created_at)
    : null;
  const date = createdDate ? createdDate.toLocaleDateString() : "N/A";
  const time = createdDate ? createdDate.toLocaleTimeString() : "N/A";

  return (
    <div className="mt-10 pt-7 rounded-lg w-full">
      <Details title="Bank Name:" value={withdraw?.user?.bankName || "N/A"} />
      <Details
        title="Account Number:"
        value={withdraw?.user?.bankAccountNumber || "N/A"}
      />

      <Details title="Amount:" value={withdraw?.amount || "N/A"} />

      <Details title="Date:" v value={`${formatDate(date)}`} />

      <Details title="Time:" v value={`${time}`} />

      <div className="flex justify-between px-4 mt-3 md:w-[30%] mx-auto">
        <h3 className="font-normal text-[14px]">Status:</h3>
        <p
          className="text-[10px] flex gap-3 w-24 justify-center h-6 pt-1 text-center rounded-md"
          style={{
            backgroundColor: getStatusColor(withdraw?.status || "N/A"),
          }}
        >
          <span
            style={{
              backgroundColor: withdraw?.status === "completed" ? "green" : "red",
            }}
            className="h-[6px] w-[6px] rounded-full mt-1"
          ></span>

          {capitalizeFirstLetter(withdraw?.status || "")}
        </p>
      </div>
    </div>
  );
};

export default WithdrawalDetails;
