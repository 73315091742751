import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Table from "../../components/table";
import classNames from "classnames";
import Icon from "../../assets/images/icons";
import { capitalizeFirstLetter, formatDate } from "../../utils/functions";
import { TailSpin } from "react-loader-spinner";
import { DatePicker } from "antd";
import dayjs from "dayjs";

const OrdersTable = ({
  data,
  selectedStatus,
  handleStatusChange,
  orderLoading,
  ordersStartDate,
  ordersEndDate,
  handleDateRangeChange,
}: any) => {
  const getStatusColor = (status: string) => {
    switch (status) {
      case "paid":
        return "#D1FFC9";
      case "completed":
        return "#D1FFC9";
      case "pending":
        return "#CFF0FC";
      case "failed":
        return "#D9D9D9";
      case "confirmed":
        return "#D1FFC9";
      case "delivered":
        return "#CFF0FC";
      case "cancelled":
        return "#FFE2E2";
      default:
        return "transparent";
    }
  };
  const navigate = useNavigate();
  const [filteredData, setFilteredData] = useState([]);
  const { RangePicker } = DatePicker;
  const columns = [
    { header: "Order ID", accessor: "icon", isIconColumn: true },
    { header: "Name of Merchant", accessor: "merchantBusinessName" },
    { header: "Amount", accessor: "amount" },
    { header: "Date", accessor: "created_at" },
    { header: "Status", accessor: "status" },
    { header: "", accessor: "orderId" },
  ];

  useEffect(() => {
    const filtered = data?.filter((order: any) => {
      if (order?.status) {
        return true;
      }
      return false;
    });
    setFilteredData(filtered);
  }, [data, selectedStatus, ordersStartDate, ordersEndDate]);

  const mappedData = filteredData.map((order: any) => {
    const totalAmount = order.items.reduce(
      (acc: number, item: any) => acc + item.amount,
      0
    );

    return {
      ...order,
      merchantBusinessName:
        `${capitalizeFirstLetter(order.merchantBusinessName)}` || "N/A",
      amount: `NGN ${totalAmount}.00`,
      icon: order.orderId,
    };
  });

  function handleUserClick  (orderId: string) {
    navigate(`/orders/${orderId}`);
  };

  return (
    <>
      <div className="rounded-md py-3  mt-5 md:block hidden">
        <div className="flex justify-between">
          <div className="flex flex-col items-center justify-start">
            <p className="text-[16px] font-semibold">Recent Orders</p>
            <hr className="md:bg-[#0979A1] h-[2px] w-full md:block" />
          </div>

          <div className="md:flex gap-2 hidden">
            <RangePicker
              value={[
                ordersStartDate ? dayjs(ordersStartDate) : null,
                ordersEndDate ? dayjs(ordersEndDate) : null,
              ]}
              onChange={(dates) => handleDateRangeChange(dates)}
              className="bg-transparent h-[44px] rounded-2xl"
            />

            <span className="text-[14px] font-medium pt-2">Filter by:</span>
            <select
              className="border text-[12px] p-3 rounded bg-[#0979A1] text-white"
              value={selectedStatus}
              onChange={handleStatusChange}
            >
              <option value="">All</option>
              <option value="pending">Pending</option>
              <option value="confirmed">Confirmed</option>
              <option value="delivered">Delivered</option>
              <option value="completed">Completed</option>
              <option value="paid">Paid</option>
              <option value="cancelled">Cancelled</option>
              <option value="refunded">Refunded</option>
              <option value="disputed">Disputed</option>
            </select>
          </div>
        </div>

        {orderLoading ? (
          <div className="flex justify-center items-center h-64">
            <TailSpin color="skyblue" />
          </div>
        ) : filteredData.length > 0 ? (
          <Table
            columns={columns}
            data={mappedData}
            selectedUserId={null}
            onUserClick={handleUserClick}
          />
        ) : (
          <div className="flex justify-center items-center h-64">
            <p className="text-gray-500 text-lg">No orders available</p>
          </div>
        )}
      </div>

      <div className="md:hidden">
        <div className="mx-auto w-[70%] mt-3">
          <RangePicker
            value={[
              ordersStartDate ? dayjs(ordersStartDate) : null,
              ordersEndDate ? dayjs(ordersEndDate) : null,
            ]}
            onChange={(dates) => handleDateRangeChange(dates)}
            className="bg-transparent h-[44px] rounded-2xl"
          />
        </div>
        <div className="flex justify-between mt-5">
          <p className="text-[14px] font-semibold md:hidden w-[44%] mt-4">
            Recent Orders
          </p>

          <div className="md:hidden flex gap-2">
            <select
              className="border text-[12px] p-3 mt-2 rounded bg-[#0979A1] text-white"
              value={selectedStatus}
              onChange={handleStatusChange}
            >
              <option value="">Filter By</option>
              <option value="pending">Pending</option>
              <option value="confirmed">Confirmed</option>
              <option value="delivered">Delivered</option>
              <option value="completed">Completed</option>
              <option value="paid">Paid</option>
              <option value="cancelled">Cancelled</option>
              <option value="refunded">Refunded</option>
              <option value="disputed">Disputed</option>
            </select>
          </div>
        </div>

        <hr className="bg-[#0979A1] w-[13%] h-[2px] md:hidden" />

        {orderLoading ? (
          <div className="flex justify-center items-center h-64">
            <TailSpin color="skyblue" />
          </div>
        ) : filteredData.length > 0 ? (
          filteredData.map((item: any, index: number) => (
            <div
              key={index}
              className={classNames(
                "flex flex-col gap-2 border border-solid rounded-lg px-3 py-3 mt-5 md:hidden",
                {
                  "border-pending": item.status === "pending",
                  "border-cancelled": item.status === "cancelled",
                  "border-failed": item.status === "failed",
                  "border-successful": item.status === "delivered",
                }
              )}
            >
              <div className="grid grid-cols-2 items-center">
                <div className="flex flex-col">
                  <p className="font-bold text-sm">
                    {capitalizeFirstLetter(item.merchantBusinessName)}
                  </p>
                  <p className="text-[14px]">{formatDate(item.created_at)}</p>
                </div>
                <div className="flex flex-col items-start justify-self-end">
                  <Link to={`/orders/${item.orderId}`} className="text-sm mb-1">
                    <Icon name="dotIcon" />
                  </Link>
                  <span
                    className={`p-2 rounded-md capitalize ${getStatusColor(
                      item.status
                    )} text-xs mt-1`}
                    style={{
                      backgroundColor: getStatusColor(item?.status),
                    }}
                  >
                    {item?.status}
                  </span>
                </div>
              </div>
            </div>
          ))
        ) : (
          <div className="flex justify-center items-center h-64">
            <p className="text-gray-500 text-lg">No orders available</p>
          </div>
        )}
      </div>
    </>
  );
};

export default OrdersTable;
