import React from "react";
import NewIcon from "../assets/images/new-icons";

interface CardProps {
  children: React.ReactNode;
  classNames?: string;
  title?: string;
  subtitle?: string; 
}

export default function Card(props: CardProps) {
  const {
    classNames = "", 
    children,
    title,
    subtitle,
  } = props;
  
  return (
    <div
      className={`w-[448px] max-w-[448px] mx-auto p-8 border border-[#E5E7EB] rounded-2xl bg-gradient-to-b from-[#0E61D9]/5 to-[#ffffff] ${classNames}`}
    >
      <div className="flex items-center justify-center mb-5">
        <NewIcon name="logo" />
      </div>

      {title && (
        <h2 className="font-inter font-bold text-[#1D2939] md:text-2xl text-base md:text-center tracking-[-2%] mb-2">
          {title}
        </h2>
      )}

      {subtitle && (
        <h3 className="font-inter text-[#475467] md:text-[14px] md:text-center tracking-[-1%] mb-5">
          {subtitle}
        </h3>
      )}

      <div>{children}</div>
    </div>
  );
}
