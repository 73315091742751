import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useRequest from "../../../components/hooks/use-request";
import Back from "../../../components/back";
import WalletDetails from "./wallet-details";

const MainDetails = () => {
  const { id } = useParams<{ id: string }>();
  const [data, setData] = useState([]);
  const { makeRequest: getTransactions } = useRequest(
    `/user/wallet-transactions/`,
    "GET"
  );

  const selectedWallet = (data as any[]).find((wallet) => wallet.id === id);

  useEffect(() => {
    const fetchData = async () => {
      const [response] = await getTransactions();
      setData(response?.data?.transactions || []);
    };
    fetchData();
  }, []);

  return (
    <>
    <div className="flex justify-center">
      <h2 className="flex justify-between w-[35%] text-[24px] mt-3 font-bold">
        {" "}
        <Back /> Transaction Details
      </h2>
      </div>
      <WalletDetails wallet={selectedWallet} />
    </>
  );
};

export default MainDetails;
