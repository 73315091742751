import { useState } from "react";
import Button from "../../components/button";
import { Modal } from "antd";
import { Link, useParams } from "react-router-dom";
import ReleaseCode from "./release-code";
import Back from "../../components/back";

const ReleaseFund = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [codeState, setCodeState] = useState("");
  const { orderId } = useParams<{ orderId: string }>();

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <Back />
      <ReleaseCode
        code={codeState}
        setCode={setCodeState}
        orderId={orderId || ""}
      />

      <Modal
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        width={690}
        closable={true}
        footer={null}
        className="flex justify-center justify-items-center px-auto mt-24"
      >
        <h1 className="text-center font-bold text-[#040821] text-lg md:text-[30px]">
          Your request is being processed...
        </h1>
        <p className="text-center text-base md:text-[24px] font-normal mt-4">
          We will send you a notification when it is completed.
        </p>
        <div className="flex justify-center mx-auto w-full mt-6">
          <Button variant="primary" size="full-half">
            <Link to="/">Back to home</Link>
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default ReleaseFund;
