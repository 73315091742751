import { useEffect, useState } from "react";
import useRequest from "../../../components/hooks/use-request";
import Back from "../../../components/back";
import { useNavigate, useParams } from "react-router-dom";

interface OrderItem {
  id: string;
  name: string;
  amount: number;
  currency: string;
  quantity: number;
  description: string;
  created_at: string;
  updated_at: string;
}

const GeneratePayment = () => {
  const [items, setItems] = useState<OrderItem[]>([]);
  const [name, setName] = useState("");
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const orderId = id;
  const { makeRequest } = useRequest(`/orders/${orderId}`, "GET");

  useEffect(() => {
    const fetchData = async () => {
      const [response] = await makeRequest();
      setItems(response.data?.order?.items);
      setName(response.data?.order?.merchantName);
    };

    fetchData();
  }, [orderId]);

  const handleClick = () => {
    navigate("/dashboard");
  };

  return (
    <div>
      <Back />
      <div className="py-3 px-2 relative mt-1 sm:mt-3">
        <h1 className="text-[#040821] text-lg md:text-[30px] font-bold">
          Payment Required!
        </h1>
        <p className="text-[#040821] text-sm md:text-[26px] mt-4 md:mt-6 leading-snug">
          Hello<span className="capitalize">{name ? ` ${name}` : ""}</span>,
          <br /> you have generated this order #
          <span className="text-[#0979A1]">{orderId}</span>
        </p>
        <p className="text-[#040821] text-sm md:text-[26px] font-semibold my-7">
          Here are the details of the order #{orderId}
        </p>

        <div className="">
          {items?.map((item, index) => (
            <div
              key={index}
              className="flex flex-col gap-4 mb-5 border rounded-md p-4"
            >
              <div>
                <div>
                  <label className="text-sm md:text-xl font-normal text-[#040821]">
                    Item
                  </label>
                  <input
                    value={item.name}
                    className="h-[35px] md:h-[55px] text-sm md:text-lg block w-full px-3 md:pl-10 outline-none bg-transparent border-2 rounded-md"
                    type="text"
                    readOnly
                  />
                </div>

                <label className="text-sm md:text-xl font-normal text-[#040821]">
                  Amount
                </label>
                <input
                  value={item.amount}
                  className="h-[35px] md:h-[55px] text-sm md:text-lg block w-full px-3 md:pl-10 outline-none bg-transparent border-2 rounded-md"
                  type="number"
                  readOnly
                />
              </div>

              <div>
                <label className="text-sm md:text-xl font-normal text-[#040821]">
                  Description
                </label>
                <input
                  value={item.description}
                  className="h-[35px] md:h-[55px] text-sm md:text-lg block w-full px-3 md:pl-10 outline-none bg-transparent border-2 rounded-md"
                  readOnly
                />
              </div>

              <div>
                <label className="text-sm md:text-xl font-normal text-[#040821]">
                  Quantity
                </label>
                <input
                  value={item.quantity}
                  className="h-[35px] md:h-[55px] text-sm md:text-lg block w-full px-3 md:pl-10 outline-none bg-transparent border-2 rounded-md"
                  readOnly
                  type="number"
                />
              </div>
            </div>
          ))}
        </div>

        <div className="flex justify-center items-center my-6">
          <button
            type="button"
            className="px-6 md:px-10 py-2 md:py-4 gap-2 w-[75%] md:w-[45%] border-2 border-[#0979A1] rounded-lg font-bold bg-[#0979A1] hover:opacity-75 text-sm md:text-xl text-[#fff]"
            onClick={handleClick}
          >
            Go to Dashboard
          </button>
        </div>
      </div>
    </div>
  );
};

export default GeneratePayment;
