import Back from "../../components/back";
import ResetPassword from "../../components/auth/reset-password";
import useRequest from "../../components/hooks/use-request";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { PadLock } from "../../assets/images/icons";
import Toast, { showToast } from "../../components/toast";
const Reset = () => {
  const { loading, makeRequest } = useRequest("/user/reset-password", "PUT");
  const navigate = useNavigate();

  const { handleSubmit, control, watch } = useForm();

  const handleResetPassword = handleSubmit(async (formData) => {
    const userEmail = {
      resetPasswordToken: formData.resetPasswordToken,
      password: formData.password,
      confirmPassword: formData.confirmPassword,
    };
    const [response] = await makeRequest(userEmail);
    if (response.status) {
      showToast(response.message, true, {
        position: "top-center",
      });
      navigate("/signin");
    } else {
      showToast(response.message, false, {
        position: "top-center",
      });
    }
  });

  return (
    <>
      <ResetPassword
        handleResetPassword={handleResetPassword}
        loading={loading}
        control={control}
        watch={watch}
      />
      <Toast />
    </>
  );
};

export default Reset;
