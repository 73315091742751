import React, { useState, useEffect } from "react";
import Hero from "../components/hero";
import Footer from "../components/footer";
import Card from "../components/card";
import escrowLock from "../assets/images/escrow.png";
import approval from "../assets/images/delivery.png";
import payments from "../assets/images/payment.png";
import withdrawal from "../assets/images/withdrawals.png";
import support from "../assets/images/support.png";
import Calculator from "./pricing/calculator";
import Icon from "../assets/images/icons";
import Basket from "../assets/images/basket.png";
import mobile from "../assets/images/mobile.png";
import shop from "../assets/images/shop.png";
import money from "../assets/images/money.png";
import PromoPopUp from "../components/promo";
import Offering from "../components/offering";
import NewsSection from "../components/news-section";

interface CardItems {
  logo: string;
  alt: string;
  hero: string;
  description: string;
  backgroundColor?: string;
  color?: string;
}

const cardItems: CardItems[] = [
  {
    logo: payments,
    alt: "payments",
    hero: "Why Use VendStash?",
    description:
      "Whether you're an experienced online shopper or a first-time seller, our user-friendly platform empowers you to transact with confidence.",
    backgroundColor: "transparent",
    color: "#000",
  },
  {
    logo: payments,
    alt: "payments-icon",
    hero: "Payment",
    description: "Create and share payment links in less than 3 minutes",
    backgroundColor: "#0979A1",
    color: "#fff",
  },
  {
    logo: approval,
    alt: "approval on delivery",
    hero: "Approval On Delivery",
    description: "Approve funds only on safe and completed deliveries",
    backgroundColor: "#fff",
    color: "0979A1",
  },
  {
    logo: escrowLock,
    alt: "withdrawal-icon",
    hero: "Escrow Lock",
    description: "Secure your money with escrow lock",
    backgroundColor: "#0979A1",
    color: "#fff",
  },
  {
    logo: withdrawal,
    alt: "support-icon",
    hero: "Withdrawal",
    description: "Easy and quick withdrawal for merchants",
    backgroundColor: "#fff",
    color: "0979A1",
  },
  {
    logo: support,
    alt: "support-icon",
    hero: "Support & Mediation",
    description: "Effective Customer Support and Dispute Mediation Center",
    backgroundColor: "#0979A1",
    color: "#fff",
  },
];

const cardItems2: CardItems[] = [
  {
    logo: shop,
    alt: "payments",
    hero: "Online shopping scams are real with scary stories.",
    description:
      "Billions of naira have been lost, with many shoppers falling victim",
    color: "#000",
  },
  {
    logo: mobile,
    alt: "payments-icon",
    hero: "You can avoid falling a victim by using an effective and reliable escrow solution.",
    description:
      "The merchant doesn’t get paid until the goods are safely delivered on time.",
    color: "#000",
  },
  {
    logo: money,
    alt: "approval on delivery",
    hero: "Legitimate sellers have nothing to fear.",
    description:
      "VendStash offers quick withdrawals with zero charges for Merchants.",
    color: "0979A1",
  },
];

const Landing = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    setIsModalVisible(true); // Show the modal when the component mounts
  }, []);

  const handleCloseModal = () => {
    setIsModalVisible(false); // Hide the modal when it's closed
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Hero />

      <Offering />
      <NewsSection />
      <Footer />
    </div>
  );
};

export default Landing;
