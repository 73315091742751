import { useEffect, useRef, useState } from "react";
import image from "../../assets/images/image-icon.svg";
import useUploadFile from "../hooks/use-upload";
import Toast from "../toast";
import { CircleLoader } from "react-spinners";

const Upload = ({ onChange }: any) => {
  const { uploadFiles, loading, progress } = useUploadFile("/upload");
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [isDragging, setIsDragging] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState<string[]>([]);
  const [uploadedFileNames, setUploadedFileNames] = useState<string[]>([]);

  useEffect(() => {
    localStorage.setItem("uploadedFiles", JSON.stringify(uploadedFiles));
  }, [uploadedFiles]);

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      const fileList = Array.from(files);
      const response = await uploadFiles(fileList);

      if (response.success) {
        const fileUrls = response.data || [];
        const fileNames = fileList.map(file => file.name);
        setUploadedFiles((prevFiles) => {
          const updatedFiles = [...prevFiles, ...fileUrls];
          if (onChange) {
            onChange(updatedFiles);
          }
          return updatedFiles;
        });
        setUploadedFileNames((prevNames) => [...prevNames, ...fileNames]);
      }
    } else {
      setUploadedFiles([]);
      setUploadedFileNames([]);
    }
  };

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleDragEnter = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleDrop = async (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragging(false);

    const files = event.dataTransfer.files;
    if (files) {
      const fileList = Array.from(files);
      const response = await uploadFiles(fileList);

      if (response.success) {
        const fileUrls = response.data || [];
        const fileNames = fileList.map(file => file.name);
        setUploadedFiles((prevFiles) => {
          const updatedFiles = [...prevFiles, ...fileUrls];
          if (onChange) {
            onChange(updatedFiles);
          }
          return updatedFiles;
        });
        setUploadedFileNames((prevNames) => [...prevNames, ...fileNames]);
      }
    }
  };

  return (
    <div
      className={`border-dashed border-2 border-gray-600 rounded-lg p-4 my-4 flex justify-center items-center flex-col ${
        isDragging ? "bg-gray-200" : ""
      }`}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDragOver={(event) => event.preventDefault()}
      onDrop={handleDrop}
    >
      <img src={image} alt="Image" />
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: "none" }}
        onChange={handleFileChange}
      />
      <button
        onClick={handleButtonClick}
        className={`file-upload-button font-bold ${
          isDragging ? "bg-gray-300" : ""
        }`}
        type="button"
      >
        <span className="text underline">Click to upload</span> or drag and drop
      </button>
      <p className="text-[#040821]">Maximum file size 100mb</p>
      <div>
        {uploadedFileNames.map((fileName, index) => (
          <p key={index}>{fileName}</p>
        ))}
      </div>
      {loading && (
        <p>
          <CircleLoader size={20} className="text-[#0979A1]" /> {progress}%
        </p>
      )}
      <Toast />
    </div>
  );
};

export default Upload;
