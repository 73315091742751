import React from "react";
import news_one from "../../assets/images/trending-news-one.png";
import news_two from "../../assets/images/trending-news-two.png";
import news_three from "../../assets/images/trending-news-three.png";
import news_four from "../../assets/images/trending-news-four.png";
import news_five from "../../assets/images/trending-news-five.png";
import news_six from "../../assets/images/trending-news-six.png";
import NewIcon from "../../assets/images/new-icons";

const Articles = () => {
  const data = [
    {
      name: news_one,
      title:
        "Best Chromebook Deals: Save on Chromebooks From HP, Asus and More",
      id: 1,
    },
    {
      name: news_two,
      title:
        "Best Chromebook Deals: Save on Chromebooks From HP, Asus and More",
      id: 2,
    },
    {
      name: news_three,
      title:
        "Best Chromebook Deals: Save on Chromebooks From HP, Asus and More",
      id: 3,
    },
    {
      name: news_four,
      title:
        "Best Chromebook Deals: Save on Chromebooks From HP, Asus and More",
      id: 4,
    },
    {
      name: news_five,
      title:
        "Best Chromebook Deals: Save on Chromebooks From HP, Asus and More",
      id: 5,
    },
    {
      name: news_six,
      title:
        "Best Chromebook Deals: Save on Chromebooks From HP, Asus and More",
      id: 6,
    },
  ];
  return (
    <div>
      <div className="bg-white px-6 lg:px-16 py-16 lg:py-32">
        <p className="text-[#191C1E] font-poppins font-medium hidden lg:block lg:text-[45px] lg:leading-[52px] mb-16">Trending News</p>
        <p className="text-[#191C1E] font-poppins font-semibold lg:hidden text-[24px] block mb-8 leading-[32px]">Trending News</p>
        <div>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
            {data.map((e: any) => {
              return (
                <div
                  className="cols-span-1 lg:w-[640px] rounded-2xl lg:rounded-[32px] flex flex-col gap-4 lg:gap-6 bg-[#F0F4F8] lg:py-8 lg:px-[33px] p-4"
                  key={e.id}
                >
                  <div>
                    <img src={e.name} />
                  </div>
                  <div>
                    <p className="font-poppins lg:text-[28px] lg:leading-[36px] font-semibold text-[16px] leading-[20px] text-[#232628]">
                      {e.title}
                    </p>
                  </div>
                  <div>
                    <button className="bg-[#DFE3E7] rounded-lg flex items-center gap-4 py-1 pr-1 lg:gap-6 lg:py-2 lg:pr-2  pl-3 lg:pl-4">
                    <span className="text-[12px] leading-[24px] text-[#444749] font-roboto lg:text-[20px]">Read full story</span>
                      <span className="w-6 h-6 p-1 lg:p-2 lg:w-10 lg:h-10 rounded bg-white block">
                        <NewIcon name={'news-arrow-forward'} width={"full"} height={"full"}/>
                      </span>
                      
                    </button>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div>
      <div className="flex flex-col lg:flex-row">
          <div className="bg-[#FF5449] text-[#540003] py-12 px-6  lg:py-20 lg:px-16 text-center lg:text-left lg:basis-2/5">
            <p className="text-[24px] leading-[32px] lg:text-[32px] lg:leading-[40px] font-semibold mb-5 lg:mb-[56px]">
              Subscribe to our newsletter
            </p>
            <p className="text-sm  lg:text-[28px] lg:leading-[40px]">
              {" "}
              Our experts share the newest products and technologies that get
              you the most of your world.
            </p>
          </div>
          <div className="bg-[#FFB4AB] py-12 px-6 lg:px-16 lg:py-[97.5px]  lg:basis-3/5 ">
            <div className="lg:mb-8 lg:pl-6 lg:py-4 pl-3 py-1.5 bg-[#FFF8F7] rounded-lg lg:rounded-2xl lg:h-[78px]  flex justify-between items-center pr-2 lg:pr-3">
              <input
                placeholder="Enter your email address"
                className="outline-none border-0 bg-inherit w-8/12 placeholder:text-[#5C5F61] placeholder:text-[12px] text-xl font-normal h-full"
              />
              <button className="bg-[#FF5449] py-3 px-4 lg:py-6 lg:px-[42px] rounded-lg lg:rounded-2xl">
                Sign Up
              </button>
            </div>
            <p>
              By proceeding, you agree to our Terms of Use and acknowledge that
              we use personal information as outlined in our Privacy Policy. *
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Articles;
