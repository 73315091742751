import OrdersTable from "./orders-table";

const Orders = ({
  data,
  selectedStatus,
  handleStatusChange,
  currentPage,
  handlePageChange,
  orderLoading,
  handleDateRangeChange,
  ordersStartDate,
  ordersEndDate,
}: any) => {
  return (
    <>
      <OrdersTable
        data={data}
        selectedStatus={selectedStatus}
        handleStatusChange={handleStatusChange}
        currentPage={currentPage}
        onPageChange={handlePageChange}
        orderLoading={orderLoading}
        handleDateRangeChange={handleDateRangeChange}
        ordersStartDate={ordersStartDate}
        ordersEndDate={ordersEndDate}
      />
    </>
  );
};

export default Orders;
