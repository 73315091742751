import { useEffect, useState } from "react";
import Input from "../../components/input";
import useRequest from "../../components/hooks/use-request";
import { Controller, useForm } from "react-hook-form";
import Button from "../../components/button";
import Back from "../../components/back";
import { showToast } from "../../components/toast";
import { useNavigate, useParams } from "react-router-dom";

interface OrderItem {
  id: string;
  name: string;
  amount: number;
  currency: string;
  quantity: number;
  description: string;
  created_at: string;
  updated_at: string;
}

interface MEmail {
  merchantEmail: string;
}

const ConfirmOrder = () => {
  const navigate = useNavigate();
  const userToken = localStorage.getItem("token");
  const { id } = useParams();
  const [items, setItems] = useState<OrderItem[]>([]);
  const [email, setEmail] = useState<MEmail[]>([]);
  const { makeRequest } = useRequest(`/orders/${id}`, "GET");
  const { makeRequest: getConfirmed } = useRequest(
    `/orders/${id}/confirm`,
    "PUT"
  );
  const { makeRequest: getDeleted } = useRequest(
    `/orders/${id}/cancel`,
    "DELETE"
  );

  useEffect(() => {
    const fetchData = async () => {
      const [response] = await makeRequest();
      setItems(response.data?.order?.items);
      setEmail(response.data?.order?.merchantEmail);
    };

    fetchData();
  }, [id]);

  const { handleSubmit, control, reset } = useForm();

  const handleConfirmSubmit = handleSubmit(async () => {
    const queryParams = { email: email };
    const [response] = await getConfirmed(undefined, queryParams);

    if (response.status) {
      showToast(response.message, true, {
        position: "top-center",
      });
      reset();
      if (email[0]?.merchantEmail === userToken) {
        navigate("/dashboard");
      } else {
        navigate(`/merchant/login/${id}`);
      }
    } else {
      showToast(response.message, false, {
        position: "top-center",
      });
    }
  });

  const handleDeleteSubmit = handleSubmit(async () => {
    const queryParams = { email: email };
    const [response] = await getDeleted(undefined, queryParams);
    if (response.status) {
      showToast(response.message, true, {
        position: "top-center",
      });
      reset();
      navigate("/");
    } else {
      showToast(response.message, false, {
        position: "top-center",
      });
    }
  });

  return (
    <div className="mb-6">
      <Back />
      <div className="items-center justify-between px-1 pt-6 md:w-[65%] mx-auto">
        <h1 className="font-semibold text-[#040821] text-center text-base md:text-2xl">
          Confirm the details of the order #
          <span className="text-[#0979A1]">{id}</span>
        </h1>

        <div className="mt-4 md:mt-6">
          {items &&
            items?.map((item, index) => (
              <div key={index} className="mb-5 border rounded-md p-4">
                <h1 className="font font-bold">ITEM {index + 1}</h1>
                <div className="flex flex-col md:flex-row gap-5 flex-wrap justify-center">
                  <div className="flex-[40%]">
                    <Input
                      value={item.name}
                      label="Name"
                      className="w-full bg-transparent"
                      readOnly
                    />
                  </div>
                  <div className="flex-[40%]">
                    <Input
                      value={item.amount}
                      label="Amount"
                      className="w-full"
                      readOnly
                    />
                  </div>

                  <div className="flex-[40%]">
                    <Input
                      value={item.quantity}
                      label="Quantity"
                      className="w-full bg-transparent"
                      readOnly
                    />
                  </div>
                  <div className="flex-[40%]">
                    <Input
                      value={item.description}
                      label="Item description"
                      className="w-full bg-transparent"
                      readOnly
                    />
                  </div>
                </div>
              </div>
            ))}
        </div>

        <Controller
          name="email"
          control={control}
          defaultValue=""
          rules={{
            required: "Email is required",
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: "Enter a valid email. E.g, example@gmail.com",
            },
          }}
          render={({ field, fieldState }) => (
            <Input
              value={field.value}
              label="Email Address"
              className="w-1/2"
              error={fieldState?.error?.message}
              onChange={field.onChange}
            />
          )}
        />

        <div className="flex gap-20 items-center justify-center mt-14">
          <Button
            size="full-half"
            variant="secondary"
            onClick={handleDeleteSubmit}
          >
            Cancel
          </Button>
          <Button
            size="full-half"
            variant="primary"
            onClick={handleConfirmSubmit}
          >
            Confirm
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmOrder;
