import { useEffect, useState } from "react";
import useRequest from "../hooks/use-request";
import Icon from "../../assets/images/icons";
import { useNavigate } from "react-router-dom";
import Avatar from "../../components/avatar";
import { capitalizeFirstLetter, signOut } from "../../utils/functions";
import { Modal } from "antd";
import Signout from "../signout";

interface User {
  fullname: string;
  userType: string;
  profilePhoto: string;
}

const Sidebar = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState<User>();
  const [isSignoutModalVisible, setIsSignoutModalVisible] = useState(false);
  const { makeRequest: getUser } = useRequest("/user/profile", "GET");

  useEffect(() => {
    const fetchData = async () => {
      const [response] = await getUser();
      setUser(response?.data);
    };
    fetchData();
  }, []);

  const handleClick = () => {
    navigate("/dashboard/profile");
  };

  const handleSignOut = () => {
    signOut();
    toggleSignoutModal();
    navigate("/");
  };

  const toggleSignoutModal = () => {
    setIsSignoutModalVisible(!isSignoutModalVisible);
  };

  useEffect(() => {
    const timeout = 10 * 60 * 4000;
    const timer = setTimeout(() => {
      signOut();
      navigate("/");
    }, timeout);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <div className="md:border-r-2 w-full md:w-[25%] pt-8 bg-white">
      <section className="flex md:block gap-4">
        <div className="flex md:block gap-5 w-1/2 md:w-full md:h-[200px] border-b-2">
          <div className="flex justify-center">
            {user?.profilePhoto ? (
              <img
                src={user?.profilePhoto}
                alt="profilePhoto"
                className="rounded-full border w-[60px] h-[60px] md:w-[100px] md:h-[102px]"
              />
            ) : (
              <Avatar size="large" />
            )}
          </div>

          <div className="md:block">
            <p className="text-[14px]  font-semibold text-center md:mt-2">
              {capitalizeFirstLetter(user?.fullname ?? "")}
            </p>
            <div
              className="flex justify-center cursor-pointer w-[88px] h-[24px] md:w-[152px]  mx-auto text-[8px] md:text-[12px] mt-3 border border-[#6A6A6A] md:h-[32px] gap-3 rounded-md"
              onClick={handleClick}
            >
              <span className="md:pt-2 pt-1">
                <Icon name="edit" />
              </span>
              <button className="f font-bold">Edit Profile</button>
            </div>
          </div>
        </div>

        <div className="flex flex-col gap-5 text-[14px] w-1/2 md:w-full md:mt-6 md:px-3 border-b-2 md:h-[300px] ">
          {/* TODO: makae this a component */}
          <a
            href="/create-order"
            className="flex items-center justify-center w-full md:mt-3 bg-[#0979A1] h-[28px] px-3 text-[10px] md:text-[16px]  border md:h-[50px] text-[#fff] font-bold gap-3 rounded-md"
          >
            Create Order
          </a>

          {/* TODO: makae this a component */}
          <a
            href="/user/withdraw"
            className="flex items-center justify-center w-full bg-[#0979A1] pt-1 border md:h-[50px] h-[28px] text-[10px] md:text-[16px] text-[#fff] font-bold gap-3 rounded-md"
          >
            Withdraw
          </a>
        </div>

        <div className="md:flex md:gap-1 md:mt-10 md:px-4 hidden">
          {/* TODO: makae this a component */}
          <button
            type="button"
            onClick={toggleSignoutModal}
            className="w-full bg-[#0979A1] pt-1 border md:h-[50px] h-[28px] text-[10px] md:text-[16px] text-[#fff] font-bold gap-3 rounded-md"
          >
            Logout
          </button>
        </div>
      </section>

      <Modal
        width={530}
        open={isSignoutModalVisible}
        onCancel={toggleSignoutModal}
        footer={null}
      >
        <Signout onYes={handleSignOut} onNo={toggleSignoutModal} />
      </Modal>
    </div>
  );
};

export default Sidebar;
