import Back from "../../components/back";
import { Controller, useForm } from "react-hook-form";
import Input from "../../components/input";
import Button from "../../components/button";

const ReportOrder = () => {
  const orderId = localStorage.getItem("orderId") || "";
  const { control } = useForm();

  return (
    <>
      <Back />
      <div className="py-2 md:px-2 mt-4 max-w-screen-xl mx-auto flex flex-col">
        <p className="text-center font-bold text-base md:text-2xl">
          Request form for order #
          <span className="text-[#0979A1]">{orderId}</span>
        </p>
        <p className="text-center font-semibold text-sm md:text-[24px] my-5">
          Please fill in the correct details
        </p>

        <form>
          <div>
            <Controller
              name="amount"
              control={control}
              defaultValue={""}
              rules={{
                required: "Amount is required",
                pattern: {
                  value: /^[0-9]+$/,
                  message: "Enter a valid number",
                },
              }}
              render={({ field, fieldState }) => (
                <Input
                  value={field.value}
                  label="Amount"
                  type="number"
                  className="w-full"
                  error={fieldState?.error?.message}
                  onChange={(e) => {
                    const numericValue = e.target.value.replace(/\D/g, "");
                    field.onChange(
                      numericValue === "" ? "" : parseInt(numericValue, 10)
                    );
                  }}
                />
              )}
            />
          </div>

          <div className="mt-5">
            <Controller
              name="description"
              control={control}
              defaultValue=""
              rules={{
                required: "Description is required",
                minLength: {
                  value: 3,
                  message: "Name must be at least 3 characters",
                },
              }}
              render={({ field, fieldState }) => (
                <Input
                  value={field.value}
                  label="Description"
                  className="w-full"
                  error={fieldState?.error?.message}
                  onChange={field.onChange}
                />
              )}
            />
          </div>

          <div className="mt-5">
            <Controller
              name="quantity"
              control={control}
              defaultValue={""}
              rules={{
                required: "Amount is required",
                pattern: {
                  value: /^[0-9]+$/,
                  message: "Enter a valid number",
                },
              }}
              render={({ field, fieldState }) => (
                <Input
                  value={field.value}
                  label="Quantity"
                  type="number"
                  className="w-full"
                  error={fieldState?.error?.message}
                  onChange={(e) => {
                    const numericValue = e.target.value.replace(/\D/g, "");
                    field.onChange(
                      numericValue === "" ? "" : parseInt(numericValue, 10)
                    );
                  }}
                />
              )}
            />
          </div>

          <div className="flex gap-20 justify-center mt-8">
            <Button size="full-half" variant="primary">
              Request top-up
            </Button>
          </div>
        </form>
      </div>
    </>
  );
};

export default ReportOrder;
