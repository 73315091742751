import { useEffect, useState } from "react";
import useRequest from "../../components/hooks/use-request";
import { capitalizeFirstLetter } from "../../utils/functions";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Back from "../../components/back";
import Icon from "../../assets/images/icons";
import { showToast } from "../../components/toast";
import { TailSpin } from "react-loader-spinner";

interface OrderItem {
  id: string;
  name: string;
  amount: number;
  currency: string;
  quantity: number;
  description: string;
  created_at: string;
  updated_at: string;
}

const AuthorizePayment = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [items, setItems] = useState<OrderItem[]>([]);
  const [name, setName] = useState("");
  const [paymentStatus, setPaymentStatus] = useState("");
  const [link, setLink] = useState("");
  const [hasAttemptedPayment, setHasAttemptedPayment] = useState(false);
  const { id } = useParams<{ id: string }>();
  const orderId = id;
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const createId = queryParams.get("transaction_id");
  const redirect_status = queryParams.get("status");
  const { makeRequest: getPayment } = useRequest(
    `/payments/${createId}`,
    "GET"
  );
  const { makeRequest } = useRequest(`/orders/${orderId}`, "GET");
  const myName = capitalizeFirstLetter(name);
  const { makeRequest: getPaymentLink } = useRequest(
    `/orders/${orderId}/initiate-payment`,
    "GET"
  );

  useEffect(() => {
    if (!createId) {
      return;
    } else {
      setIsLoading(true);
      setHasAttemptedPayment(true);
    }

    const fetchData = async () => {
      try {
        const [response] = await getPayment();
        setPaymentStatus(response.data?.status);
      } catch (error) {
      } finally {
      }
    };

    const interval = setInterval(() => {
      if (paymentStatus !== "paid") {
        fetchData();
      }
    }, 5000);

    const timeout = setTimeout(() => {
      clearInterval(interval);
      setIsLoading(false);
    }, 10000);

    if (paymentStatus === "paid") {
      setIsLoading(false);
      clearInterval(interval);
      clearTimeout(timeout);
      showToast("Payment successfully!", true, {
        position: "top-center",
      });
      navigate(`/confirm-payment/${orderId}`);
    }

    return () => {
      clearInterval(interval);
      clearTimeout(timeout);
    };
  }, [paymentStatus, createId, navigate, orderId]);

  useEffect(() => {
    const fetchData = async () => {
      const [response] = await makeRequest();
      setItems(response.data?.order?.items);
      setName(response.data?.order?.buyerName);
      setPaymentStatus(response.data?.order?.status);
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (link) return;
    const fetchData = async () => {
      const queryParams = { userType: "buyer" };
      const [response] = await getPaymentLink(undefined, queryParams);
      setLink(response.data?.link);
    };

    const interval = setInterval(() => {
      if (!link) {
        fetchData();
      }
    }, 10000);

    const timeout = setTimeout(() => {
      clearInterval(interval);
      setIsLoading(false);
    }, 30000);

    return () => {
      clearInterval(interval);
      clearTimeout(timeout);
    };
  }, [link]);

  const handleCopyLink = () => {
    navigator.clipboard.writeText(link);
    showToast("Link copied successfully!", true, {
      position: "top-center",
    });
  };

  const handleClick = () => {
    if (link) {
      setHasAttemptedPayment(true); 
      window.location.href = link;
    } else {
      showToast("Unable to generate payment. Please try again!", false, {
        position: "top-center",
      });
    }
  };

  // DO NOT REMOVE

  // const handleRadioClick = (value: any) => {
  //   if (selectedRadio === value) {
  //     setSelectedRadio(null);
  //   } else {
  //     setSelectedRadio(value);
  //   }
  // };

  return (
    <div>
      <Back />
      <div>
        {isLoading ? (
          <div className="w-[20%] mx-auto mt-40 ">
            <TailSpin color="skyblue" />
          </div>
        ) : (
          <div className="py-3 px-2 relative mt-1 sm:mt-3">
            <h1 className="text-[#040821] text-lg md:text-[30px] font-bold">
              Payment required!
            </h1>
            <p className="text-[#040821] text-sm md:text-[26px] mt-4 md:mt-6 leading-snug">
              Hello {myName}, <br /> you have generated this payment link to pay
              for order #<span className="text-[#0979A1]">{orderId}</span>
            </p>
            <div>
              {hasAttemptedPayment && paymentStatus !== "paid" && (
                <div className="mx p-2 font-semibold sm:w-[78%] my-6 rounded-lg bg-orange-300 mx-auto">
                  <div className="g-[#F8F8F8] sm:w-[85%] px-5 py-2">
                    We are verifying your payment status and will notify you once
                    we receive it.
                  </div>
                </div>
              )}
              <div className="bg-[#F8F8F8] sm:w-[78%] mx-auto mt-4 flex justify-around rounded-md border border-solid">
                <input
                  value={link ? link : "Loading..."}
                  className="bg-[#F8F8F8] sm:w-[85%] h-[50px] md:h-[70px] outline-none"
                  readOnly
                />
                <button type="button" onClick={handleCopyLink}>
                  <Icon name="copy" />
                </button>
              </div>
            </div>
            <p className="text-[#040821] text-center text-sm md:text-[26px] font-semibold my-7">
              Here are the details of the order #{orderId}
            </p>

            <div className="w-full">
              {items.map((item, index) => (
                <div
                  key={index}
                  className="md:grid grid-cols-2 w-full md:w-[60%] mx-auto  justify-center gap-4 mb-5 rounded-md"
                >
                  <div>
                    <label className="text-sm md:text-xl font-normal text-[#040821]">
                      Item
                    </label>
                    <input
                      value={item.name}
                      className="h-[35px] md:h-[55px] text-sm md:text-lg block w-full px-3 md:pl-10 outline-none bg-transparent border-2 rounded-md"
                      type="text"
                      readOnly
                    />
                  </div>

                  <div>
                    <label className="text-sm md:text-xl font-normal text-[#040821]">
                      Amount
                    </label>
                    <input
                      value={item.amount}
                      className="h-[35px] md:h-[55px] text-sm md:text-lg block w-full  px-3 md:pl-10 outline-none bg-transparent border-2 rounded-md"
                      type="number"
                      readOnly
                    />
                  </div>

                  <div>
                    <label className="text-sm md:text-xl font-normal text-[#040821]">
                      Goods Description
                    </label>
                    <input
                      value={item.description}
                      className="h-[35px] md:h-[55px] text-sm md:text-lg block w-full  px-3 md:pl-10 outline-none bg-transparent border-2 rounded-md"
                      readOnly
                    />
                  </div>

                  <div>
                    <label className="text-sm md:text-xl font-normal text-[#040821]">
                      Quantity
                    </label>
                    <input
                      value={item.quantity}
                      className="h-[35px] md:h-[55px] text-sm md:text-lg block w-full  px-3 md:pl-10 outline-none bg-transparent border-2 rounded-md"
                      readOnly
                      type="number"
                    />
                  </div>
                </div>
              ))}
            </div>

            <div className="flex justify-center items-center my-6">
              <button
                type="button"
                className="px-6 md:px-10 py-2 md:py-4 gap-2 w-[75%] md:w-[45%] border-2 border-[#0979A1] rounded-lg font-bold bg-[#0979A1] hover:opacity-75 text-sm md:text-xl text-[#fff]"
                onClick={handleClick}
              >
                Authorise payment
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AuthorizePayment;
