import Back from "../../components/back";
import Avatar from "../../components/avatar";

// THIS PAGE IS UNDER REVIEW FOR NOW!!

const Reviews = () => {
  return (
    <div>
      <div>
        <div className="flex jstify-start">
          <div className="inline-block">
            <Back />
          </div>
          <div className="mt-5 ml-6 md:ml-12 md:mt-6 md:hidden">
            <p className="text-lg font-bold">
              Reviews <span className="text-[#0979A1]">(15)</span>
            </p>
          </div>
        </div>
        <div className="mt-8 p-2 hidden md:block">
          <p className="text-lg font-bold">
            Reviews <span className="text-[#0979A1]">(15)</span>
          </p>
        </div>
      </div>

      <div className="flex flex-col gap-5 py-4 w-[100%] border border-red-200">
        <div className="flex flex-col gap-3 border border-blue-800">
          <div className="rounded-full ring-1 ring-offset-4 ring-[#A7A7A7]">
            <Avatar />
            <div className="flex flex-col gap-1">
              <h4>Oge Obubu</h4>
              <p className="text-sm text-gray-400">Nov 30, 2023</p>
            </div>
          </div>

          <div
            className="w-780 h-150 flex-shrink-0"
            style={{
              fill: "#FFF",
              filter: "drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.10))",
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="784"
              height="137"
              viewBox="0 0 784 137"
              fill="none"
            >
              <g filter="url(#filter0_d_1361_8428)">
                <path
                  d="M0 20C0 8.9543 8.95431 0 20 0H760C771.046 0 780 8.9543 780 20V96.0959C780 107.142 771.046 116.096 760 116.096H56.0793C52.0729 116.096 48.1587 117.299 44.8441 119.55L31.2351 128.791C17.956 137.807 0 128.296 0 112.245V20Z"
                  fill="white"
                />
              </g>
              <defs>
                <filter
                  id="filter0_d_1361_8428"
                  x="0"
                  y="0"
                  width="784"
                  height="136.279"
                  filterUnits="userSpaceOnUse"
                  colorInterpolationFilters="sRGB"
                >
                  <feFlood floodOpacity="0" result="BackgroundImageFix" />
                  <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                  />
                  <feOffset dx="2" dy="2" />
                  <feGaussianBlur stdDeviation="1" />
                  <feComposite in2="hardAlpha" operator="out" />
                  <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
                  />
                  <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_1361_8428"
                  />
                  <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow_1361_8428"
                    result="shape"
                  />
                </filter>
              </defs>
            </svg>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Reviews;
