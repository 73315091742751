import Hero from "../../components/about/hero";
import Use from "../../components/about/use";
import Vision from "../../components/about/vision";
import Team from "../../components/about/team";
import Contact from "../../components/about/contact";
import Back from "../../components/back";

const About = () => {
  return (
    <>
    <Back />
      <Hero />
      <Use />
      <Vision />
      <Team />
      <Contact />
    </>
  );
};

export default About;
