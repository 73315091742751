import React, { useEffect, useState } from "react";
import Lock from "../../assets/images/Ellipse 1.svg";
import useRequest from "../../components/hooks/use-request";
import Icon from "../../assets/images/icons";
import { useNavigate, useParams } from "react-router-dom";
import { showToast } from "../../components/toast";
import Back from "../../components/back";

const app_url = `${window.location.origin}`;

const ConfirmBuyerPayment = (props: any) => {
  const { orderId } = props;
  const [link, setLink] = useState("");
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const { makeRequest } = useRequest(`/orders/${id}/initiate-payment`, "GET");

  useEffect(() => {
    const fetchData = async () => {
      const [response] = await makeRequest();
      setLink(response.data?.link);
    };

    if (orderId) fetchData();
    setLink(`${app_url}/confirm-order/${id}`);
  }, [orderId, id]);

  const handleCopyLink = () => {
    navigator.clipboard.writeText(link);
    showToast("Link copied successfully!", true, {
      position: "top-center",
    });
  };

  const handleClick = () => {
    navigate("/");
  };

  return (
    <div>
      <Back />
      <h2 className="text-[#040821] text-center font-bold text-base md:text-[28px] mt-4">
        Payment Confirmed!
      </h2>

      <div className="flex justify-center items-center mt-5 "></div>

      <div className="flex m-22 my-4 justify-center items-center gap-3">
        <img src={Lock} alt="lock" className="w-12 h-12 md:w-24 md:h-24" />
        <h2 className="text-[#040821] font-semibold text-base md:text-[28px]">
          Order Link Generated!
          <span className="text-[#040821] block md:my-2 font-normal text-xs md:text-[20px]">
            Share this link with the merchant
          </span>
        </h2>
      </div>

      <div className=" bg-[#F8F8F8] sm:w-[70%] w-[80%] mx-auto mt-4 flex justify-around rounded-md border border-solid">
        <input
          value={link ? link : "Loading..."}
          className="bg-[#F8F8F8] sm:w-[85%] h-[50px] md:h-[70px] outline-none"
          readOnly
        />
        <button type="button" onClick={handleCopyLink}>
          <Icon name="copy" />
        </button>
      </div>

      <div className="flex justify-center items-center my-5 ">
        <button
          type="button"
          className="px-6 md:px-10 py-2 md:py-4 gap-2 w-[75%] md:w-[45%] border-2 border-[#0979A1] rounded-full font-bold bg-[#0979A1] hover:opacity-75 text-sm md:text-xl text-[#fff]"
          onClick={handleCopyLink}
        >
          Copy Link
        </button>
      </div>
      <div className="flex justify-center items-center my-5 ">
        <button
          type="button"
          className="px-6 md:px-10 py-2 md:py-4 gap-2 w-[75%] md:w-[45%] border-2 border-[#0979A1] rounded-full font-bold hover:opacity-75 text-sm md:text-xl text-[#0979A1]"
          onClick={handleClick}
        >
          Go Home
        </button>
      </div>
    </div>
  );
};

export default ConfirmBuyerPayment;
