import { Controller, useForm, useWatch } from "react-hook-form";
import Input from "../input";
import { useContext, useEffect } from "react";
import { I_Item } from "../../pages/create-order/types";
import Icon from "../../assets/images/icons";
import Textarea from "../textarea";
import { ItemsContext } from "../../pages/create-order/context";

export function Item({
  index,
  amountValidationMessage,
}: {
  index: number;
  amountValidationMessage: string;
}) {
  const { updateItem, removeItem, transactionFees } = useContext(ItemsContext);
  const { control, getValues } = useForm();
  const amount = useWatch({ name: "amount", control });
  const quantity = useWatch({ name: "quantity", control });
  const name = useWatch({ name: "name", control });
  const description = useWatch({ name: "description", control });

  const handleUpdateItem = () => {
    const data = getValues() as I_Item;
    updateItem(index, data);
  };

  useEffect(() => {
    handleUpdateItem();
  }, [amount, quantity, name, description]);

  return (
    <>
      <div className="flex flex-col gap-6">
        {index > 0 && (
          <div className="flex justify-end">
            <button
              onClick={() => removeItem(index)}
              type="button"
              className="hover:scale-110"
            >
              <Icon name="delete" className="w-[36px]" />
            </button>
          </div>
        )}

        <div className="flex flex-col md:flex-row justify-between gap-6 items-center">
          <Controller
            name="amount"
            control={control}
            defaultValue={""}
            rules={{
              required: "Amount is required",
              pattern: {
                value: /^[0-9]+$/,
                message: "Enter a valid number",
              },
              validate: {
                minimumAmount: (value) => {
                  const numericValue = parseInt(value, 10);
                  if (isNaN(numericValue) || numericValue < 200) {
                    return "Minimum amount is 200";
                  }
                  return true;
                },
              },
            }}
            render={({ field, fieldState }) => {
              return (
                <Input
                  value={field.value}
                  label="Amount"
                  type="number"
                  className="w-full"
                  error={
                    fieldState?.error?.type === "minimumAmount"
                      ? fieldState.error.message
                      : ""
                  }
                  onChange={(e) => {
                    const numericValue = e.target.value.replace(/\D/g, "");
                    field.onChange(
                      numericValue === "" ? "" : parseInt(numericValue, 10)
                    );
                  }}
                />
              );
            }}
          />

          <Controller
            name="quantity"
            control={control}
            defaultValue={1}
            rules={{
              required: "Quantity is required",
              pattern: {
                value: /^[0-9]+$/,
                message: "Enter a valid number",
              },
            }}
            render={({ field, fieldState }) => (
              <Input
                value={field.value}
                label="Quantity"
                type="number"
                className="w-full"
                error={fieldState?.error?.message}
                onChange={(e) => {
                  const numericValue = e.target.value.replace(/\D/g, "");
                  field.onChange(
                    numericValue === "" ? "" : parseInt(numericValue, 10)
                  );
                }}
              />
            )}
          />
        </div>

        {transactionFees.amount > 0 && (
          <p className="text-red-500 text-[10px]">
            {amountValidationMessage}
          </p>
        )}


        <div className="flex flex-col md:flex-row justify-between gap-6 items-center">
          <Controller
            name="name"
            control={control}
            defaultValue=""
            rules={{
              required: "Item name is required",
              minLength: {
                value: 3,
                message: "Name must be at least 3 characters",
              },
            }}
            render={({ field, fieldState }) => (
              <Textarea
                value={field.value}
                label="Item"
                className="w-full"
                error={fieldState?.error?.message}
                onChange={field.onChange}
              />
            )}
          />

          <Controller
            name="description"
            control={control}
            defaultValue=""
            rules={{
              required: "Description is required",
              minLength: {
                value: 3,
                message: "Name must be at least 3 characters",
              },
            }}
            render={({ field, fieldState }) => (
              <Textarea
                value={field.value}
                label="Description"
                className="w-full"
                error={fieldState?.error?.message}
                onChange={field.onChange}
              />
            )}
          />
        </div>
      </div>
    </>
  );
}
