import * as cryptoJS from "crypto-js";

export function encryptData(data: string, encryptionKey: string) {
  return cryptoJS.AES.encrypt(data, encryptionKey).toString();
}

export function decryptData(data: string, encryptionKey: string) {
  const bytes = cryptoJS.AES.decrypt(data, encryptionKey);
  return bytes.toString(cryptoJS.enc.Utf8);
}
