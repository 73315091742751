import React, { useEffect, useState } from "react";
import Button from "../../components/button";
import FileUpload from "../../components/upload";
import { Controller, useForm } from "react-hook-form";
import { showToast } from "../../components/toast";
import useRequest from "../../components/hooks/use-request";
import { CircleLoader } from "react-spinners";
import Input from "../../components/input";
import { useNavigate } from "react-router-dom";

interface Item {
  id: string;
  name: string;
  amount: number;
  currency: string;
  quantity: number;
  description: string;
  created_at: string;
  updated_at: string;
}

interface Order {
  id: string;
  merchantBusinessName: string;
  status: string;
  created_at: string;
  orderId: string;
  buyerName: string;
  buyerPhoneNumber: string;
  buyerEmail: string;
  buyerSocialMediaHandle: string;
  buyerSocialMediaPlatform: string;
  merchantEmail: string;
  items: Item[];
}

interface OrderDetailsProps {
  order: Order;
}

const UploadProof: React.FC<OrderDetailsProps> = ({ order }) => {
  const navigate = useNavigate();
  const [isOrderSelected, setIsOrderSelected] = useState(false);
  const userToken = localStorage.getItem("token");
  const [files, setFiles] = useState<string[]>([]);
  const [fileError, setFileError] = useState<string | null>(null);

  const { loading, makeRequest } = useRequest(
    `/orders/${order?.orderId}/create-proof`,
    "POST",
    {
      Authorization: `Bearer ${userToken}`,
    }
  );

  const {
    handleSubmit,
    control,
    reset,
    setValue,
    formState: { errors },
  } = useForm();

  const handleFormSubmit = handleSubmit(async (formdata) => {
    if (files.length === 0) {
      setFileError("Please upload at least one picture as proof");
      return;
    }
    setFileError(null);

    const proof = files.flat();
    const userData = {
      email: formdata.email,
      proof: proof.flat(),
    };
    const [response] = await makeRequest(userData);
    if (response.status) {
      showToast(response.message, true, {
        position: "top-center",
      });
      reset();
      navigate(-1);
    } else {
      showToast("Payment has not been made for this order", false, {
        position: "top-center",
      });
    }
  });

  useEffect(() => {
    if (order?.merchantEmail) {
      setValue("email", order.merchantEmail);
    }
  }, [order?.merchantEmail, setValue]);

  useEffect(() => {
    if (files.length > 0) {
      setFileError(null);
    }
  }, [files]);

  return (
    <>
      <form onSubmit={handleFormSubmit}>
        <div className="p-4 flex justify-center items-center flex-col gap-5">
          {isOrderSelected && (
            <div>
              <Controller
                name="email"
                control={control}
                defaultValue={order?.merchantEmail}
                rules={{
                  required: "Email is required",
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "Enter a valid email. E.g, example@gmail.com",
                  },
                }}
                render={({ field, fieldState }) => (
                  <Input
                    value={field.value}
                    label="Email Address"
                    className="w-full sm:w-[50%]"
                    error={fieldState?.error?.message}
                    onChange={field.onChange}
                  />
                )}
              />
            </div>
          )}

          <div className="my-4 w-[90%] md:w-[50%]">
            <FileUpload files={files} setFiles={setFiles} />
            {fileError && <p className="text-red-500 text-[14px] mt-2">{fileError}</p>}
          </div>

          <div className="flex justify-center items-center w-[90%] md:w-[500px]">
            <Button size="full-half" variant="primary" type="submit">
              {loading ? (
                <CircleLoader color="#ffffff" loading={loading} size={20} />
              ) : (
                "Submit"
              )}
            </Button>
          </div>
        </div>
      </form>
    </>
  );
};

export default UploadProof;
