import { useEffect } from "react";
import Back from "../../components/back";
import Login from "../../components/auth/signin";
import useRequest from "../../components/hooks/use-request";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import Toast, { showToast } from "../../components/toast";
import { encryptData } from "../../utils/encryption";

const Signin = () => {
  const { loading, makeRequest } = useRequest("/auth/login", "POST");
  const navigate = useNavigate();
  const { handleSubmit, control, setValue } = useForm();

  useEffect(() => {
    const user = localStorage.getItem("user");
    if (user) {
      const userInfo = JSON.parse(user);
      setValue("email", userInfo.email);
      setValue("password", userInfo.password);
    }
  }, [setValue]);

  const handleLogin = handleSubmit(async (formData) => {
    const user = {
      email: formData.email,
      password: formData.password,
    };

    const [response] = await makeRequest(user);

    if (response.status) {
      localStorage.setItem("user", JSON.stringify(response?.data?.user));
      localStorage.setItem(
        "verifyEmail",
        JSON.stringify(response?.data?.user?.emailVerified)
      );
      localStorage.setItem("profilePhoto", response.data?.user?.profilePhoto);
      localStorage.setItem("verifyToken", response.data.token);
      if (response?.data?.user?.emailVerified) {
        localStorage.setItem("token", response.data.token);
        if (formData.rememberMe) {
          localStorage.setItem(
            "rememberMe",
            JSON.stringify({
              email: user.email,
              password: encryptData(user.password, user.email),
            })
          );
        } else {
          localStorage.removeItem("rememberMe");
        }

        showToast(response.message, true, {
          position: "top-center",
        });

        navigate("/dashboard");
      } else {
        showToast("Please verify your email to proceed", false, {
          position: "top-center",
        });
        navigate("/signup/verify");
      }
    } else {
      showToast(response.message, false, {
        position: "top-center",
      });
    }
  });

  return (
    <>

      <div className="my-8 flex flex-col">
        <Login handleLogin={handleLogin} control={control} loading={loading} />
      </div>
      <Toast />
    </>
  );
};

export default Signin;
