import { useParams, useSearchParams } from "react-router-dom";
import {
  capitalizeFirstLetter,
  formatCurrency,
} from "../../../utils/functions";
import Button from "../../../components/button";
import { I_Order, I_User } from "./types";
import { ReactNode, useEffect, useState } from "react";
import { SectionTitle } from "../../../components/text";
import classNames from "classnames";
import useRequest from "../../../components/hooks/use-request";
import { showToast } from "../../../components/toast";
import { CircleLoader } from "react-spinners";

export enum OrderStatusEnum {
  PENDING = "pending",
  PAID = "paid",
  CONFIRMED = "confirmed",
  DELIVERED = "delivered",
  COMPLETED = "completed",
  DISPUTED = "disputed",
  CANCELLED = "cancelled",
  REFUNDED = "refunded",
}

export default function OrderDetails({ order }: { order: I_Order }) {
  const { orderId } = useParams<{ orderId: string }>();
  const userJSON = localStorage.getItem("user");
  const user: I_User | null = userJSON ? JSON.parse(userJSON) : null;
  const isLoggedIn = userJSON !== null;
  const isOwner = !isLoggedIn
    ? false
    : [order.merchantEmail, order.buyerEmail].includes(user?.email || "");
  const isBuyer = order.buyerEmail === user?.email;
  const [searchParams] = useSearchParams();
  const reference = searchParams.get("reference");
  const trxref = searchParams.get("trxref");
  const [status, setStatus] = useState<string>();
  let notified = false;

  const subtotal = order?.items.reduce(
    (sum: number, item: any) => sum + item.amount * item.quantity,
    0
  );
  const { makeRequest: getPaymentLink, loading } = useRequest(
    `/orders/${order.orderId}/initiate-payment`,
    "GET"
  );

  useEffect(() => {
    if (!status) setStatus(order.status);

    if ((reference || trxref || orderId?.includes("_")) && !notified) {
      if (order.status === OrderStatusEnum.PAID && order.status != status) {
        showToast("Payment successful", true);
      }
      notified = true;
    }
  }, []);

  async function handleMakePayment() {
    const [response] = await getPaymentLink();
    const link = response.data?.link;

    if (!link) {
      showToast("Error generating payment link", false);
    } else {
      window.location.href = link;
    }
  }

  return (
    <div className="w-full min-h-[100vh] bg-white p-6 flex flex-col gap-7 rounded-2xl lg:rounded-none">
      <section className="flex justify-between w-full">
        <SectionTitle size="text-[16px] md:text-[22px]">
          Order #{order.orderId.toUpperCase()}
        </SectionTitle>

        <OrderStatus status={order.status} />
      </section>

      <Divider />

      <>
        {order.items.map((item: any, index: any) => (
          <div key={index} className="flex flex-col gap-7">
            <Section>
              <SectionTitle>Item {index + 1}</SectionTitle>

              <Section size="sm">
                <Detail
                  title={"Name"}
                  value={capitalizeFirstLetter(item.name)}
                />
                <Detail
                  title={"Amount"}
                  value={formatCurrency(
                    item.amount,
                    "NGN",
                    "symbol"
                  )}
                />
                <Detail title={"Quantity"} value={item.quantity} />
                <Detail
                  title={"Description"}
                  value={capitalizeFirstLetter(item?.description || "N/A")}
                />
              </Section>
            </Section>
            <Divider />
          </div>
        ))}
      </>

      {(!isOwner || !isBuyer) && (
        <>
          <Section>
            <SectionTitle>Buyer's Details</SectionTitle>

            <Section size="sm">
              <Detail
                title={"Name"}
                value={capitalizeFirstLetter(order.buyerName)}
              />

              <Detail
                title={"Phone Number"}
                value={order.buyerPhoneNumber || "N/A"}
              />

              <Detail title={"Email Address"} value={order.buyerEmail} />

              <Detail
                title={"Social Media Platform"}
                value={order.buyerSocialMediaPlatform || "N/A"}
              />

              <Detail
                title={"Social Media Handle"}
                value={order.buyerSocialMediaHandle || "N/A"}
              />
            </Section>
          </Section>

          <Divider />
        </>
      )}

      {(!isOwner || isBuyer) && (
        <>
          <Section>
            <SectionTitle>Merchant's Details</SectionTitle>

            <Section size="sm">
              <Detail
                title={"Name"}
                value={capitalizeFirstLetter(order.merchantBusinessName)}
              />

              <Detail
                title={"Phone Number"}
                value={order.merchantPhoneNumber || "N/A"}
              />

              <Detail title={"Email Address"} value={order.merchantEmail} />

              <Detail
                title={"Social Media Platform"}
                value={order.merchantSocialMediaPlatform || "N/A"}
              />

              <Detail
                title={"Social Media Handle"}
                value={order.merchantSocialMediaHandle || "N/A"}
              />
            </Section>
          </Section>

          <Divider />
        </>
      )}

      <Section size="sm">
        <Detail
          title={"Date"}
          value={new Date(order.created_at).toLocaleDateString(undefined, {
            year: "numeric",
            month: "long",
            day: "numeric",
          })}
        />

        <Detail
          title={"Subtotal"}
          value={formatCurrency(subtotal, "NGN", "symbol")}
        />

<Detail
          title={"Estimated Delivery Fee"}
          value={formatCurrency(order?.logisticsMeta?.originalEstimate, "NGN", "symbol")}
        />


        <Detail
          title={"Charge"}
          value={formatCurrency(order.transactionFee, "NGN", "symbol")}
        />

        <Detail
          title={"Total"}
          value={formatCurrency(
            subtotal + order.transactionFee + order?.logisticsMeta?.originalEstimate,
            "NGN",
            "symbol"
          )}
        />
      </Section>

      {/* {isLoggedIn && <UserWallet />} */}
      {isBuyer && (
        <>
          {order.status === "pending" && (
            <div className="flex w-full items-center gap-6 justify-center">
              <Button
                type="submit"
                size="sm"
                variant="secondary"
                onClick={handleMakePayment}
              >
                {loading ? (
                  <CircleLoader color="#0979A1" loading={loading} size={20} />
                ) : (
                  "Make payment"
                )}
              </Button>
            </div>
          )}
        </>
      )}
    </div>
  );
}

function Detail({
  title,
  value,
}: {
  title: string;
  value: string | ReactNode;
}) {
  return (
    <div className="flex items-center w-full">
      <p className="font-bold text-[16px] w-[40%]">{title}:</p>
      <p className="font-normal text-[14px] w-full">{value}</p>
    </div>
  );
}

function Divider() {
  return <hr className="bg-[#B6B7BA] w-full h-[1px] rounded" />;
}

function Section({
  children,
  size = "lg",
}: {
  children: ReactNode;
  size?: "lg" | "sm";
}) {
  return (
    <section
      className={classNames("flex flex-col w-full", {
        "gap-5": size === "lg",
        "gap-2": size === "sm",
      })}
    >
      {children}
    </section>
  );
}

function getStatusColor(status: string) {
  switch (status) {
    case OrderStatusEnum.PENDING:
      return "#BEE9FF";

    case OrderStatusEnum.PAID:
      return "#33B5FF";

    case OrderStatusEnum.CONFIRMED:
      return "#BBFF33";

    case OrderStatusEnum.DELIVERED:
      return "#ACFFB6";

    case OrderStatusEnum.DISPUTED:
      return "#FF6133";

    case OrderStatusEnum.CANCELLED:
      return "#F93D3D";

    case OrderStatusEnum.REFUNDED:
      return "#DAF7A6";

    case OrderStatusEnum.COMPLETED:
      return "#33FF68";

    default:
      return "#BEE9FF";
  }
}

function OrderStatus({ status }: { status: string }) {
  const color = getStatusColor(status);

  return (
    <span
      className="rounded-md text-[12px] h-7 w-20 flex items-center justify-center font-bold"
      style={{ backgroundColor: color }}
    >
      {capitalizeFirstLetter(status)}
    </span>
  );
}
