import { useState } from "react";
import Back from "../../components/back";
import Password from "../../components/auth/forgot-password/forgot-password";
import useRequest from "../../components/hooks/use-request";
import { useForm } from "react-hook-form";
import PasswordSuccess from "../../components/auth/forgot-password/reset-password-success";
import Toast, { showToast } from "../../components/toast";
import { PadLock } from "../../assets/images/icons";

const ForgotPassword = () => {
  const [success, setSuccess] = useState(false);
  const { loading, makeRequest } = useRequest("/user/forgot-password", "POST");

  const { handleSubmit, control } = useForm();

  const handleForgotPassword = handleSubmit(async (formData) => {
    const userEmail = {
      email: formData.email,
    };
    const [response] = await makeRequest(userEmail);
    if (response.status) {
      setSuccess(true);
      showToast(response.message, true, {
        position: "top-center",
      });
    } else{
      showToast(response.message, false, {
        position: "top-center",
      });
    }
  });

  return (
    <>
      
      {success ? (
        <PasswordSuccess />
      ) : (
          <Password
            handleForgotPassword={handleForgotPassword}
            loading={loading}
            control={control}
          />
      )}
      <Toast />
    </>
  );
};

export default ForgotPassword;
