import Image from "../../assets/images/works.png";
import generate from "../../assets/images/generate.png";
import pay from "../../assets/images/pay.png";
import proof from "../../assets/images/proof.png";
import code from "../../assets/images/code.png";
import receives from "../../assets/images/receives.png";
import Back from "../../components/back";
import { Link } from "react-router-dom";

const Works = () => {
  return (
    <div className="max-w-screen-xl mx-auto">
      <Back />
      <div className="flex flex-col lg:flex-row items-center justify-between  px-4 lg:px-0">
        <div className="w-full lg:w-1/2 lg:pr-8 flex flex-col gap-5 mt-5">
          <h1 className="text-xl sm:text-[30px] font-medium text-[#0979A1]">
            <span className="border border-t-0 border-l-0 border-r-0 border-b-[#0979A1]">
              How
            </span>{" "}
            It Works
          </h1>
          <p className="text-base text-center lg:text-left lg:text-lg font-normal text-[#040821] font-montserrat">
            Welcome to VendStash, where online transactions are as simple and
            reliable as they should be!
          </p>
          <p className="text-base text-center lg:text-left lg:text-lg text-[#040821] font-montserrat">
            Whether you're an experienced online shopper or a first-time seller,
            our user-friendly platform empowers you to transact with confidence.
          </p>

          {/* <p>VendStash is a product owned by Mitochron Hub.</p> */}
        </div>

        <div className="w-full text-center lg:w-1/2 lg:mt-8 ">
          <img className="w-fusll h-aut mx-auto" src={Image} alt="svg" />
        </div>
      </div>

      <div className="flex flex-col md:flex-row justify-between items-center mt-8">
        <img src={generate} alt="generate" />
        <div className="flex flex-row md:flex-row justify-start md:justify-center items-start gap-5 md:w-[50%] w-full">
          <p className="text-xl sm:text-[30px] mt-[22px] font-medium text-[#0979A1]">
            1
          </p>
          <div className="w-full gap-5 mt-0 md:mt-5">
            <h1 className="text-xl sm:text-[30px] font-medium text-[#0979A1]">
              <span className="border border-t-0 border-l-0 border-r-0 border-b-[#0979A1]">
                Generate
              </span>{" "}
              Payment Link
            </h1>
            <p className="text-base text-left md:text-center lg:text-left lg:text-lg mt-5 font-normal text-[#040821] font-montserrat">
              As a Buyer, you can pay the agreed funds for an order into Escrow
              and generate a link for the Merchant to confirm the order.
            </p>
            <p className="text-base text-left md:text-center lg:text-left lg:text-lg mt-4 font-normal text-[#040821] font-montserrat">
              Merchants can also create a payment link for an order. You can set
              the price, description, and delivery time for the order.
            </p>
            <p className="text-base text-left md:text-center lg:text-left lg:text-lg mt-4 font-normal text-[#040821] font-montserrat">
              Vendstash's Escrow system will securely hold the buyer's funds
              until the Merchant delivers the order.
            </p>
          </div>
        </div>
      </div>

      <div className="flex flex-col md:flex-row justify-between items-center mt-8">
        <div className="flex flex-row md:flex-row justify-start md:justify-center items-start gap-5 md:w-[50%] w-full">
          <p className="text-xl sm:text-[30px] mt-[22px] font-medium text-[#0979A1]">
            2
          </p>
          <div className="w-full gap-5 mt-5">
            <h1 className="text-xl sm:text-[30px] font-medium text-[#0979A1]">
              <span className="border border-t-0 border-l-0 border-r-0 border-b-[#0979A1]">
                Buyer
              </span>{" "}
              Pays for Order
            </h1>
            <p className="text-base text-left md:text-center lg:text-left mt-5 lg:text-lg font-normal text-[#040821] font-montserrat">
              If you receive a payment link from your Merchant, you need to pay
              for the order using the payment link provided.
            </p>
            <p className="text-base text-left md:text-center lg:text-left mt-4 lg:text-lg font-normal text-[#040821] font-montserrat">
              You can use various payment methods, such as your debit card, bank
              transfer, or bank USSD code to complete the payment.
            </p>
          </div>
        </div>
        <img src={pay} alt="pay" />
      </div>

      <div className="flex flex-col md:flex-row justify-between items-center mt-8">
        <img src={proof} alt="proof" />
        <div className="flex flex-row md:flex-row justify-start md:justify-center items-start gap-5 md:w-[50%] w-full">
          <p className="text-xl sm:text-[30px] mt-[22px] font-medium text-[#0979A1]">
            3
          </p>
          <div className="w-full gap-5 mt-5">
            <h1 className="text-xl sm:text-[30px] font-medium text-[#0979A1]">
              <span className="border border-t-0 border-l-0 border-r-0 border-b-[#0979A1]">
                Merchant
              </span>{" "}
              Uploads Delivery Proof
            </h1>
            <p className="text-base text-left md:text-center mt-5 lg:text-left lg:text-lg font-normal text-[#040821] font-montserrat">
              Once the Merchant delivers the order, they can upload the proof of
              delivery on VendStash. This is the evidence that shows the order
              has been completed.
            </p>
            <p className="text-base text-left md:text-center lg:text-left lg:text-lg mt-4 font-normal text-[#040821] font-montserrat">
              Uploading the proof of delivery triggers a request for the Buyer
              to enter the approval code for the funds held in Escrow to be
              released to the Merchant.
            </p>
            <p className="text-base text-left md:text-center lg:text-left lg:text-lg mt-4 font-normal text-[#040821] font-montserrat">
              If a Buyer fails to approve the release of the funds after three
              (3) hours from the upload time, they will be contacted by
              VendStash at three (3) different intervals within the next 36
              hours.
            </p>
            <p className="text-base text-left md:text-center lg:text-left lg:text-lg mt-4 font-normal text-[#040821] font-montserrat">
              If the Buyer still fails to approve the release of the funds in
              Escrow or enter a dispute by requesting Mediation, VendStash will
              assess the proof of delivery and may release the funds to the
              Merchant.
            </p>
            <p className="text-base text-left md:text-center lg:text-left lg:text-lg font-normal text-[#040821] font-montserrat">
              If a Merchant fails to upload a proof of delivery, VendStash will
              not contact the Buyer on the Merchant’s behalf or release the
              funds until the Buyer approves by entering the unique 4-digit
              code.
            </p>
          </div>
        </div>
      </div>

      <div className="flex flex-col md:flex-row justify-between items-center mt-8">
        <div className="flex flex-row md:flex-row justify-start md:justify-center items-start gap-5 md:w-[50%] w-full">
          <p className="text-xl sm:text-[30px] mt-[22px] font-medium text-[#0979A1]">
            4
          </p>
          <div className="w-full lg:pr-8 flex flex-col gap-5 mt-5">
            <h1 className="text-xl sm:text-[30px] font-medium text-[#0979A1]">
              <span className="border border-t-0 border-l-0 border-r-0 border-b-[#0979A1]">
                Buyer
              </span>{" "}
              Provides Release Code
            </h1>
            <p className="text-base text-left md:text-center lg:text-left lg:text-lg font-normal text-[#040821] font-montserrat">
              After the Buyer pays for an order, a unique 4-digit code is sent
              to their email and registered phone number.
            </p>
            <p className="text-base text-left md:text-center lg:text-left lg:text-lg font-normal text-[#040821] font-montserrat">
              Once the Buyer receives the order, they can enter the release code
              on VendStash and the funds will be released to the Merchant,
              whether the Merchant has uploaded a proof of delivery or not.
            </p>
            <p className="text-base text-left md:text-center lg:text-left lg:text-lg font-normal text-[#040821] font-montserrat">
              The release code is a unique code that confirms the buyer's
              satisfaction and it triggers the release of the payment from
              Vendstash's Escrow system to the Merchant’s account.
            </p>
          </div>
        </div>
        <img src={code} alt="code" />
      </div>

      <div className="flex flex-col md:flex-row justify-between items-center mt-8">
        <img src={receives} alt="receives" />
        <div className="flex flex-row md:flex-row justify-start md:justify-center items-start gap-5 md:w-[50%] w-full">
          <p className="text-xl sm:text-[30px] mt-[22px] font-medium text-[#0979A1]">
            5
          </p>
          <div className="w-full lg:pr-8 flex flex-col gap-5 mt-5">
            <h1 className="text-xl sm:text-[30px] font-medium text-[#0979A1]">
              <span className="border border-t-0 border-l-0 border-r-0 border-b-[#0979A1]">
                Merchant
              </span>{" "}
              Receives Fund
            </h1>
            <p className="text-base text-left md:text-center lg:text-left lg:text-lg font-normal text-[#040821] font-montserrat">
              As soon as the Buyer enters the release code, the Merchant Will
              receive the funds in their VendStash wallet automatically created
              upon sign up.
            </p>
            <p className="text-base text-left md:text-center lg:text-left lg:text-lg font-normal text-[#040821] font-montserrat">
              Merchants can withdraw the funds in their wallets to their bank
              account immediately or via a scheduled payout that is processed
              every night from Mondays - Saturdays.
            </p>
            <p className="text-base text-left md:text-center lg:text-left lg:text-lg font-normal text-[#040821] font-montserrat">
              Immediate withdrawals cost a small processing fee while scheduled
              payouts are free.
            </p>
          </div>
        </div>
      </div>

      <div className="flex flex-col md:flex-row justify-between items-center mt-8">
        <div className="flex flex-row md:flex-row justify-start md:justify-center items-start gap-5 md:w-[50%] w-full">
          <p className="text-xl sm:text-[30px] mt-[22px] font-medium text-[#0979A1]">
            6
          </p>
          <div className="w-full lg:pr-8 flex flex-col gap-5 mt-5">
            <h1 className="text-xl sm:text-[30px] font-medium text-[#0979A1]">
              <span className="border border-t-0 border-l-0 border-r-0 border-b-[#0979A1]">
                Refunds
              </span>{" "}
            </h1>
            <p className="text-base text-left md:text-center lg:text-left lg:text-lg font-normal text-[#040821] font-montserrat">
              A refund is automatically processed to the Buyer’s wallet if a
              Merchant declines to confirm the order generated by the Buyer.
              VendStash does not charge any fee in this case.
            </p>
            <p className="text-base text-left md:text-center lg:text-left lg:text-lg font-normal text-[#040821] font-montserrat">
              A refund is manually processed from the Escrow by VendStash if the
              Merchant fails to deliver the goods after confirmation. The Buyer
              will VendStash’s intervention via Mediation and the Merchant will
              be contacted to provide their account of the transaction.
            </p>
          </div>
        </div>
        <img src={receives} alt="receives" />
      </div>

      <div className="flex flex-col md:flex-row justify-between items-center mt-8">
        <img src={receives} alt="receives" />
        <div className="flex flex-row md:flex-row justify-start md:justify-center items-start gap-5 md:w-[50%] w-full">
          <p className="text-xl sm:text-[30px] mt-[22px] font-medium text-[#0979A1]">
            7
          </p>
          <div className="w-full lg:pr-8 flex flex-col gap-5 mt-5">
            <h1 className="text-xl sm:text-[30px] font-medium text-[#0979A1]">
              <span className="border border-t-0 border-l-0 border-r-0 border-b-[#0979A1]">
                Dispute
              </span>{" "}
              and Mediation
            </h1>
            <p className="text-base text-left md:text-center lg:text-left lg:text-lg font-normal text-[#040821] font-montserrat">
              Either a Buyer or Merchant can request a VendStash Mediation
              through their dashboard menu. (This option is only available for
              those that have created a VendStash account). Both the Buyer and
              the Merchant will be given the opportunity to represent their
              account of the transaction.
            </p>
            <p className="text-base text-left md:text-center lg:text-left lg:text-lg font-normal text-[#040821] font-montserrat">
              VendStash mediators are professionals (not AI or robots) who
              understand dispute resolution and are experienced in handling
              commercial disputes. Their job is to make both parties come to a
              logical resolution.
            </p>
            <p className="text-base text-left md:text-center lg:text-left lg:text-lg font-normal text-[#040821] font-montserrat">
              Once the parties have agreed on a decision, VendStash will
              implement that decision. If the parties fail to agree, VendStash
              may provide other options to resolve the dispute or enter a fair
              and justifiable decision which will be binding.
            </p>
          </div>
        </div>
      </div>

      <div className="my-12 text-base sm:text-lg font-medium px-4 text-center">
        <p>
          Vendstash charges a minimal fee for each transaction, which helps to
          maintain the quality and security of the platform.
        </p>
        <p>
          Please see our Pricing{" "}
          <Link to="/pricing" className="underline text-[#0979A1]">
            here
          </Link>
          .
        </p>
        <p>
          VendStash: Your gateway to a secure, rewarding, and reliable online
          transaction experience.
        </p>
      </div>
    </div>
  );
};

export default Works;
