import Card from "../card";
import escrowLock from "../../assets/images/money.svg";
import approval from "../../assets/images/money-and-coin.svg";
import payments from "../../assets/images/cash-stack.svg";
import withdrawal from "../../assets/images/withdrawal.svg";
import support from "../../assets/images/headphone.svg";

interface CardItems {
  logo: string;
  alt: string;
  hero: string;
  description: string;
}

const cardItems: CardItems[] = [
  {
    logo: payments,
    alt: "payments",
    hero: "Payments",
    description: "Create and share payment links in less than 3 minutes",
  },
  {
    logo: approval,
    alt: "approval-icon",
    hero: "Approval on Delivery",
    description: "Approve funds only on safe delivery",
  },
  {
    logo: escrowLock,
    alt: "escrow-lock-icon",
    hero: "Escrow Lock",
    description: "Secure your money with escrow lock",
  },
  {
    logo: withdrawal,
    alt: "withdrawal-icon",
    hero: "Withdrawals",
    description: "Easy and quick withdrawal for Merchants",
  },
  {
    logo: support,
    alt: "support-icon",
    hero: "Support & Mediation",
    description: "Effective Customer Support and Dispute Mediation Center",
  },
];

const Use = () => {
  return (
    <div className="my-4 mt- lg:mt-0 px-[4%]">
      <div className="sm:max-w-screen-xl sm:mx-auto py-10 text-white">
        <p className="text-center text-xl sm:text-[30px] font-medium text-[#0979A1]">
          With VendStash, what you ordered...
        </p>
        <div className="animate-fade-left flex flex-col md:flex-row justify-center mt-6 text-black gap-y-4 sm:gap-y-12 gap-x-[8%] flex-wrap">
          {cardItems.map(({ logo, alt, hero, description }) => (
            <Card
              key={hero}
              showCard={true}
              classNames="flex flex-col gap-y-2 gap-x-2 px-10 pt-4 pb-8 basis-[28%]"
            >
              <div className="">
                <img src={logo} alt={alt} />
              </div>
              <div className="text-base md:text-lg font-semibold">{hero}</div>
              <div className="text-sm lg:text-base font-normal">
                {description}
              </div>
            </Card>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Use;
