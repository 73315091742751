import "./app.css";
import AOS from 'aos';
import 'aos/dist/aos.css';
import RoutesWrapper from "./routes/routes-wrapper";
import Toast from "./components/toast";
import Navbar from "./components/layout/navbar";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const App: React.FC = () => {
  const location = useLocation();
  const [showHeader, setShowHeader] = useState(true);

  useEffect(() => {
    const login = new RegExp(`/merchant/login*`);
    const signup = new RegExp(`/create-account*`);

    if (login.test(location.pathname) || signup.test(location.pathname)) {
      setShowHeader(false);
    } else {
      setShowHeader(true);
    }
  }, [location]);

  useEffect(()=>{
    AOS.init(
      {
        offset: 120,
        delay: 0,
        duration: 1200, 
        easing: 'ease', 
        once: true, 
        mirror: false,
      })
  })

  return (
    <>
      {showHeader && <Navbar />}
      <RoutesWrapper />
      <Toast />
    </>
  );
};

export default App;
