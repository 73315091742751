import React from "react";
import Button from "../../button";
import Card from "../../onboarding-card";

const ResetPasswordSuccess: React.FC = () => {
  return (
    <Card
      title="Reset Link sent"
      subtitle=" A password reset code has been sent to your email address"
      classNames="my-10"
    >
      <Button size="lg" variant="primary" href="/reset-password">
        Continue
      </Button>
    </Card>
  );
};

export default ResetPasswordSuccess;
