import React from "react";
import { Link } from "react-router-dom";
import Logo from "../assets/images/Logo Desktop.svg";
import Icon from "../assets/images/icons";
import Support from "../assets/images/Headset.svg";
import Mail from "../assets/images/Email.svg";
import Whatsapp from "../assets/images/WhatsApp.svg";
import Divider from "./footer-promo";

const Footer = () => {
  return (
    <>
 <div className="bg-[#00131C] mb-7 lg:mb-[150px]">
  <Divider/>
 </div>
    <div className=" bg-[#00131C] text-[#BEE9FF] py-6 lg:pb-12">
      <div className="w-[94%] mx-auto ">
        <section className="grid lg:grid-cols-4 grid-cols-1 px-6 lg:px-16 gap-7 md:gap-0 md:pl-0">

          <div className="col-span-1">
            <h3 className="text-[16px] font-semibold uppercase">About us</h3>
            <ul className="mt-4 text-[14px] font-medium leading-8">
              <li>
                <Link to="https://vendstash.wordpress.com/" target="_blank" rel="noopener noreferrer">Blog</Link>
              </li>
              <li>
                <a href="#pricing">Pricing</a>
              </li>
             
              <li>
                <Link to="/faq">FAQ</Link>
              </li>
              <li>
                <Link to="#">Career</Link>
              </li>
            </ul>
          </div>

          <div className="col-span-1">
            <h3 className="text-[16px] font-semibold uppercase">Legal</h3>
            <ul className="mt-4 text-[14px] font-medium leading-8">
            <li>
                <Link to="#"> About us</Link>
              </li>
              <li>
                <Link to="/privacy-policy"> Privacy Policy</Link>
              </li>
              <li>
                <Link to="#">Terms of Service</Link>
              </li>
            
            </ul>
          </div>
          

          <div className="col-span-1">
            <h3 className="text-[16px] font-semibold uppercase">Social media</h3>
            <ul className="mt-4 text-[14px] font-medium leading-8">
              <li>
                <Link to='https://web.facebook.com/61554099410029/' className="no-underline" target="_blank" rel="noopener noreferrer">
                Facebook
                </Link>
              </li>
              <li>
                <Link to='https://www.linkedin.com/company/vendstash/' className="no-underline" target="_blank" rel="noopener noreferrer">
                LinkedIn
                </Link>
              </li>
              <li>
                <Link to='https://twitter.com/vendstash' className="no-underline" target="_blank" rel="noopener noreferrer">
                X(Twitter)
                </Link>
              </li>
            </ul>
          </div>
          <div className="col-span-1 lg:ml-auto">
          <p className="md:text-[16px] text-[10px] font-normal">
             © {new Date().getFullYear()} A product of Mitochron Hub
          </p>
         
        </div>
        </section>
      </div>
    </div></>
  );
};

export default Footer;
