import React, { useEffect, useState } from "react";
import Back from "../../../components/back";
import { Controller, useForm } from "react-hook-form";
import Input from "../../../components/input";
import useRequest from "../../../components/hooks/use-request";
import { showToast } from "../../../components/toast";
import Button from "../../../components/button";
import { CircleLoader } from "react-spinners";
import { Modal } from "antd";
import { Link } from "react-router-dom";
import { formatSimpleCurrency } from "../../../utils/functions";

interface User {
  merchantName: string;
  balance: number;
  bankName: string;
  bankAccountNumber: string;
  created_at: string;
}

const Payout = () => {
  const [user, setUser] = useState<User | null>(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const userToken = localStorage.getItem("token");
  const requestDetails = localStorage.getItem("amount");
  const details = requestDetails ? JSON.parse(requestDetails) : null;
  const { loading: payoutLoading, makeRequest: payoutRequest } = useRequest(
    "/user/withdraw",
    "POST",
    {
      Authorization: `Bearer ${userToken}`,
    }
  );

  const { makeRequest: getUser } = useRequest("/user/profile", "GET", {
    Authorization: `Bearer ${userToken}`,
  });

  useEffect(() => {
    const fetchData = async () => {
      const [response] = await getUser();
      setUser(response.data || []);
    };
    fetchData();
  }, []);

  const { handleSubmit, control } = useForm();

  const handlePayout = handleSubmit(async (formData) => {
    const userData = {
      amount: formData.amount,
    };
    const [response] = await payoutRequest(userData);
    if (response.status) {
      localStorage.setItem("amount", JSON.stringify(response?.data));
      showToast(response.message, true, {
        position: "top-center",
      });
      showModal();
    } else {
      showToast(response.message, false, {
        position: "top-center",
      });
    }
  });

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <Back />
      <div className="lg:w-[30%] md:w-[50%] mx-auto ">
        <h1 className="text-[24px] font-bold text-center mt-5">Withdraw</h1>
        <p className="text-center">Request Withdrawal</p>

        <div className="flex-[30%] mx-8  mt-10">
          <Controller
            name="amount"
            control={control}
            defaultValue={""}
            rules={{
              required: "Amount is required",
              pattern: {
                value: /^[0-9]+$/,
                message: "Enter a valid number",
              },
            }}
            render={({ field, fieldState }) => (
              <Input
                value={field.value}
                label="Amount"
                type="number"
                className="w-full"
                error={fieldState?.error?.message}
                onChange={(e) => {
                  const numericValue = e.target.value.replace(/\D/g, "");
                  field.onChange(
                    numericValue === "" ? "" : parseInt(numericValue, 10)
                  );
                }}
              />
            )}
          />
        </div>

        <div className="flex justify-center items-center mt-10 w-full mb-6">
          <Button
            size="md"
            variant="primary"
            type="submit"
            onClick={() => handlePayout()}
          >
            {payoutLoading ? (
              <CircleLoader color="#ffffff" loading={payoutLoading} size={20} />
            ) : (
              "Withdraw"
            )}
          </Button>
        </div>
      </div>

      <Modal
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        width={500}
        closable={true}
        footer={null}
        className="flex justify-center justify-items-center px-auto mt-24"
      >
        <h1 className="font-bold text-[#040821] text-lg md:text-[30px]">
          Request Details
        </h1>
        <div className=" w-full">
          <div className="flex flex-wrap mt-7">
            <div className="flex justify-between w-full">
              <p className="text-[18px]">Bank Name</p>
              <p className="text-right text-[18px] font-medium align">
                {user?.bankName}
              </p>
            </div>

            <div className="flex justify-between w-full mt-4">
              <p className="text-[18px]">Account number</p>
              <p className="text-right text-[18px] font-medium align">
                {user?.bankAccountNumber}
              </p>
            </div>

            <div className="flex justify-between w-full mt-4">
              <p className="text-[18px]">Amount:</p>
              <p className="text-right text-[18px] font-medium">
                {formatSimpleCurrency(details?.amount)}
              </p>
            </div>

            <div className="flex justify-between w-full mt-4">
              <p className="text-[18px]">Date</p>
              <p className="text-right text-[18px] font-medium ">
                {new Date(details?.created_at || "").toLocaleDateString(
                  undefined,
                  {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                  }
                )}
              </p>
            </div>

            <p className="bg-[#E6F3FF] text-[#0066CC] text-[12px] p-4 rounded-md mt-4 font-semibold">
              Your withdrawal request will be processed and disbursed to your
              bank account by 7 am.
            </p>
          </div>
        </div>
        <div className="flex justify-center mx-auto w-full mt-6">
          <Button variant="primary" size="full-half">
            <Link to="/dashboard">Back to home</Link>
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default Payout;
