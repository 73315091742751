import React from 'react'
import Carousel from '../../components/blog/carousel'
import Articles from '../../components/blog/articles'
import Footer from '../../components/footer'

const Blog = () => {
  return (
    <div>
        <Carousel/>
        <Articles/>
        <Footer/>
    </div>
  )
}

export default Blog