import React, { useEffect, useRef } from "react";
import useRequest from "../hooks/use-request";
import { useNavigate } from "react-router-dom";
import Toast, { showToast } from "../toast";
import ResendCode from "./resend-code";
import Button from "../button";
import { CircleLoader } from "react-spinners";
import Card from "../onboarding-card";
import { Controller, useForm } from "react-hook-form";
import Input from "../input";
import { maskEmail } from "../../utils/functions";

interface CodeInputProps {
  code: string;
  setCode: React.Dispatch<React.SetStateAction<string>>;
  onSuccess: () => void;
}

const CodeInput: React.FC<CodeInputProps> = () => {
  const userToken = localStorage.getItem("verifyToken");
  const userEmail = localStorage.getItem("userEmail");
  const { loading, makeRequest } = useRequest("/user/verify-email", "PUT", {
    Authorization: `Bearer ${userToken}`,
  });
  const navigate = useNavigate();

  const { handleSubmit, control } = useForm();

  const handleResetPassword = handleSubmit(async (formData) => {
    if (userToken === null) {
      navigate("/signin");
      return;
    }
    const userCode = {
      verificationCode: formData.verificationCode,
    };
    const [response] = await makeRequest(userCode);
    if (response.status) {
      showToast(response.message, true, {
        position: "top-center",
      });
      navigate("/signin");
    } else {
      showToast(response.message, false, {
        position: "top-center",
      });
    }
  });

  return (
    <Card title="Check your email" classNames="">
      <form
        onSubmit={handleResetPassword}
        className="flex flex-col items-left md:items-center gap-6 pt-6"
      >
        <div className="flex flex-col gap-4 w-full text-[16px] md:text-base md:text-center">
          <p className="text-[#475467] text-sm">
            We have sent a 4 digit code to{" "}
            <span className="text-[#1D2939] font-semibold">
             {maskEmail(userEmail)}
            </span>
          </p>

          <div className="flex items-center justify-center gap-[3%] md:gap-[2%]">
            <Controller
              name="verificationCode"
              control={control}
              defaultValue=""
              rules={{
                required: "Code is required",
                minLength: {
                  value: 4,
                  message: "Code must be 4 digits",
                },
              }}
              render={({ field, fieldState }) => (
                <Input
                  value={field.value}
                  placeholder="Enter Code"
                  className="w-full"
                  type="number"
                  error={fieldState?.error?.message}
                  onChange={field.onChange}
                />
              )}
            />
          </div>
          <ResendCode />
        </div>
        <Button size="full-half" variant="primary" type="submit">
          {loading ? (
            <CircleLoader color="#ffffff" loading={loading} size={20} />
          ) : (
            "continue"
          )}
        </Button>
      </form>
      <Toast />
    </Card>
  );
};

export default CodeInput;
