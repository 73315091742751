import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Logo from "../logo";
import notificationIcon from "../../assets/images/notification-bing.png";
import classNames from "classnames";
import Icon from "../../assets/images/icons";
import { Modal } from "antd";
import Delete from "../../pages/dashboard/delete";
import useRequest from "../hooks/use-request";
import Signout from "../signout";
import { signOut } from "../../utils/functions";
import Mediation from "./mediation";
import Divider from "../footer-promo";

interface User {
  userType?: string;
}

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navigate = useNavigate();
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [isSignoutModalVisible, setIsSignoutModalVisible] = useState(false);
  const location = useLocation();
  const pathname = location.pathname;
  const iconName = isMenuOpen ? "menuIconClose" : "menuIconOpen";
  const isLoggedIn = localStorage.getItem("token");
  const userJSON = localStorage.getItem("user");
  const user = userJSON ? JSON.parse(userJSON) : null;
  const logoLink = isLoggedIn ? "/dashboard" : "/";

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
    if (!isMenuOpen) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }
  };

  const handleSignOut = () => {
    signOut();
    toggleSignoutModal();
    navigate("/");
  };

  const toggleDeleteModal = () => {
    setIsDeleteModalVisible(!isDeleteModalVisible);
  };

  const toggleSignoutModal = () => {
    setIsSignoutModalVisible(!isSignoutModalVisible);
  };

  const handleDeleteAccount = () => {
    toggleDeleteModal();
  };

  const routes = isLoggedIn
    ? [
        { path: "/dashboard", label: "Dashboard" },
        { path: "/about", label: "About Us" },
        { path: "/how-it-works", label: "How it works" },
        { path: "/pricing", label: "Pricing" },
        { path: "/terms", label: "Terms and Conditions" },
        { path: "/privacy-policy", label: "Privacy Policy" },
        { path: "/faq", label: "Frequently Asked Questions" },
        {
          path: "/dashboard/profile/delete",
          label: "Delete Account",
          onClick: handleDeleteAccount,
        },
        { path: "/contact", label: "Customer Support" },
        { path: "/signout", label: "Sign Out", onClick: toggleSignoutModal },
      ]
    : [
        { path: "/", label: "Home" },

        { path: "/pricing", label: "Pricing" },
        { path: "/contact", label: "Contact" },
      ];

  if (user?.userType === "buyer") {
    routes.splice(-2, 0, { path: "/buyer-refund", label: "Request Refund" });
    routes.splice(-2, 0, {
      path: "/blacklist/merchant/report",
      label: "Report Merchants",
    });
  }

  useEffect(() => {
    const timeout = 10 * 60 * 4000;
    const timer = setTimeout(() => {
      signOut();
      navigate("/");
    }, timeout);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <div className='relative bg-[#fff] border-[#000]'>
      {!isLoggedIn && pathname == "/" && (
        <div className='bg-[#00131C] py-6 w-full'>
          <div className='overflow-hidden whitespace-nowrap  text-white'>
            <div className='flex space-x-24 animate-marquee'>
              {[...Array(15)].map((_, index) => (
                <span key={index} className='flex items-center space-x-4'>
                  <span className='block xl:inline font-semibold'>
                    Special Promo Alert
                  </span>
                  <span className='hidden xl:inline lg:mx-4'>
                    <svg
                      width='41'
                      height='6'
                      viewBox='0 0 41 6'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'>
                      <path
                        d='M40.2998 5.04715L-0.000194729 5.04715L-0.00019455 0.952147L40.2998 0.952148L40.2998 5.04715Z'
                        fill='#BEE9FF'
                      />
                    </svg>
                  </span>
                  <span className='block xl:inline'>
                    Unlock Secure Shopping & Get 50% Off Transaction Fee
                    (Limited Offer)
                  </span>
                </span>
              ))}
            </div>
          </div>
          <div
            data-aos='zoom-in'
            className=' text-[#BEE9FF] text-center flex flex-col lg:flex-row items-center justify-center text-xs lg:text-base'>
            {/* <span className='block  xl:inline font-semibold'>
              Special Promo Alert
            </span>
            <span className='hidden xl:inline lg:mx-4'>
              <svg
                width='41'
                height='6'
                viewBox='0 0 41 6'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'>
                <path
                  d='M40.2998 5.04715L-0.000194729 5.04715L-0.00019455 0.952147L40.2998 0.952148L40.2998 5.04715Z'
                  fill='#BEE9FF'
                />
              </svg>
            </span>
            <span className='block xl:inline'>
              Unlock Secure Shopping & Get 100% Off Transaction Fee (Limited
              Offer)
            </span> */}
          </div>
        </div>
      )}
      <div className=' mx-auto py-6 lg:px-16 px-6 relative z-50'>
        <div className='flex justify-between items-center cursor-pointer'>
          <Link
            to={logoLink}
            className=' w-[168px] md:w-[180px] lg:w-[221px] h-[10px] md:h-[15px] flex items-center'>
            <Logo />
          </Link>

          <div className='basis-1/2 flex justify-end gap-x-4'>
            {!isLoggedIn && pathname == "/" && (
              <Link to='/' className='hidden sm:block'>
                <button className=' text-[16px] font-normal text-[#2A3136] px-8 hidden lg:block'>
                  Home
                </button>
              </Link>
            )}

            {!isLoggedIn && pathname == "/" && (
              <a href='#pricing' className='hidden sm:block'>
                <button className='text-[16px] font-normal px-8 text-[#2A3136] hidden lg:block'>
                  Pricing
                </button>
              </a>
            )}

            {!isLoggedIn && pathname == "/" && (
              <Link to='/contact' className='hidden sm:block'>
                <button className='text-[16px] font-normal px-8 text-[#2A3136] hidden lg:block'>
                  Contact Us
                </button>
              </Link>
            )}
          </div>

          <div className='basis-1/2 flex justify-end gap-x-4'>
            {!isLoggedIn && pathname != "/signin" && (
              <Link to='/signin' className='hidden lg:block'>
                <button className='w-[161px] rounded-2xl bg-[#BEE9FF] hover:bg-[#9ED589]  text-xl h-[62px] font-medium hidden sm:block text-[#003546]'>
                  Log In
                </button>
              </Link>
            )}

            {!isLoggedIn && pathname != "/signup" && (
              <Link to='/signup' className='hidden lg:block'>
                <button className='w-[161px] rounded-2xl bg-[#004D65] hover:bg-[#003546] text-white h-[62px] text-xl font-medium hidden sm:block'>
                  Sign up
                </button>
              </Link>
            )}

            <div
              className={`${
                pathname !== "/signup/verify" && isLoggedIn
                  ? "flex items-center gap-4 pr-12 lg:gap-8"
                  : ""
              } `}>
              {pathname !== "/signup/verify" && isLoggedIn && (
                <>
                  <Mediation />
                  <img
                    className='cursor-pointer w-[32px]'
                    src={notificationIcon}
                    alt='icon'
                  />
                </>
              )}

              <button onClick={toggleMenu} className='block lg:hidden'>
                Menu
              </button>

              {isLoggedIn && (
                <button onClick={toggleMenu} className='hidden lg:block'>
                  Menu
                </button>
              )}
            </div>
          </div>
        </div>
      </div>

      {isMenuOpen && (
        <div
          className=' md:relative md:max-w-[100%] h-[900px] md:[600px] md:bg-[#F8F8F8]  max-w-[100%] ml-auto inset-0 md:mt-6 lg:mt-36 z-50 md:-z-0 justify-center'
          onClick={toggleMenu}>
          <div className='relative top-[220px] z-50 md:top-[75px] md:w-full left-1/2 w-[100%] bg-white -mt-10 md:mt-36 lg:mt-0 md:bg-[#F8F8F8] transform -translate-x-1/2 -translate-y-1/2 pt-8 rounded-md'>
            <div className='w-[100%] max-w-screen-xl mx-auto flex items-center justify-end p-1 xl:container xl:pr-[7%] mt-2 md:hidden'>
              <button onClick={toggleMenu} className='p-2 mr-6'>
                Close
              </button>
            </div>
            <ul
              className={classNames(
                "flex flex-col gap-3 items-center md:text-[26px] h-[450px] text-center text-[#040821] font-OrbiterFBSTrial font-semibold",
                {
                  "mt-6": user?.userType === "buyer",
                  "mt-10": user?.userType === "merchant",
                }
              )}>
              {routes.map((route) => (
                <li
                  key={route.path}
                  className={classNames("font-OrbiterFBSTrial font-semibold", {
                    "text-[#0979A1] text-[16px] text-3x border-b-3 border-[#0979A1]":
                      location.pathname === route.path,
                    "text-[13px] hover:text-2xl hover:scale-y-100":
                      location.pathname !== route.path,
                  })}>
                  {route.onClick ? (
                    <button onClick={route.onClick}>{route.label}</button>
                  ) : (
                    <Link to={route.path}>{route.label}</Link>
                  )}
                </li>
              ))}
              {!isLoggedIn && pathname != "/signup" && (
                <Link to='/signup' className='w-11/12 lg:hidden'>
                  <button className='w-full rounded-lg bg-[#004D65] hover:bg-[#003546] text-white h-[40px] text-xs font-medium '>
                    Sign up
                  </button>
                </Link>
              )}
              {!isLoggedIn && pathname != "/signin" && (
                <Link to='/signin' className='w-11/12 lg:hidden'>
                  <button className='w-full rounded-lg bg-[#BEE9FF] hover:bg-[#9ED589]  text-xs h-[40px] font-medium  text-[#003546]'>
                    Log In
                  </button>
                </Link>
              )}

              {!isLoggedIn && pathname != "/signup" && (
                <Link to='/signup' className='hidden lg:block'>
                  <button className='w-[161px] rounded-2xl bg-[#004D65] hover:bg-[#003546] text-white h-[62px] text-xl font-medium hidden sm:block'>
                    Sign up
                  </button>
                </Link>
              )}
            </ul>
            <div className='w-[100%] md:-mt-20 text-center hidden md:block   text-gray-600 font-Nunito text-base font-normal'>
              Copyright © {new Date().getFullYear()} VendStash
            </div>
          </div>
        </div>
      )}
      <Modal
        width={730}
        open={isDeleteModalVisible}
        onCancel={toggleDeleteModal}
        footer={null}>
        <Delete onYes={handleDeleteAccount} onNo={toggleDeleteModal} />
      </Modal>
      <Modal
        width={530}
        open={isSignoutModalVisible}
        onCancel={toggleSignoutModal}
        footer={null}>
        <Signout onYes={handleSignOut} onNo={toggleSignoutModal} />
      </Modal>
    </div>
  );
};

export default Navbar;
