import classNames from "classnames";
import { ChangeEvent, forwardRef } from "react";
import { Subtitle } from "./text";

interface InputProps {
  label?: string;
  placeholder?: string;
  className?: string;
  type?: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  name?: string;
  value?: string | number;
  error?: string;
  readOnly?: boolean;
  variant?: "primary" | "secondary";
  ref?: any;
  selectedValue?: string | number;
  checked?: boolean; // Add checked property
}

const RadioButton = forwardRef(
  (
    {
      label,
      className,
      onChange,
      name,
      value,
      error,
      readOnly = false,
      variant = "primary",
      selectedValue,
      checked,
    }: InputProps,
    ref
  ) => {
    const isSelected = checked || value === selectedValue;

    return (
      <div className={`flex flex-col gap-2 md:gap-3 ${className}`}>
        <label className="flex items-center cursor-pointer">
          <input
            ref={ref as any}
            className="hidden"
            onChange={onChange}
            value={value}
            type="radio"
            name={name}
            readOnly={readOnly}
            checked={isSelected}
          />

          <span
            className={classNames(
              "custom-radio w-[20px] h-[20px] border-2 rounded-full flex justify-center items-center shadow border-[#E5E7EB]",
              {
                "bg-[#96E5B8]": isSelected && variant === "primary",
                "bg-[#E11D48]": isSelected && variant === "secondary",
              }
            )}
          >
            {isSelected && (
              <span className="w-[10px] h-[10px] bg-white rounded-full" />
            )}
          </span>

          <span className="ml-2 text-sm">{label}</span>
        </label>

        {error && (
          <span className="error-text text-[#FF0101] text-[12px]">{error}</span>
        )}
      </div>
    );
  }
);

export default RadioButton;
