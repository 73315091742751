import { CircleLoader } from "react-spinners";
import { useNavigate } from "react-router-dom";
import Toast, { showToast } from "../../components/toast";
import useRequest from "../../components/hooks/use-request";

const ResendCode = ({ orderId }: { orderId: string }) => {
  const { loading, makeRequest } = useRequest(
    `/orders/resend-code/${orderId}`,
    "GET"
  );
  const navigate = useNavigate();

  async function handleResendCode(e: any) {
    e.preventDefault();

    const [response, status] = await makeRequest();
    if (status === 200) {
      showToast(response.message, true, {
        position: "top-center",
      });
    } else {
      showToast(response.message, false, {
        position: "top-center",
      });
    }
  }
  return (
    <div className="text-center">
      I did not receive a code.{" "}
      <button className="text-[#0979A1]" onClick={handleResendCode}>
        {loading ? (
          <CircleLoader
            color="#ffffff"
            loading={loading}
            size={20}
            onClick={handleResendCode}
          />
        ) : (
          "Resend code"
        )}
      </button>
      <Toast />
    </div>
  );
};

export default ResendCode;
