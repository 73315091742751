import { useState, useCallback } from 'react';

const useSelectedOrder = () => {
  const [selectedOrderId, setSelectedOrderId] = useState<string | null>(null);

  const selectOrder = useCallback((orderId: string) => {
    setSelectedOrderId(orderId);
  }, []);

  const clearSelectedOrder = useCallback(() => {
    setSelectedOrderId(null);
  }, []);

  return {
    selectedOrderId,
    selectOrder,
    clearSelectedOrder,
  };
};

export default useSelectedOrder;
