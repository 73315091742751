import React, { useState } from "react";
import { Link } from "react-router-dom";
import defaultShop from "../../assets/images/shop-default.svg";
import Icon from "../../assets/images/icons";

interface MerchantCardProps {
  name: string;
  type: string;
  reports: number;
  link: string;
}

const MerchantCard: React.FC<MerchantCardProps> = ({
  name,
  type,
  reports,
  link,
}) => {
  const [liked, setLiked] = useState(false);
  const [disliked, setDisliked] = useState(false);

  const handleLike = () => {
    setLiked(!liked);
    setDisliked(false);
  };

  const handleDislike = () => {
    setDisliked(!disliked);
    setLiked(false);
  };

  return (
    <div className='border border-solid rounded-lg px-3 md:px-10 py-3 md:py-4 mb-3'>
      <div className='flex gap-2 justify-between items-center'>
        <div className='flex gap-5'>
          <img
            src={defaultShop}
            alt='Shop'
            className='w-12 h-12 md:w-16 md:h-16'
          />
        </div>

        <div className='flex gap-2 justify-between flex-1'>
          <div className='flex-[55%] flex flex-col gap-1'>
            <p className='font-bold text-base md:text-xl'>{name}</p>
            <p className='text-sm md:text-lg text-[#040821]'>{type}</p>
            <div className='flex justify-between items-center w-full'>
              <p className='text-xs md:text-sm'>
                Reported by:{" "}
                <span className='text-[#0979A1]'>{reports} accounts</span>
              </p>
            </div>
          </div>
          <div className='flex flex-col justify-between'>
            <div className='flex  ml-auto cursor-pointer gap-2'>
              <button
                onClick={handleLike}
                className={liked ? "bg-blue-500" : ""}>
                <Icon name='thumbsup' className='shadow-md rounded' />
              </button>
              <button
                onClick={handleDislike}
                className={disliked ? "bg-red-500" : ""}>
                <Icon name='thumbsdown' className='shadow-md rounded' />
              </button>
            </div>
            <Link to={link}>
              <button className='flex items-center px-2 md:px-4 py-2 rounded-full bg-[#0979A1] text-[10px] md:text-base text-white'>
                See reviews
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MerchantCard;
