import React from "react";
import { showToast } from "../../../components/toast";
import Icon from "../../../assets/images/icons";
import { capitalizeFirstLetter } from "../../../utils/functions";

interface EditFormProps {
  user: any;
  referrals: any;
}

const Referral = ({ user, referrals }: EditFormProps) => {
  const userType = user?.userType;

  const Details = ({ title, value, canCopy = false, isLink = false }: any) => {
    const copyToClipboard = (text: string) => {
      navigator.clipboard.writeText(text).then(
        () => {
          showToast("Copied to clipboard successfully!", true);
        },
        (err) => {
          showToast("Failed to copy to clipboard", false);
        }
      );
    };

    return (
      <div className="grid grid-cols-2 px-6 mt-4 text-sm break-all font-normal">
        <p>{title}</p>
        <div className="flex items-center justify-between">
          {isLink ? (
            <button
              className="h-[38px] p-4 flex items-center justify-center text-[12px] font-medium rounded-md bg-[#CFF0FC] hover:bg-[#4dc2ed] text-[#0978a1]"
              onClick={() => copyToClipboard(value)}
            >
              Copy Referral Link
            </button>
          ) : (
            <p className="text-left">{value}</p>
          )}
          {canCopy && !isLink && (
            <span onClick={() => copyToClipboard(value)}>
              <Icon name="clipBoard" className="cursor-pointer" />
            </span>
          )}
        </div>
      </div>
    );
  };

  return (
    <div className=" bg-white border border-[#fff] pt-14 pb-10 rounded-lg w-full justify-between gap-16 md:mt-0 mt-6">
      <div className="lg:ml-10 ml-5">
        <div className="flex gap-6 items-center px-6 mb-10">
          <img
            className="w-[117px] h-[117px] rounded-full"
            src={
              user?.profilePhoto ||
              `https://ui-avatars.com/api/?name=${user?.fullname}&background=0979A1&color=fff`
            }
            alt="profile"
          />
          <div>
            <p className="font-bold text-base">{capitalizeFirstLetter(user?.userType) || "N/A"}</p>
            <p className="text-sm break-all"> {user?.email || "N/A"}</p>
          </div>
        </div>

        <Details
          title={userType === "buyer" ? "Name" : "Name"}
          value={user?.fullname || "N/A"}
        />
        <Details title="Email address:" value={user?.email || "N/A"} />

        <Details title="Phone number:" value={user?.phone || "N/A"} />

        <Details
          title="Referral code:"
          value={user?.referralCode || "N/A"}
          canCopy={true}
        />

        <Details
          title="Referral link:"
          value={user?.referralLink}
          canCopy={true}
          isLink={true}
        />
      </div>

      <div className="overflow-x-auto lg:ml-10 ml-5  border rounded-lg  mt-6 shadow-xl">
        <table className="min-w-full min-h-full bg-white">
          <thead className="bg-[#0979A1] text-white">
            <tr>
              <th className="py-2 px-4 border-r text-center border-gray-200">
                Calculated Amount
              </th>
              <th className="py-2 px-4 text-center">No. of Referrals</th>
            </tr>
          </thead>

          <tbody>
            <tr className="">
              <td className="py-2 px-4 text-center border-r border-gray-200 ">
                {user?.referralBalance || 0}
              </td>
              <td className="py-2 px-4 text-center">
                {referrals?.referralCount || 0}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Referral;
