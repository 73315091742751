import Landing from "../pages";

// ==============|| MAIN ROUTING ||============ //
async function checkIfUserIsLoggedIn() {
  const token = localStorage.getItem('token');
  return token !== null;
}

const MainRoutes = {
  path: "/",
  element: <Landing />,
  loader: async () => {
    const isLoggedIn = await checkIfUserIsLoggedIn(); // Implement this function to check the user's login status
    return isLoggedIn ? { pathname: "/dashboard" } : null;
  },
};



export default MainRoutes;
