import { useState, useEffect } from "react";

import Orders from "../orders";
import useRequest from "../../components/hooks/use-request";
import Sidebar from "../../components/layout/sidebar";
import Wallet from "./wallet/wallet";
import Pagination from "../../components/pagination";
import Tabs from "../../components/dashboard/tabs";
import Withdraw from "./withdrawals";
import dayjs from "dayjs";

interface UserData {
  fullname: string;
}

const Dashboard = () => {
  const [activeTab, setActiveTab] = useState<
    "orders" | "wallet" | "withdrawals"
  >("orders");
  const [orderLoading, setOrdersLoading] = useState(true);
  const [walletLoading, setWalletLoading] = useState(true);
  const [withdrawalsloading, setWithdrawalsloading] = useState(true);
  const [statistics, setStatistics] = useState([]);
  const [data, setData] = useState<UserData[]>([]);
  const [transaction, setTransaction] = useState<UserData[]>([]);
  const [withdrawals, setWithdrawals] = useState<UserData[]>([]);
  const [ordersStartDate, setOrdersStartDate] = useState<string | null>(null);
  const [ordersEndDate, setOrdersEndDate] = useState<string | null>(null);
  const [walletStartDate, setWalletStartDate] = useState<string | null>(null);
  const [walletEndDate, setWalletEndDate] = useState<string | null>(null);
  const { makeRequest: getOrders, response } = useRequest(`/orders`, "GET");
  const { makeRequest: getTransactions } = useRequest(
    "/user/wallet-transactions",
    "GET"
  );
  const { makeRequest: getWithdrawals } = useRequest("/user/withdraw", "GET");
  const [userEmail, setUserEmail] = useState<string>("");
  const userToken = localStorage.getItem("token");
  const params = new URLSearchParams(new URL(window.location.href).search);
  const [currentPage, setCurrentPage] = useState(
    Number(params.get("page") || 1)
  );
  const [totalPages, setTotalPages] = useState<number>(1);
  const itemsPerPage = 10;
  const [selectedStatus, setSelectedStatus] = useState<string>("");
  const [transactionStatus, setTransactionStatus] = useState<string>("");
  const [withdrawalStatus, setWithdrawalStatus] = useState<string>("");

  const getProfile = useRequest("/user/profile", "GET", {
    Authorization: `Bearer ${userToken}`,
  });

  useEffect(() => {
    getProfile.makeRequest();
    const responseData = getProfile.response;
    setUserEmail(responseData?.data?.email);
    setStatistics(responseData?.data);
    if (getProfile.statusCode === 401) {
      window.location.href = "/";
    }
  }, [getProfile.statusCode]);

  function updateUrlParams(params: { [key: string]: string | number | null }) {
    const url = new URL(window.location.href);
    Object.keys(params).forEach((key) => {
      if (params[key] !== null) {
        url.searchParams.set(key, params[key]!.toString());
      } else {
        url.searchParams.delete(key);
      }
    });
    window.history.pushState({}, "", url.toString());
  }

  useEffect(() => {
    const storedStatus = params.get("status") || "";
    const storedUserType = params.get("userType") || activeTab;
    const storedOrdersDate = params.get("ordersStartDate") || "";
    const storedOrdersEndDate = params.get("ordersEndDate") || "";
    const storedWalletDate = params.get("walletStartDate") || "";
    const storedWalletEndDate = params.get("walletEndDate") || "";

    setSelectedStatus(storedStatus);
    setActiveTab(storedUserType as "orders" | "wallet" | "withdrawals");
    setOrdersStartDate(storedOrdersDate);
    setOrdersEndDate(storedOrdersEndDate);
    setWalletStartDate(storedWalletDate);
    setWalletEndDate(storedWalletEndDate);

    fetchData();
  }, [currentPage]);

  useEffect(() => {
    updateUrlParams({
      page: currentPage,
      status: selectedStatus,
      userType: activeTab,
      ordersStartDate: ordersStartDate,
      ordersEndDate: ordersEndDate,
      walletStartDate: walletStartDate,
      walletEndDate: walletEndDate,
    });
    fetchData();
  }, [
    activeTab,
    userEmail,
    selectedStatus,
    transactionStatus,
    withdrawalStatus,
    ordersStartDate,
    ordersEndDate,
    walletStartDate,
    walletEndDate,
    currentPage,
  ]);

  async function fetchData() {
    const page = currentPage;
    const limit = itemsPerPage;
    if (activeTab === "orders") {
      if (userEmail) {
        const [response] = await getOrders(undefined, {
          email: userEmail,
          status: selectedStatus,
          startDate: ordersStartDate,
          endDate: ordersEndDate,
          limit,
          page,
        });
        setOrdersLoading(false);
        setData(response.data?.orders || []);
        setTotalPages(Math.ceil(response.data?.totalPages));
      }
    } else if (activeTab === "wallet") {
      const [response] = await getTransactions(undefined, {
        type: transactionStatus,
        startDate: walletStartDate,
        endDate: walletEndDate,
        limit,
        page,
      });
      setWalletLoading(false);
      setTransaction(response.data?.transactions || []);
      setTotalPages(Math.ceil(response.data?.totalPages));
    } else if (activeTab === "withdrawals") {
      const [response] = await getWithdrawals(undefined, {
        status: withdrawalStatus,
        limit,
        page,
      });
      setWithdrawalsloading(false);
      setWithdrawals(response.data?.withdrawals || []);
      setTotalPages(Math.ceil(response.data?.totalPages));
    }
  }

  function handleStatusChange(event: React.ChangeEvent<HTMLSelectElement>) {
    setSelectedStatus(event.target.value);
  }

  function handleTransactionStatusChange(
    event: React.ChangeEvent<HTMLSelectElement>
  ) {
    setTransactionStatus(event.target.value);
  }

  function handleWithdrawalStatusChange(
    event: React.ChangeEvent<HTMLSelectElement>
  ) {
    setWithdrawalStatus(event.target.value);
  }

  async function handlePageChange(page: number) {
    setCurrentPage(page);
    await fetchData();
  }

  function handleDateRangeChange(
    dates: [dayjs.Dayjs | null, dayjs.Dayjs | null]
  ) {
    if (dates) {
      let [start, end] = dates;
      if (start && end && start > end) {
        [start, end] = [end, start];
      }
      setOrdersStartDate(start ? start.format("YYYY-MM-DD") : null);
      setOrdersEndDate(end ? end.format("YYYY-MM-DD") : null);
      setWalletStartDate(start ? start.format("YYYY-MM-DD") : null);
      setWalletEndDate(end ? end.format("YYYY-MM-DD") : null);
    }
  }

  return (
    <div className="md:flex block">
      <Sidebar />

      <div className="w-full mt-5 md:mt-0 px-8">
        <Tabs
          activeTab={activeTab}
          tabs={
            getProfile?.response?.data?.userType === "merchant" || "buyer"
              ? ["orders", "wallet", "withdrawals"]
              : ["orders"]
          }
          setActiveTab={setActiveTab}
        />

        {activeTab === "orders" && (
          <Orders
            data={data}
            statistics={statistics}
            selectedStatus={selectedStatus}
            handleStatusChange={handleStatusChange}
            orderLoading={orderLoading}
            setOrdersStartDate={setOrdersStartDate}
            setOrdersEndDate={setOrdersEndDate}
            handleDateRangeChange={handleDateRangeChange}
          />
        )}

        {activeTab === "wallet" && (
          <Wallet
            transaction={transaction}
            statistics={statistics}
            transactionStatus={transactionStatus}
            handleTransactionStatusChange={handleTransactionStatusChange}
            walletLoading={walletLoading}
            setWalletStartDate={setOrdersStartDate}
            setWalletEndDate={setOrdersEndDate}
            handleDateRangeChange={handleDateRangeChange}
          />
        )}

        {activeTab === "withdrawals" && (
          <Withdraw
            withdrawals={withdrawals}
            withdrawalStatus={withdrawalStatus}
            handleWithdrawalStatusChange={handleWithdrawalStatusChange}
            withdrawalsloading={withdrawalsloading}
          />
        )}

        {totalPages > 1 && (
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
          />
        )}
      </div>
    </div>
  );
};

export default Dashboard;
