import defaultPhoto from "../assets/images/default-avatar.svg";

interface AvatarProps {
  src?: string | null;
  altText?: string;
  size?: "small" | "medium" | "large";
}

const Avatar: React.FC<AvatarProps> = ({ src, altText, size = "medium" }) => {
  const sizeClasses = {
    small: "w-8 h-8 md:w-12 md:h-12",
    medium: "w-16 h-16 md:w-24 md:h-24",
    large: "w-16 h-16",
  };

  return (
    <div className={`rounded-full overflow-hidden ${sizeClasses[size]}`}>
      <img
        src={src ? src : defaultPhoto}
        alt={altText}
        className='w-full h-full object-cover'
      />
    </div>
  );
};

export default Avatar;
