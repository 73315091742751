import { createContext, ReactNode, useContext, useState } from "react";
import { I_Item, I_TransactionFees } from "./types";

interface OrderContextProps {
  orderId: string;
  setOrderIdContext: (newOrderId: string) => void;
}

const OrderContext = createContext<OrderContextProps | undefined>(undefined);

export const ItemsContext = createContext<{
  items: I_Item[];
  removeItem: (index: number) => void;
  updateItem: (index: number, value: I_Item) => void;
  transactionFees: I_TransactionFees;
  addItem: () => void;
}>({
  items: [],
  removeItem: (i: number) => {},
  updateItem: (i: number, value: I_Item) => {},
  transactionFees: {
    total: 0,
    rate: 0,
    discount: 0,
    amount: 0,
  },
  addItem: () => {},
});

export function OrderProvider({ children }: { children: ReactNode }) {
  const [orderId, setOrderId] = useState("");

  const setOrderIdContext = (newOrderId: string) => {
    setOrderId(newOrderId);
  };

  return (
    <OrderContext.Provider value={{ orderId, setOrderIdContext }}>
      {children}
    </OrderContext.Provider>
  );
}

export const useOrder = () => {
  const context = useContext(OrderContext);
  if (!context) {
    throw new Error();
  }
  return context;
};
