import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Table from "../../components/table";
import { formatDate } from "../../utils/functions";
import { DatePicker } from "antd";
import dayjs from "dayjs";
import { TailSpin } from "react-loader-spinner";

const WalletTable = ({
  transaction,
  transactionStatus,
  handleTransactionStatusChange,
  walletLoading,
  walletStartDate,
  walletEndDate,
  handleDateRangeChange,
}: any) => {
  const navigate = useNavigate();
  const [filteredData, setFilteredData] = useState([]);
  const { RangePicker } = DatePicker;
  const columns = [
    { header: "Amount", accessor: "amount" },
    { header: "Date", accessor: "date" },
    { header: "Description", accessor: "type" },
    { header: "Time", accessor: "time" },
  ];

  useEffect(() => {
    const filtered = transaction.filter((wallet: any) => {
      if (wallet.type) {
        return true;
      }
      return false;
    });
    setFilteredData(filtered);
  }, [transaction, transactionStatus, walletStartDate, walletEndDate]);

  const mappedData = transaction.map((transactions: any) => ({
    ...transactions,
    email: transactions?.email || "N/A",
    amount: `NGN ${transactions?.amount}.00` || "N/A",
    date: dayjs(transactions?.created_at).format("MMMM D, YYYY") || "N/A",
    time: dayjs(transactions?.created_at).format("h:mm A") || "N/A",
  }));

  function handleUserClick(id: string) {
    navigate(`/wallet/details/${id}`);
  }

  return (
    <>
      <div className="rounded-md py-3  mt-5 w-full md:block hidden">
        <div className="flex justify-between">
          <p className="text-[14px] font-semibold md:w-[50%]">
            Recent Transactions
            <hr className="md:bg-[#0979A1] h-[2px] md:w-[8%] md:block" />
          </p>

          <div className="md:flex gap-2 hidden">
            <RangePicker
              value={[
                walletStartDate ? dayjs(walletStartDate) : null,
                walletEndDate ? dayjs(walletEndDate) : null,
              ]}
              onChange={(dates) => handleDateRangeChange(dates)}
              className="bg-transparent h-[44px] rounded-2xl"
            />

            <span className="text-[14px] font-medium pt-2">Filter</span>
            <select
              className="border text-[12px] p-2 rounded bg-[#0979A1] text-white"
              value={transactionStatus}
              onChange={handleTransactionStatusChange}
            >
              <option value="">All</option>
              <option value="credit">Credit</option>
              <option value="debit">Debit</option>
            </select>
          </div>
        </div>

        {walletLoading ? (
          <div className="flex justify-center items-center h-64">
            <TailSpin color="skyblue" />
          </div>
        ) : filteredData.length > 0 ? (
          <Table
            columns={columns}
            data={mappedData}
            selectedUserId={null}
            onUserClick={handleUserClick}
          />
        ) : (
          <div className="flex justify-center items-center h-64">
            <p className="text-gray-500 text-lg">No data available</p>
          </div>
        )}
      </div>

      <div className="md:hidden">
        <RangePicker
          value={[
            walletStartDate ? dayjs(walletStartDate) : null,
            walletEndDate ? dayjs(walletEndDate) : null,
          ]}
          onChange={(dates) => handleDateRangeChange(dates)}
          className="bg-transparent h-[44px] rounded-2xl mt-4"
        />
        <div className="flex justify-between mt-5">
          <p className="text-[14px] font-semibold  mt-3 ">
            Recent Transactions
          </p>

          <div className=" flex gap-2 ">
            <select
              className="border text-[12px] p-2 mt-2 rounded bg-[#0979A1] text-white"
              value={transactionStatus}
              onChange={handleTransactionStatusChange}
            >
              <option value="">Filter By</option>
              <option value="credit">Credit</option>
              <option value="debit">Debit</option>
            </select>
          </div>
        </div>
        F
        <hr className="bg-[#0979A1] w-[13%] h-[2px] " />
        {walletLoading ? (
          <div className="flex justify-center items-center h-64">
            <TailSpin color="skyblue" />
          </div>
        ) : filteredData.length > 0 ? (
          filteredData.map((item: any, index: number) => (
            <div
              key={index}
              className="flex gap-5 md:gap-10 border border-solid rounded-lg px-3 md:px-10 py-3 md:py-5 mt-5 "
            >
              <div className="flex gap-2 px-5 justify-between flex-1">
                <div className="flex flex-col gap-2">
                  <p className="text-[14px] md:text-lg">
                    {formatDate(item.created_at)}
                  </p>
                </div>

                <div className="flex flex-col gap-2 justify-between">
                  <p className="font-bold text-sm md:text-xl overflow-hidden whitespace-nowrap truncate">
                    {item.amount >= 0
                      ? `+${item.amount}.00`
                      : `-${Math.abs(item.amount)}.00`}
                  </p>
                  <p className="font-normal text-[10px] md:text-xl overflow-hidden whitespace-nowrap truncate">
                    {item.type}
                  </p>
                </div>
              </div>
            </div>
          ))
        ) : (
          <div className="flex justify-center items-center h-64">
            <p className="text-gray-500 text-lg">No data available</p>
          </div>
        )}
      </div>
    </>
  );
};

export default WalletTable;
