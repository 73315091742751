import React, { useState, useCallback } from "react";
import { Controller, useForm } from "react-hook-form";
import useRequest from "./hooks/use-request";
import NewIcon from "../assets/images/new-icons";
import laptop from "../assets/images/new-laptop.png";
import { formatCurrency } from "../utils/functions";
import classNames from "classnames";

const Offering = () => {
  const { makeRequest: getTransactionFee } = useRequest(
    "/orders/transaction-fee",
    "POST"
  );
  const [transactionFees, setTransactionFees] = useState({
    total: 0,
    rate: 0,
  });
  const [amount, setAmount] = useState(0);
  const { control } = useForm();

  const handleAmountChange = useCallback(
    (event: any) => {
      const amount = parseFloat(event.target.value) || 0;
      setAmount(amount);

      getTransactionFee({ amount }).then((data) => {
        if (data && data.length > 0) {
          const [response] = data;
          const fees = response.data;
          setTransactionFees({
            total: fees?.total,
            rate: fees?.rate,
          });
        }
      });
    },
    [getTransactionFee]
  );
  const data = [
    {
      name: "offerings-one",
      title: "Safe Transaction",
      description:
        "Online shopping scams are real with scary stories. Billions of naira have been lost, with many shoppers falling victim.",
    },
    {
      name: "offerings-two",
      title: "Seamless Transaction",
      description:
        "You can avoid falling a victim by using an effective and reliable escrow solution. The merchant does not get paid until the goods are safely delivered on time.",
    },
    {
      name: "offerings-three",
      title: "Instant Withdrawal",
      description:
        "Legitimate sellers have nothing to fear. We offer quick withdrawals with zero charges for Merchants.",
    },
  ];
  const data_two = [
    {
      title: "Payments",
      text: "Create and share payment links in less than 3 minutes",
    },
    {
      title: "Approval On Delivery",
      text: "Approve funds only on safe and completed deliveries",
    },
    { title: "Escrow Lock", text: "Easy and quick withdrawal for merchants" },
    {
      title: "Support and Mediation",
      text: "Effective customer support and dispute mediation center",
    },
  ];

  return (
    <>
      <div className="py-16 lg:py-32 bg-[#F0F4F8] font-poppins">
        <p className="text-[#191C1E] text-[24px] lg:text-[45px] leading-[52px] font-semibold mb-8 lg:mb-16 text-center font-poppins">
          Our Offerings
        </p>
        <div className="flex flex-col lg:flex-row items-center justify-center lg:w-[91%] mx-auto gap-4 lg:gap-8 lg:h-[391px]">
          {data.map((e: any, index: any) => {
            return (
              <div
                key={index}
                className="w-[86%] h-full xl:w-[426px] bg-white rounded-3xl flex flex-row justify-between lg:justify-normal lg:flex-col gap-4 lg:gap-6 border border-[#C0C8CD] p-4 lg:p-8"
              >
                <div className="bg-[#FF5449] w-[72px] h-[72px] xl:w-[98px] xl:h-[98px] rounded-lg lg:rounded-3xl flex items-center justify-center p-3">
                  <div className="w-12 h-12 lg:w-16 lg:h-16">
                    <NewIcon name={e.name} width={"full"} height={"full"} />
                  </div>
                </div>
                <div>
                  <p className="text-[#232628] font-semibold text-base lg:text-[24px] leading-[36px] xl:mb-4 font-poppins">
                    {e.title}
                  </p>
                  <p className="text-xs text-[#444749] font-normal lg:text-sm xl:text-[20px] lg:leading-[28px] font-poppins">
                    {e.description}
                  </p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="py-16 lg:py-32 bg-white ">
        <p className="text-[#191C1E] text-[24px] lg:text-[45px] leading-[52px] font-semibold mb-8 lg:mb-16 text-center font-poppins">
          Why Use Vendstash?
        </p>
        <div className="flex flex-col lg:flex-row items-center justify-center lg:w-[100%] mx-auto gap-4 lg:gap-8 ">
          <div>
            <img src={laptop} />
          </div>
          <div className="">
            {data_two.map((e: any,index:any) => {
              return (
                <div className="flex items-start mb-4 lg:mb-8 px-6" key={index}>
                  <span className="relative flex h-6 w-6">
                    <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-yellow-400 opacity-75"></span>
                    <span className="relative inline-flex rounded-full h-6 w-6 bg-[#E7C507]"></span>
                  </span>

                  <div className="ml-4 lg:ml-4">
                    <p className="text-[#232628] font-semibold text-base lg:text-2xl font-poppins">
                      {e.title}
                    </p>
                    <p className="font-normal text-[#444749] text-xs lg:text-[20px] lg:leading-[28px] font-poppins">
                      {e.text}
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className="py-16 lg:py-32 bg-[#F0F4F8]" id="pricing">
        <p className="text-[#191C1E] text-[24px] lg:text-[45px] font-poppins leading-[52px] font-semibold mb-8 lg:mb-16 text-center">
          Our pricing supports Buyers and Merchants
        </p>
        <div className="w-[87%] lg:w-[70.5%] mx-auto bg-white rounded-2xl lg:rounded-3xl border border-[#C0C8CD] p-2 lg:p-8">
          <div className="bg-[#003546] mb-2.5 lg:mb-6 rounded-lg lg:rounded-xl flex items-center text-white lg:text-[24px] lg:leading-[28px] text-[14px] leading-[18px] h-[28px] lg:h-[81px]">
            <p className=" basis-3/5 lg:basis-2/3 pl-4 lg:pl-8 font-poppins">
              Transaction value
            </p>
            <p className=" basis-2/5 lg:basis-1/3 font-poppins">
              Transaction fee
            </p>
          </div>
          <div className="bg-[#EAEEF2] mb-2.5 lg:mb-6 rounded-lg lg:rounded-xl flex items-center text-[#444749] lg:text-[24px] lg:leading-[28px] text-[14px] leading-[18px] h-[28px] lg:h-[81px]">
            <p className=" basis-3/5 lg:basis-2/3 pl-4 lg:pl-8 font-poppins">
              0 -2000
            </p>
            <p className=" basis-2/5 lg:basis-1/3 font-poppins">50</p>
          </div>
          <div className="bg-white mb-2.5 lg:mb-6 rounded-lg lg:rounded-xl flex items-center text-[#444749] lg:text-[24px] lg:leading-[28px] text-[14px] leading-[18px] h-[28px] lg:h-[81px]">
            <p className=" basis-3/5 lg:basis-2/3  pl-4 lg:pl-8 font-poppins">
              2,500 - 750,000
            </p>
            <p className=" basis-2/5 lg:basis-1/3 flex font-poppins">
              2% of transaction value
            </p>
          </div>
          <div className="bg-[#EAEEF2] mb-2.5 lg:mb-6 rounded-lg lg:rounded-xl flex items-center text-[#444749] lg:text-[24px] lg:leading-[28px] text-[14px] leading-[18px] h-[28px] lg:h-[81px]">
            <p className=" basis-3/5 lg:basis-2/3  pl-4 lg:pl-8 font-poppins">
              750,000 - 5,000,000
            </p>
            <p className=" basis-2/5 lg:basis-1/3 font-poppins">15,000</p>
          </div>
          <div className="bg-white mb-2.5 lg:mb-6 rounded-lg lg:rounded-xl flex items-center text-[#444749] lg:text-[24px] lg:leading-[28px] text-[14px] leading-[18px] h-[28px] lg:h-[81px]">
            <p className=" basis-3/5 lg:basis-2/3 pl-4 lg:pl-8 font-poppins">
              Above 5 million
            </p>
            <p className=" basis-2/5 lg:basis-1/3 font-poppins">-</p>
          </div>
        </div>
        <div className="mt-8 mb-5 lg:mt-[88px] lg:mb-[50px] lg:px-16 px-4 flex flex-col lg:flex-row lg:items-start justify-center gap-6">
          <div className="lg:w-[288px] xl:w-[416px] xl:max-w-[416px] flex flex-col gap-4 xl:gap-6">
            <span className="text-[#232628] font-normal text-xs lg:text-sm xl:text-[20px] leading-[28px] font-poppins">
              Enter transaction amount (at least N200){" "}
            </span>
            <div className="bg-white hover:shadow-xl rounded-lg lg:rounded-2xl text-[#444749] lg:text-[24px] lg:leading-[28px] text-[14px] leading-[18px] h-[40px] lg:h-[68px] border-[0.7px] border-[#C0C8CD] lg:border lg:border-[#70787D] lg:px-8 px-4 py-2 lg:py-4">
              <Controller
                name="amount"
                control={control}
                defaultValue=""
                rules={{
                  required: "Amount is required",
                  minLength: {
                    value: 1,
                    message: "Amount must be at least 1 number ",
                  },
                }}
                render={({ field }) => (
                  <input
                    {...field}
                    type="number"
                    min="0"
                    placeholder="0.00"
                    className="h-full w-full border-none border-0 outline-none"
                    onChange={(e) => {
                      field.onChange(e);
                      handleAmountChange(e);
                    }}
                  />
                )}
              />
            </div>
            <p className="text-[#BA1A1A] text-xs lg:text-lg font-normal">
              {
                amount > 0 && amount <200 ? 'The value entered must be 200 or above.':''
              }
            </p>
          </div>
   
          <div className="lg:w-[288px] xl:w-[416px] xl:max-w-[416px]  flex flex-col gap-4 xl:gap-6">
            <span className="text-[#232628] font-normal text-xs lg:text-sm xl:text-[20px] leading-[28px]">
              Transaction charges
            </span>
            <div className="bg-[#C5C7C9]  rounded-lg lg:rounded-2xl text-[#444749] lg:text-[24px] lg:leading-[28px] text-[14px] leading-[18px] h-[40px] lg:h-[68px] lg:px-8 px-4 py-2 lg:py-4">
              <p
                className={classNames(
                  "font-medium  text-[#444749] lg:text-[24px] lg:leading-[28px] text-[14px] leading-[18px] font-poppins",
                  "text-center ",
                  
                )}
              >
                {formatCurrency(
                  amount > 200 ? transactionFees?.rate : 0,
                  "NGN",
                  "symbol"
                )}
              </p>
            </div>
          </div>
          <div className="lg:w-[288px] xl:w-[416px] xl:max-w-[416px]  flex flex-col gap-4 xl:gap-6">
            <span className="text-[#232628] font-normal text-xs lg:text-sm xl:text-[20px] leading-[28px]">
              Total amount
            </span>
            <div className="bg-[#C5C7C9]  rounded-lg lg:rounded-2xl text-[#444749] lg:text-[24px] lg:leading-[28px] text-[14px] leading-[18px] h-[40px] lg:h-[68px] lg:px-8 px-4 py-2 lg:py-4">
              <p
                className={classNames(
                  "font-medium  text-[#444749] lg:text-[24px] lg:leading-[28px] text-[14px] leading-[18px] font-poppins",
                  "text-center ",
                 
                )}
              >
                {formatCurrency(
                  amount > 200 ? transactionFees?.total : 0,
                  "NGN",
                  "symbol"
                )}
              </p>
            </div>
          </div>
        </div>
        <p className="text-[#232628] text-base lg:text-[28px] lg:leading-[28px] font-poppins font-semibold text-center">
          Use the calculator above to see how our pricing works
        </p>
      </div>
    </>
  );
};

export default Offering;
