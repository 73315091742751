import { useState, useEffect } from "react";
import { Subtitle } from "./text";
import Icon from "../assets/images/icons";

interface SelectProps {
  label: string;
  name: string;
  options: { value: string; label: string }[];
  className?: string;
  onChange: (selectedValue: string) => void;
  value?: string;
  error?: string;
  message?: string;
}

export default function Select({
  label,
  options,
  className,
  name,
  onChange,
  value,
  error,
  message,
}: SelectProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState(value || "");

  const toggleDropdown = () => setIsOpen(!isOpen);

  const handleOptionClick = (value: string) => {
    setSelectedValue(value);
    onChange(value);
    setIsOpen(false);
  };

  useEffect(() => {
    setSelectedValue(value || "");
  }, [value]);

  return (
    <div className={`relative flex flex-col gap-2 md:gap-3 ${className}`}>
      <Subtitle>{label}</Subtitle>

      <div className="relative">
        <div
          onClick={toggleDropdown}
          className={`text-field w-full h-[40px] px-4 flex justify-between items-center bg-[#F9FAFB] border border-[#E5E7EB] shadow shadow-[#03071214] pl-5 rounded-md cursor-pointer`}
        >
          <span className="text-[12px] md:text-[14px] text-[#98A2B3]">
            {selectedValue || `Select`}
          </span>
          <Icon name="selecticon" />
        </div>

        {isOpen && (
          <ul className="absolute z-10 w-full bg-white border border-[#E5E7EB] rounded-md shadow-lg shadow-[#03071214] mt-1 max-h-40 overflow-y-auto text-[12px] md:text-[14px]">
            {options.map((option) => (
              <li
                key={option.value}
                onClick={() => handleOptionClick(option.value)}
                className="pt-2 px-14 hover:bg-gray-200 cursor-pointer"
              >
                {option.label}
              </li>
            ))}
            {message && (
              <div className="mt-1 text-sm text-[#667085] border-t border-gray-300 py-5 px-8">
                {message}
              </div>
            )}
          </ul>
        )}
      </div>

      {error && (
        <span className="error-text text-[#FF0101] text-[14px]">{error}</span>
      )}
    </div>
  );
}
