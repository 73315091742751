import { ReactNode, useState, useEffect } from "react";
import useRequest from "../../../components/hooks/use-request";
import { CircleLoader } from "react-spinners";
import { SectionTitle } from "../../../components/text";
import { I_Order, I_Timeline, I_User, I_Rider } from "./types";
import Toast, { showToast } from "../../../components/toast";
import { useNavigate, useSearchParams } from "react-router-dom";
import NewIcon from "../../../assets/images/new-icons";
import FetchRiderImage from "../../../assets/images/fetchRider.png";
import Rider from "../../../assets/images/rider.png";
import classNames from "classnames";

export default function TimelineDetails({
  timeline,
  order,
}: {
  timeline: I_Timeline;
  order: I_Order;
}) {
  const navigate = useNavigate();
  const userJSON = localStorage.getItem("user");
  const user: I_User | null = userJSON ? JSON.parse(userJSON) : null;
  const isMerchant = order.merchantEmail === user?.email;
  const isBuyer = order.buyerEmail === user?.email;
  const [searchParams] = useSearchParams();
  const t = searchParams.get("t");
  const merchantIsViewer = isMerchant || atob(t || "") === order.merchantEmail;
  const buyerIsViewer = isBuyer || atob(t || "") === order.buyerEmail;

  const [loadingRider, setLoadingRider] = useState(false);
  const [fetchingRider, setFetchingRider] = useState(false);

  const [requestRider, setRequestRider] = useState<I_Rider | null >(null);


  const [intervalId, setIntervalId] = useState<NodeJS.Timeout | null>(null);

  function getTimelineValue(status: string) {
    return (
      timeline.find((timeline) => timeline.status === status)?.value || false
    );
  }

  function copyLink() {
    const link = window.location.href;
    navigator.clipboard.writeText(link);
    showToast("Link copied to clipboard", true);
  }

  const handleClick = () => {
    navigate(`/upload-proof/${order.orderId}`);
  };

  const { makeRequest: getRiders, loading } = useRequest(
    `/orders/logistics/ride?orderId=${order.orderId}`,
    "POST"
  );

  const { makeRequest: getRiderDetails } = useRequest(
    `/orders/logistics/ride/${order.orderId}`,
    "GET"
  );

  const { makeRequest: cancelRide } = useRequest(
    `/orders/logistics/ride/${order.orderId}`,
    "DELETE"
  );

  const handleGetRiderDetails = async () => {
    const [response] = await getRiderDetails();
    if (response) {
      if (response?.data?.status === "driver on route to client") {
        setRequestRider(response.data);
        console.log("rider", requestRider);
        console.log("this is the response of rider details", response);
        if (intervalId) {
          clearInterval(intervalId);
          setIntervalId(null);
        }
      }
      else if (response?.data?.status === "no driver found") {
        setFetchingRider(false);
        showToast(response?.data?.status, true, {
          position: "top-center",
        });
        if (intervalId) {
          clearInterval(intervalId);
          setIntervalId(null);
        }
      }
      else if (response?.status === 400) {
        setFetchingRider(false);
        if (intervalId) {
          clearInterval(intervalId);
          setIntervalId(null);
        }
      }
    }
  };

  const handleGetRider = async () => {
    setLoadingRider(true);
    const [response] = await getRiders();
    if (response) {
      showToast(response.message, true, {
        position: "top-center",
      });
    }

    if (response.status) {
      setFetchingRider(true);
      await handleGetRiderDetails();
    }
  };

  const handleOnRefresh = () => {
    console.log("Window has been refreshed");
    handleGetRiderDetails();
  };

  useEffect(() => {
    handleOnRefresh();

    const id = setInterval(() => {
      handleGetRiderDetails();
    }, 30000);

    setIntervalId(id);

    return () => clearInterval(id);
  }, []);


  const handleCancelRide = async () => {
    setLoadingRider(false);
    const [response] = await cancelRide();
    if (response.status) {
      showToast(response?.message, true, {
        position: "top-center",
      });
      setLoadingRider(false);
      setFetchingRider(false);
      window.location.reload();
    }
  };

  return (
    <div className="w-full flex flex-col sm:p-6">
      <div className="w-full flex bg-white rounded-2xl">
        <div className="flex flex-col gap-5 w-full p-6">
          <Section>
            <Timeline status="Order Created" value={true} />

            <Action onClick={copyLink}>Copy Payment Link</Action>
          </Section>

          <Section>
            <Timeline
              status="Payment Received"
              value={getTimelineValue("payment_received")}
            />

            {order.status === "paid" && buyerIsViewer && (
              <>
                {["paid", "delivered"].includes(order.status) && (
                  <Action
                    onClick={() => {
                      navigate(`../../release-fund/${order.orderId}`);
                    }}
                  >
                    Release Funds To Merchant
                  </Action>
                )}
              </>
            )}
          </Section>

          <Section>
            <Timeline
              status="Initiate Delivery"
              value={requestRider?.rider.name == ''}
            />

            {order.status === "paid" && buyerIsViewer && (
              <>
                {["paid", "delivered"].includes(order.status) &&
                  !fetchingRider && (
                    <DeliveryAction onClick={handleGetRider}>
                      {loading ? (
                        <CircleLoader color="#fff" size="20" />
                      ) : (
                        "Request a Rider"
                      )}
                    </DeliveryAction>
                  )}
              </>
            )}
          </Section>

          <Section classNames="flex justify-center items-center">
            {fetchingRider || requestRider ? (
              <RiderCard onClick={handleCancelRide} loading = {loadingRider} rider={requestRider} />
            ) : (
              <UnAssignedCard />
            )}
          </Section>

          <Section>
            <Timeline
              status="Delivery Completed"
              value={getTimelineValue("delivery_completed")}
            />

            {order.status === "paid" && merchantIsViewer && (
              <Action onClick={handleClick}>Upload Proof of Delivery</Action>
            )}
          </Section>

          <Section>
            <Timeline
              status="Payment Disbursed"
              value={getTimelineValue("payment_disbursed")}
            />

            {getTimelineValue("payment_disbursed") && (
              <Action color="green">Payment Disbursed</Action>
            )}
          </Section>
        </div>
      </div>
      <Toast />
    </div>
  );
}

function Timeline({ status, value }: { status: string; value: boolean }) {
  return (
    <div className="flex items-center gap-4 w-[50%]">
      {value ? checked : unchecked}
      <SectionTitle size="text-[12px] md:text-[18px]">{status}</SectionTitle>
    </div>
  );
}

function Section({
  children,
  classNames,
}: {
  children: ReactNode;
  classNames?: string;
}) {
  return (
    <div className={`flex items-center w-full gap-2 py-3 ${classNames}`}>
      {children}
    </div>
  );
}

function Action({
  onClick,
  children,
  color = "blue",
}: {
  onClick?: () => void;
  children: ReactNode;
  color?: "blue" | "green";
}) {
  return (
    <button
      className={classNames(
        "h-[38px] p-4 flex items-center justify-center text-[12px] font-medium rounded-md",
        {
          "bg-[#CFF0FC] hover:bg-[#4dc2ed] text-[#0978a1]": color === "blue",
          "bg-[#DEF2D9] text-[#078E42]": color === "green",
        }
      )}
      onClick={onClick}
    >
      {children}
    </button>
  );
}

function DeliveryAction({
  onClick,
  children,
  color = "blue",
}: {
  onClick?: () => void;
  children: ReactNode;
  color?: "blue" | "red";
}) {
  return (
    <button
      className={classNames(
        "h-[38px] p-4 flex items-center justify-center text-[12px] font-medium rounded-md",
        {
          "bg-[#075B79] text-[#FFFFFF]": color === "blue",
          "bg-[#FFE1E1] border border-[#FFB5B5] text-[#FF3636]":
            color === "red",
        }
      )}
      onClick={onClick}
    >
      {children}
    </button>
  );
}

function UnAssignedCard() {
  return (
    <div className="p-4 rounded-2xl gap-5 flex flex-row bg-[#EDEFEB] max-w-[585px]">
      <div>
        <NewIcon name="big-car-icon" />
      </div>
      <p className="font-roboto font-normal text-[20px] leading-[23px]">
        There is no rider assigned yet, assign one under Initiate Delivery
      </p>
    </div>
  );
}

function FetchRiderCard() {
  return (
    <div className="flex flex-col justify-center items-center">
      <div className="w-[166px] max-w-[167px] h-[98px] max-h-[99px]">
        <img width={"full"} height={"full"} src={FetchRiderImage} />
      </div>
      <p className="font-roboto text-base text-center text-[#282828] mt-2.5">
        Fetching riders
      </p>
    </div>
  );
}

function RiderCard({ onClick, rider , loading}: { onClick?: () => void; rider?: any; loading?:boolean }) {
  return (
    <>
      {!rider ? (
        <FetchRiderCard />
      ) : (
        <div>
          <div className="p-6 bg-[#EDEFEB] rounded-[32px] flex justify-between gap-6 w-[578px] max-w-[579px]">
            <div>
              <div>
                <img src={Rider} alt="rider" />
              </div>
            </div>
            <div className="flex-1">
              <p className="text-[#282828] font-semibold text-lg font-roboto mb-3">
                {rider?.rider?.name}
              </p>

              <div className="mb-6 flex gap-6">
                <div className="flex">
                  <NewIcon name="rider-phone-icon" />
                  <span className="text-[#4A4A4A] text-base font-normal">
                    {rider?.rider?.phone}
                  </span>
                </div>

                <div className="flex">
                  <NewIcon name="rider-plate-number-icon" />
                  <span className="text-[#4A4A4A] text-base font-normal">
                    {" "}
                    {rider?.vehicle?.plateNumer}
                  </span>
                </div>
              </div>
              <div className="mb-6 flex">
                <a  href={rider?.url}
                  target="_blank"
                  rel="noopener noreferrer" className="text-[#4A4A4A] text-base font-normal mr-1.5 underline">
                  Track your delivery 
                </a>
               
              </div>

              <div className="flex">
                <DeliveryAction color="red" onClick={onClick}>
               {
                loading? <CircleLoader color="#FFF" size={'10px'} /> :   'Reject'
               }
                </DeliveryAction>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

const unchecked = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="12" height="12" transform="translate(6 6)" fill="white" />
    <mask
      id="mask0_11087_14478"
      style={{ maskType: "alpha" }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="24"
      height="24"
    >
      <rect width="24" height="24" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_11087_14478)">
      <path
        d="M10.6 16.6L17.65 9.55L16.25 8.15L10.6 13.8L7.75 10.95L6.35 12.35L10.6 16.6ZM12 22C10.6167 22 9.31667 21.7375 8.1 21.2125C6.88333 20.6875 5.825 19.975 4.925 19.075C4.025 18.175 3.3125 17.1167 2.7875 15.9C2.2625 14.6833 2 13.3833 2 12C2 10.6167 2.2625 9.31667 2.7875 8.1C3.3125 6.88333 4.025 5.825 4.925 4.925C5.825 4.025 6.88333 3.3125 8.1 2.7875C9.31667 2.2625 10.6167 2 12 2C13.3833 2 14.6833 2.2625 15.9 2.7875C17.1167 3.3125 18.175 4.025 19.075 4.925C19.975 5.825 20.6875 6.88333 21.2125 8.1C21.7375 9.31667 22 10.6167 22 12C22 13.3833 21.7375 14.6833 21.2125 15.9C20.6875 17.1167 19.975 18.175 19.075 19.075C18.175 19.975 17.1167 20.6875 15.9 21.2125C14.6833 21.7375 13.3833 22 12 22Z"
        fill="#A0A0A0"
      />
    </g>
  </svg>
);

const checked = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_11087_14506"
      style={{ maskType: "alpha" }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="24"
      height="24"
    >
      <rect width="24" height="24" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_11087_14506)">
      <path
        d="M10.6 16.6L17.65 9.55L16.25 8.15L10.6 13.8L7.75 10.95L6.35 12.35L10.6 16.6ZM12 22C10.6167 22 9.31667 21.7375 8.1 21.2125C6.88333 20.6875 5.825 19.975 4.925 19.075C4.025 18.175 3.3125 17.1167 2.7875 15.9C2.2625 14.6833 2 13.3833 2 12C2 10.6167 2.2625 9.31667 2.7875 8.1C3.3125 6.88333 4.025 5.825 4.925 4.925C5.825 4.025 6.88333 3.3125 8.1 2.7875C9.31667 2.2625 10.6167 2 12 2C13.3833 2 14.6833 2.2625 15.9 2.7875C17.1167 3.3125 18.175 4.025 19.075 4.925C19.975 5.825 20.6875 6.88333 21.2125 8.1C21.7375 9.31667 22 10.6167 22 12C22 13.3833 21.7375 14.6833 21.2125 15.9C20.6875 17.1167 19.975 18.175 19.075 19.075C18.175 19.975 17.1167 20.6875 15.9 21.2125C14.6833 21.7375 13.3833 22 12 22Z"
        fill="#078E42"
      />
    </g>
  </svg>
);
