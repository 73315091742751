import React, { useEffect, useState } from "react";
import Lock from "../../assets/images/Ellipse 1.svg";
import useRequest from "../../components/hooks/use-request";
import Icon from "../../assets/images/icons";
import { useNavigate, useParams } from "react-router-dom";
import { showToast } from "../../components/toast";

const PaymentLink = () => {
  const navigate = useNavigate();
  const [link, setLink] = useState("");
  const { id } = useParams<{ id: string }>();

  const { makeRequest: getPaymentLink } = useRequest(
    `/orders/${id}/initiate-payment`,
    "GET"
  );

  useEffect(() => {
    if (link) return;
    const fetchData = async () => {
      const queryParams = { userType: "merchant" };
      const [response] = await getPaymentLink(undefined, queryParams);
      setLink(response.data?.link);
    };

    const interval = setInterval(() => {
      if (!link) {
        fetchData();
      }
    }, 10000);

    const timeout = setTimeout(() => {
      clearInterval(interval);
    }, 30000);

    return () => {
      clearInterval(interval);
      clearTimeout(timeout);
    };
  }, [link]);

  const handleCopyLink = () => {
    navigator.clipboard.writeText(link);
    showToast("Link copied successfully!", true, {
      position: "top-center",
    });
  };

  const handleClick = () => {
    if (link) {
      navigator.clipboard.writeText(link);
      showToast("Link copied successfully!", true, {
        position: "top-center",
      });
      navigate("/");
    } else {
      showToast("Unable to generate payment. Please try again!", false, {
        position: "top-center",
      });
    }
  };

  return (
    <>
      <div className="flex m-22  justify-center items-center gap-3 mt-20">
        <img src={Lock} alt="lock" className="w-12 h-12 md:w-24 md:h-24" />
        <h2 className="text-[#040821] font-semibold text-base md:text-[28px]">
          Link Generated!
          <span className="text-[#040821] block md:my-2 font-normal text-xs md:text-[20px]">
            Share this link to authorise payment
          </span>
        </h2>
      </div>

      <div className=" bg-[#F8F8F8] sm:w-[50%] mx-auto mt-10 flex justify-around rounded-md">
        <input
          value={link ? link : "Loading..."}
          className=" bg-[#F8F8F8] sm:w-[85%] h-[50px] md:h-[70px] outline-none"
          readOnly
        />
        <button type="button" onClick={handleCopyLink}>
          <Icon name="copy" />
        </button>
      </div>

      <div className="flex flex-col gap-4 justify-center items-center mt-10">
        <button
          type="button"
          className="px-6 md:px-10 py-2 md:py-4 gap-2 w-[75%] md:w-[45%] border-2 border-[#0979A1] rounded-lg font-bold bg-[#0979A1] hover:opacity-75 text-sm md:text-xl text-[#fff]"
          onClick={handleClick}
        >
          Copy link
        </button>
        <button
          type="button"
          className="px-6 md:px-10 py-2 md:py-4 gap-2 w-[75%] md:w-[45%] border-2 border-[#0979A1] rounded-lg font-bold hover:opacity-75 text-sm md:text-xl text-[#0979A1]"
          onClick={() => navigate("/")}
        >
          Back to Homepage
        </button>
      </div>
    </>
  );
};

export default PaymentLink;
