import { useEffect, useState } from "react";
import Back from "../../components/back";
import Search from "../../components/blacklist/search";
import MerchantCard from "../../components/blacklist/merchant-card";
import useRequest from "../../components/hooks/use-request";

const Merchants = () => {
  const [search, setSearch] = useState("");
  const { makeRequest, response } = useRequest(
    `/reports?search=${search}`,
    "GET"
  );

  useEffect(() => {
    makeRequest();
  }, [search]);
  return (
    <>
      <div>
        <div className="flex jstify-start">
          <div className="inline-block">
            <Back />
          </div>
          <div className="mt-5 ml-6 md:ml-12 md:mt-6 md:hidden">
            <p className="text-lg font-bold">Blacklisted Merchants</p>
          </div>
        </div>
        <div className="mt-8 p-2 hidden md:block">
          <p className="text-lg font-bold">Blacklisted Merchants</p>
        </div>
      </div>

      <div className="flex flex-col gap-5 w-[100%] sm:p-2">
        <div className="mt-4">
          <Search setSearch={setSearch} />

          <div className="flex flex-col flex-wrap gap-3 md:gap-6 md:flex-row">
            {response?.data?.reports?.map((report: any, index: number) => (
              <div key={index} className="flex-[40%]">
                <MerchantCard
                  name={report?.reportedBy?.fullname}
                  type={report?.reportedMerchant?.fullname}
                  reports={1}
                  link={`/blacklist/merchant/reviews/${report?.id}`}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Merchants;
