import useRequest from "../hooks/use-request";
import { CircleLoader } from "react-spinners";
import { useNavigate } from "react-router-dom";
import Toast, { showToast } from "../toast";

const ResendCode = () => {
  const userToken = localStorage.getItem("verifyToken");
  const { loading, makeRequest } = useRequest("/user/token", "POST", {
    Authorization: `Bearer ${userToken}`,
  });
  const navigate = useNavigate()

  async function handleResendCode(e: any) {
    e.preventDefault();
    if (userToken === null) {
      navigate('/signin')
      return;
    }
      const [response, status] = await makeRequest();
      if(status === 201){
      showToast(response.message, true, {
        position: "top-center",
      });
    } else{
      showToast(response.message, false, {
        position: "top-center",
      });
    }
  }
  return (
      <div className="text-center text-[#475467] text-sm">
     Didn’t receive code? {" "}
      <button  className="text-[#0979A1] hover:text-[#35D081]" onClick={handleResendCode}>
        {loading ? (
          <CircleLoader
            color="#ffffff"
            loading={loading}
            size={20}
            onClick={handleResendCode}
          />
        ) : (
          "Resend code"
        )}
      </button>
      <Toast />
    </div>
  );
};

export default ResendCode;
