import { useParams } from "react-router-dom";
import Back from "../../components/back";
import UploadProof from "./upload";
import { useEffect, useState } from "react";
import useRequest from "../../components/hooks/use-request";

interface Item {
  id: string;
  name: string;
  amount: number;
  currency: string;
  quantity: number;
  description: string;
  created_at: string;
  updated_at: string;
}

interface Order {
  id: string;
  merchantBusinessName: string;
  status: string;
  created_at: string;
  orderId: string;
  buyerName: string;
  buyerPhoneNumber: string;
  buyerEmail: string;
  buyerSocialMediaHandle: string;
  buyerSocialMediaPlatform: string;
  merchantEmail: string;
  items: Item[]; 
}

const RequestPayment = () => {
  const [order, setOrder] = useState<Order>({
    id: "",
    merchantBusinessName: "",
    status: "",
    created_at: "",
    orderId: "",
    buyerName: "",
    buyerPhoneNumber: "",
    buyerEmail: "",
    buyerSocialMediaHandle: "",
    buyerSocialMediaPlatform: "",
    merchantEmail: "",
    items: [], 
  });

  const { orderId } = useParams<{ orderId: string }>();
  const { makeRequest: getOrderById } = useRequest(`/orders/${orderId}`, "GET");

  useEffect(() => {
    const fetchData = async () => {
        const [response] = await getOrderById();
        setOrder(response?.data?.order );
    };
    fetchData();
  }, []);
  
  return (
    <>
      <Back />
      <div className="text-center">
        <p className="text-center font-bold text-[#040821]">
          Upload proof of delivery
        </p>

        <p className="text-[#040821]">Upload and attach picture or file here</p>
      </div>
      <UploadProof order={order} />
    </>
  );
};

export default RequestPayment;
