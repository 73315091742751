import React from "react";
import classNames from "classnames";
import { isLoggedIn } from "../utils/functions";

interface TabsProps {
  activeTab: string;
  tabs: string[];
  setActiveTab: any;
  variant?: "default" | "white";
}

const Tabs = ({
  activeTab,
  tabs,
  setActiveTab,
  variant = "default",
}: TabsProps) => {
  const handleTabClick = (tab: string) => {
    setActiveTab(tab);
  };
  const loggedIn = isLoggedIn();

  return (
    <div
      className={classNames("w-full", {
        "bg-white shadow-md": variant === "white",
      })}
    >
      <div className="flex w-full items-center justify-center">
        {loggedIn && (
          <h1 className="text-bold md:text-lg w-[300px] py-4 font-semibold">
            Transaction Details
          </h1>
        )}

        {!loggedIn &&
          tabs.map((tab, index) => (
            <button
              key={index}
              className={classNames(
                "text-bold md:text-lg w-[300px] py-4 font-semibold",
                {
                  "border-b-4 border-[#0979a1] text-[#0979a1]":
                    activeTab === tab,
                  "border-none text-[#4A4A4A]": activeTab !== tab,
                  "text-[#0979A1]": variant === "white",
                }
              )}
              onClick={() => handleTabClick(tab)}
            >
              {tab.charAt(0).toUpperCase() + tab.slice(1)}
            </button>
          ))}
      </div>
    </div>
  );
};

export default Tabs;
