import { Link } from "react-router-dom";
import Back from "../../components/back";

const Blacklist = () => {
  return (
    <>
      <Back />
      <div className="">
        <div className="mt-[35%] md:mt-10 p-4 flex justify-center items-center flex-col gap-5">
          <Link
            to="/blacklist/merchants"
            className="w-[105%] md:w-[400px] border border-gray-300 rounded p-4 block text-gray-900 text-center"
          >
            All Blacklisted Merchants
          </Link>

          <Link
            to="/blacklist/merchant/report"
            className="w-[105%] md:w-[400px] border border-gray-300 rounded p-4 block text-gray-900 text-center"
          >
            Report a Merchant to be Blacklisted
          </Link>
        </div>
      </div>
    </>
  );
};

export default Blacklist;
