import { Link } from "react-router-dom";
import image from "../assets/images/new-desktop-hero.png";
import NewIcon from "../assets/images/new-icons";

const Hero = () => {
  return (
    <div className=" mx-auto flex flex-col xl:flex-row  justify-center  px-4 lg:px-0 bg-[#BEE9FF] py-16 lg:py-0 xl:max-h-[628px]">
      {/* Content */}
      <div className="w-full xl:w-1/2 lg:px-16 flex flex-col gap-8 lg:gap-[72px]">
        <div className="lg:mt-[149px]">
          <h1 className="text-[#003546] text-center xl:text-left font-semibold text-4xl leading-[44px] lg:text-[57px] lg:leading-[64px] font-poppins">
            Secure Your <br className="lg:hidden" /> Deal{" "}
            <br className="hidden lg:block" /> with Escrow
          </h1>
          <p className="text-center xl:text-left text-[#004155] text-[20px] leading-[26px] lg:text-[28px] lg:leading-[36px] font-poppins lg:mt-12">
            Get what you ordered when you pay with VendStash.
            <br /> 
            Happy Customer, Happy Merchant.
          </p>
        </div>
        <div className="flex flex-col items-center gap-4 justify-center lg:flex-row lg:gap-8">
          <Link to='/create-order' className="no-underline">
          <button className="bg-[#004D65] hover:bg-[#003546] font-poppins rounded-2xl py-6 px-4  text-base w-[250px] lg:min-w-[288px] max-h-[59px] lg:py-[30px] lg:px-7 text-white font-medium lg:text-xl  lg:max-h-[77px] flex items-center">
            {" "}
            Generate payment link
          </button></Link>
          <a href="#download">
          <button className="bg-[#69D3FF] hover:bg-[#9ED589] font-poppins max-h-[59px] w-[250px]  rounded-2xl py-6 px-4  text-base lg:py-[30px] lg:px-7 lg:min-w-[288px] text-[#003546] flex items-center font-medium lg:text-xl lg:max-h-[77px]">
            <span className="w-[76px] mr-1 lg:mr-2">
              <NewIcon name="download" height={"full"} width={"full"}/>
            </span>
            Download app
          </button>
          </a>
        </div>
      </div>

      {/* Image */}
      <div className="mx-auto text-center flex items-center justify-center  h-[445px] lg:w-[698px] lg:h-auto xl:max-w-[698px] lg:my-8 mt-7 xl:mr-auto">
        <img className="w-full h-full mx-auto" src={image} alt="svg" />
      </div>
    </div>
  );
};

export default Hero;
