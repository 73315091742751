import classNames from "classnames";
import {
  ChangeEvent,
  ForwardedRef,
  forwardRef,
  HTMLInputTypeAttribute,
} from "react";
import { Subtitle } from "./text";

interface InputProps {
  label?: string;
  placeholder?: string;
  className?: string;
  type?: HTMLInputTypeAttribute;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  name?: string;
  value?: string | number;
  error?: string;
  readOnly?: boolean;
  variant?: "primary" | "secondary";
  ref?: any;
}

const Input = forwardRef(
  (
    {
      label,
      placeholder,
      className,
      type,
      onChange,
      name,
      value,
      error,
      readOnly = false,
      variant = "primary",
    }: InputProps,
    ref
  ) => (
    <div className={`flex flex-col gap-4 lg:gap-7 ${className}`}>
      {label && <div className="text-[#212121] font-normal text-xs lg:text-xl">{label} </div>}

      <input
        ref={ref as any}
        id={label}
        placeholder={placeholder}
        className={classNames(
          "text-field w-full h-[40px] lg:h-[80px]  pl-5 p-4 border-[0.5px] lg:border border-[#70787D] rounded-lg lg:rounded-3xl outline-none text-[12px] md:text-[16px]",
          {
            
            error: error,
          }
        )}
        onChange={onChange}
        value={value}
        type={type === "number" ? "text" : type}
        name={name}
        readOnly={readOnly}
      />

      {error && (
        <span className="error-text text-[#FF0101] text-[12px]">{error}</span>
      )}
    </div>
  )
);

export default Input;
